export default {
  auth: {
    email: "E-mail",
    password: "Password",
    logout: "Logout",
    sign_in: "Login",
    sign_in_with_sso: "Login with SSO",
    login_screen: "login screen",
    back_to_sign_in: "Back to login",
    reset_password: "Reset Password",
    reset_password_code: "Reset Password Code",
    forget_password: "Forget Password",
    new_password: "New Password",
    re_new_password: "Re New Password",
    login_failed: "Identity not found",
    change_password: "Change Password",
    continue: "Continue",
    showcase: {
      welcome: "Welcome^3000",
      cloud: "Next generation identification and management cloud platform^4000",
      identify: "It lets you identify, track and manage your workforce^1000 easy and secure^4000",
      manage: "You can manage anytime and anywhere you like^4000",
      freedom: "You have freedom^3000 to see all your system^3000"
    },
    email_not_found: "This email address was not found",
    invalid_reset_token: "Invalid reset code",
    reset_password_expired: "You must wait at least 3 minutes to reset a new password",
    reset_code_expired: "Password reset code has expired",
    send_sms_password_code: "Password reset code was sent as SMS message",
    password_change_successfully: "Your password has been changed successfully",
    invalid_email_or_password: "Email or password is invalid",
    invalid_password: "Password is invalid",
    current_password: "Your Current Password",
    sso_error: "An error occured"
  },
  dashboard: {
    access: {
      table: {
        last_event: "Last Events",
        personal: "Employee",
        terminal_type: "Terminal Type",
        general_info: "General Information",
        terminal: "Terminal",
        event_type: "Event Type",
        identity_type: "Identity Type",
        time: "Time",
        event: "Event",
        location: "Location",
        place: "Place",
        place_beacon: "Place Beacon",
        minor: "Minor",
        major: "Major",
        parent: "Parent",
        duration: "Duration",
        first_seen: "First Seen",
        last_seen: "Last Seen",
        max_person_count: "Max Person Count",
        begin_date_time: "Begin DateTime",
        end_date_time: "End DateTime",
        employee_dialog_title: "Employee Enter - Exit Information",
        empty_zone_message: "Empty Zone",
        loading_message: "Loading"
      },
      monthly_dashboard: "Monthly Dashboard",
      monthly_events: "Monthly Events",
      monthly_timesheet_report: "Late Clock In",
      absence: "My Absences",
      incalculable: "My Incalculable",
      lates: "My Late Clock In",
      overtime: "My Overtime",
      early_leaver: "My Early Clock Out",
      missing_time: "My Missing Hours",
      expected: "My Expected",
      total_tardiness_average: "Late Clock In Average",
      total_leaver_average: "Early Clock Out Average",
      total_overtime_average: "Overtime Average",
      tardiness_minute: "Late Minute",
      early_minute: "Early Minute",
      overtime_minute: "Overtime Minute",
      short_minute: "min",
      today: "Today"
    },
    currency: {
      buying: "Buying",
      selling: "Selling",
      currencies: "Currency"
    },
    about: {
      about: "About",
      mission: "Mission",
      vision: "Vision"
    },
    menu_of_day: "The Menu of Day",
    messages: {
      no_data_reminders: "No data Reminders",
      no_data_messages: "No data Messages",
      no_data_leaves: "No data Time Off Requests",
      no_data_overtimes: "No data Overtime Requests",
      no_data_expenses: "No data Expense Requests",
      no_data_imprests: "No data Imprest Requests",
      no_data_incalculables: "No data Incalculables",
      no_data_absences: "No data Absences"
    },
    request_type: {
      leave: "Time Off Requests",
      overtime: "Overtime Requests",
      expense: "Expense Requests",
      imprest: "Imprest Requests"
    },
    weather_forecast: "Weather Forecast & Current Rates",
    reminder_tab_title: "Reminders",
    message_tab_title: "Messages",
    data_type_enum: {
      LEAVE_REQUEST: "Time Off Requests",
      ACTIVITY: "Activities",
      REMINDER: "Reminders"
    }
  },
  time: {
    crud: {
      create_overtime: "Create Overtime Request"
    },
    event: {
      clock_in: "Clock In",
      clock_out: "Clock Out",
      duty_in: "Duty In",
      duty_out: "Duty Out",
      break_in: "Break In",
      break_out: "Break Out",
      lunch_in: "Lunch In",
      lunch_out: "Lunch Out"
    },
    menu: {
      shifts: "Shifts",
      timesheets: "Timesheets",
      overtime_requests: "Overtime Requests",
      overtime_approval: "Overtime Approval Requests",
      employee_calendar: "Employee Calendar"
    },
    shift_types: {
      FIX: "Fix",
      FLEXIBLE: "Flexible",
      OFF_DAY: "Off Day",
      OFFDAY: "Off Day",
      HOLIDAY: "Holiday",
      WORKDAY: "Work Day",
      BREAK: "Rest Day"
    },
    overtime_approval: {
      employee: "Employee",
      workplan: "Workplan",
      request_status: "Request Status",
      reason: "Reason",
      request_time: "Requested Date"
    },
    overtime_request: {
      approve_title: "Overtime Request Approval",
      add_overtime: "Add Overtime Request",
      date: "Shift Date",
      workplan: "Shift",
      overtime_in_minutes: "Overtime Duration",
      short_minute: "min",
      request_status: "Status",
      request_date: "Request Date",
      period: "Period",
      periods: {
        CLOCK_IN: "Clock In",
        CLOCK_OUT: "Clock Out",
        CLOCK_IN_OUT: "Clock In Out"
      },
      message: "{0} Do you want to approve the <b> {2} minutes overtime request for her shift on {1}  </b>"
    },
    timesheet: {
      employee: "Employee",
      employees: "Employees",
      date: "Date",
      type: "Type",
      work_plan: "Working Plan",
      shift: "Shift",
      clock_in: "Clock In",
      clock_in_title: "Clock In Time",
      late: "Late",
      reason: "Reason",
      late_title: "Late Clock In",
      it: "IT",
      it_title: "Identity Type",
      terminal: "Terminal",
      clock_out: "Clock Out",
      clock_out_title: "Clock Out Time",
      early: "Early",
      early_title: "Early Clock Out",
      ewt: "EWT",
      ewt_title: "Expected Working Time",
      awt: "AWT",
      twt_title: "Total Working Time",
      twt: "TÇS",
      awt_title: "Actual Working Time",
      ot: "OT",
      ot_title: "Overtime",
      mt: "MT",
      mt_title: "Missing Time",
      name: "Employee",
      time: "Time",
      identity_type: "Identity Type",
      event_type: "Event Type",
      event_time: "Event Time",
      all_events: "All Events",
      status: "Status",
      shift_name: "Shift Name",
      branch: "Branch",
      unit: "Unit",
      team: "Team",
      jobInfo: "Job Info",
      staffNumber: "Staff Number",
      information: {
        work_day: "Work Day",
        work_day_desc: "That means the employee will come to work that day.",
        work_off: "Work Off",
        work_off_desc: "That day is the weekend holiday for the employee.",
        public_holiday: "Public Holiday",
        public_holiday_desc: "That is the public holiday for the employee.",
        did_not_come_to_work: "Did not come to the work",
        did_not_come_to_work_desc: "The employee did not come to the work despite he has a work shift for that day.",
        on_leave: "On Time Off",
        on_leave_desc: "The employee is on time off on that day.",
        calculated: "Calculated",
        calculated_desc: "That means the calculation has been done for the words colored by green.",
        not_calculated: "Not Calculated",
        not_calculated_desc: "That means the calculation hasn't been done for the words colored by red."
      },
      total: "Total",
      ot_mt: "Difference"
    },
    calendar: {
      holiday: "Holiday",
      off_day: "Off Day",
      breakday: "Break Day",
      employee_overtime: "If the employee comes to work on this date, it is calculated as overtime",
      employee_off_day: "It is the employee's weekly holiday",
      employee_break_day: "It is the employee's rest day.",
      shift_loading: "Idenfit creating shifts",
      create_shift: "Create Shift",
      shift_detail: "Shift Detail",
      leave_type: "Time Off Type",
      begin_time: "Begin Time",
      end_time: "End Time",
      break_time: "Break Time",
      shift_time: "Shift Time",
      clock_in_max_overtime: "Clock In Max Overtime",
      clock_out_max_overtime: "Clock Out Max Overtime",
      lately_tolerance: "Late Tolerance",
      early_tolerance: "Early Time Off Tolerance",
      overtime: "Overtime",
      early_clock_out_tolerance: "Late Tolerance",
      lately_clock_in_tolerance: "Early Time Off Tolerance",
      max_clock_in_overtime_in_minutes: "Max Clock In Overtime In Minutes",
      max_clock_out_overtime_in_minutes: "Max Clock Out Overtime In Minutes",
      min_clock_in_overtime_in_minutes: "Min Clock In Overtime In Minutes",
      min_clock_out_overtime_in_minutes: "Min Clock Out Overtime In Minutes",
      minimum_overtime: "Minimum overtime",
      maximum_overtime: "Maximum overtime",
      midnight_tolerance: "Midnight Tolerance",
      shift_type: "Shift Type",
      count_as_overtime: "Count as overtime",
      total_work_time: "Total Working Time",
      selectWeekends: "Weekend Days",
      selectBreaks: "Break Days",
      overtime_request: {
        approve_title: "Approving Overtime",
        add_overtime: "Request Overtime ",
        detail_overtime: "Overtime Detail",
        date: "Shift Date",
        workplan: "Shift",
        overtime_in_minutes: "Shift Time",
        request_status: "Status",
        request_date: "Request Date",
        approval_date: "Approving Date",
        period: "Period",
        history: "Approving History",
        periods: {
          CLOCK_IN: "Clock In",
          CLOCK_OUT: "Clock Out",
          CLOCK_IN_OUT: "Clock In & Out"
        },
        approved:
          "{0} <b> {1} tarihinideki vardiyası için {2} dk </b> fazla mesai talebini onaylamak istiyor musunuz ?",
        rejected:
          "{0} <b> {1} tarihinideki vardiyası için {2} dk </b> fazla mesai talebini reddetmek istiyor musunuz ?",
        approval_to_manager: "Fazla Mesai Yöneticisine onay gitsin",
        not_approval_to_manager: "Fazla Mesai Yöneticisine onay gitmesin",
        pending_approval_manager: "Onayı Beklenen Yönetici",
        processes: {
          approved: "Approved the request",
          pending: "Pending approval",
          stopped: "Approving process stopped since the request cancelled",
          waiting: "Waiting for previous manager to approve",
          denied: "Request cancelled"
        }
      },
      multiple_shift_deleting: "Multiple Shift Deleting",
      cancel_request: "Cancel Request",
      cancel_request_title: "Cancel Shift Request"
    }
  },
  leave: {
    crud: {
      create: "Add Time Off Request"
    },
    menu: {
      leave_accruals: "Time Off Accruals",
      leave_requests: "Time Off Requests",
      leave_approvals: "Pending Time Off Approvals"
    },
    hourly: "Hourly",
    daily: "Daily",
    type: "Time Off Type",
    begin_time: "Begin Time",
    end_time: "End Time",
    begin_date: "Begin Date",
    end_date: "End Date",
    approved_time: "Approved Date",
    request_time: "Request Time",
    approve_or_reject: "Approve or Reject",
    status: "Status",
    no_approver: "No Approver",
    manager: "Time Off Manager",
    managers: "Time Off Managers",
    balance: "Balance",
    not_leave_accruals: "This employee has not yet deserved a time off",
    permission: {
      employee_leave_approver_message: "This staff member can approve requests for permission from other staff",
      employee_not_leave_approver_message: "This staff member cannot approve permission requests from other staff",
      employee_shift_approver_message: "This staff member can assign shifts to other staff",
      employee_not_shift_approver_message: "This staff member cannot assign shifts to other staff",
      employee_overtime_approver_message: "This staff member can approve overtime requests from other staff",
      employee_not_overtime_approver_message: "This staff member cannot approve overtime requests from other staff"
    },
    notification: {
      employee_receiver_leave_notification_message:
        "These personnel may receive permission notices from other personnel",
      employee_not_receiver_leave_notification_message: "This staff cannot receive permission notices from other staff",
      employee_receiver_shift_notification_message:
        "These staff can receive shift assignment notifications from other staff",
      employee_not_receiver_shift_notification_message:
        "This staff cannot receive shift assignment notifications from other staff",
      employee_receiver_overtime_notification_message:
        "These staff can receive overtime request notifications from other staff",
      employee_not_receiver_overtime_notification_message:
        "This staff cannot receive overtime request notifications from other staff"
    },
    respect: "Permission managers above will have priority",
    order_is_important: "The manager's order is important",
    already_selected_manager: "You have already selected this manager",
    there_is_no_leave_manager: "No assigned time off managers",
    add_leave_manager: "Add Time Off Manager",
    used: "Used",
    remaining: "Remaining",
    on_leave: "On Time Off",
    period: "Period",
    duration: "Duration",
    periods: {
      first_half: "1.Half",
      second_half: "2.Half",
      first_quarter: "1.Quarter",
      second_quarter: "2.Quarter",
      third_quarter: "3.Quarter",
      fourth_quarter: "4.Quarter"
    },
    request: {
      this_day_does_not_count_as_leave: "This day does not count as time off",
      leave_balance: "Time Off Balance ",
      max_exceed_leave_balance: "Max Exceed Time Off Balance",
      leave_request_count: "Time Off Request Count",
      used: "Used",
      remaining: "Remaining",
      approved: "Approve",
      denied: "Deny",
      leave_decreased_count: "Time Off will decrease"
    },
    accruals: {
      name: "Name",
      count: "Time Off Accrual",
      used: "Used",
      balance: "Actual Balance",
      previous_balance: "Previous Balance"
    },
    approvals: {
      leave_approval: "Time Off Approval",
      message:
        "<b>{0}</b>'s time off balance of type <b>{1}</b> is <b>{2}</b> days. Do you want to approve a total of <b>{5}</b> daily time off requests made between <b>{3}</b> - <b>{4}</b>?"
    },
    cancels: {
      leave_cancel: "Revoke Time Off",
      message: "Your reason for revoking the time off?",
      error_message: "Your reason for canceling? space is required"
    },
    error_messages: {
      notice_period_error: "This time off must be requested at least {0} days in advance",
      max_consecutive_error: "You can request up to {0} days of time off at a time",
      min_consecutive_error: "You can request a minimum of {0} days of time off at a time",
      leave_request_exist_error: "There is a requested time off on these dates",
      total_leave_balance_error1: "You do not have enough balance for this time off",
      total_leave_balance_error2:
        "For this time off, you can use a maximum of {0} days from the advance balance, you can not request time off because your advance balance is insufficient."
    },
    e_signature: "Digital Signature",
    e_signature_leave_form: "Digital Signed Time Off Form",
    leave_form: "Time Off Form",
    leave_cancel: "Cancel Time Off",
    signed: "Signed",
    not_signed: "Not Signed",
    requests: {
      days_off_automatically: "Auto added days",
      requested_leave_type_documents: "Files need to be documents for time off"
    },
    today_counts_as_a_day_off: "Today is considered a day off",
    required_document_message:
      " | There is {count} document you need to upload | There are {count} documents you need to upload",
    isNexyDayMessage: "Time Off start the next day"
  },
  leave_request_status: {
    pending: "Pending",
    PENDING: "Pending",
    approved: "Approved",
    APPROVED: "Approved",
    denied: "Denied",
    DENIED: "Denied",
    cancelled: "Cancelled",
    CANCELLED: "Cancelled",
    need_extra_detail: "Need extra detail",
    NEED_EXTRA_DETAIL: "Need extra detail",
    null: "Unknown",
    NULL: "Unknown",
    undefined: "Unknown",
    UNDEFINED: "Unknown",
    closed: "Closed",
    CLOSED: "Closed",
    close_pending: "Pending Close",
    CLOSE_PENDING: "Pending Close",
    NOT_COME: "Not Come",
    not_come: "Not Come",
    super_admin: "Admin Approval",
    SUPER_ADMIN: "Admin Approval"
  },
  time_duration: {
    hour: "Hour",
    minute: "Minute",
    second: "Second"
  },
  leave_type: {
    paid: "Paid",
    unpaid: "Unpaid",
    on_duty: "On Duty",
    restricted_holiday: "Restricted Holiday"
  },
  leave_status: {
    APPROVED: "Approved",
    PENDING: "Pending",
    DENIED: "Denied",
    CANCELLED: "Cancelled",
    NEED_EXTRA_DETAIL: "Need Extra Detail",
    not_come: "No time has come",
    NOT_COME: "No time has come"
  },
  leave_duration: {
    FULL_DAY: "Full Day",
    HALF_DAY: "Half Day",
    QUARTER_DAY: "Quarter Day",
    HOURLY_DAY: "Hourly Day",
    HOURLY: "Hourly"
  },
  hr: {
    menu: {
      profile: "Profile",
      performances: "Performances",
      hiring: "Hiring",
      trainings: "Trainings",
      expenses: "Expenses",
      imprests: "Imprests",
      papers: "Papers",
      paper: "Paper",
      documents: "Documents",
      health: "Health",
      debit_tickets: "Debit Tickets",
      payroll: "Payrolls",
      events: "Events",
      approvals: "Approval Requests",
      expense_approval: "Expense Requests",
      imprest_approval: "Imprest Requests",
      messages: "Messages",
      inbox: "Inbox",
      outbox: "Outbox",
      suggestions: "Suggestions",
      notes: "Notes",
      travels: "Travels",
      travel_requests: "Travel Requests",
      vehicles: "Vehicles",
      menus: "Food Menu",
      contact: "Contact",
      expense: "Expense",
      expense_request_history: "Expense Request History",
      imprest: "Imprest",
      imprest_request_history: "Imprest Request History",
      travel: "Travel",
      travel_request_history: "Travel Request History",
      debit_ticket_transfer_requests: "Debit Ticket Transfer Requests",
      training: "Training",
      training_requests: "Training Requests",
      training_request_history: "Training Request History",
      survey: "Survey",
      pending_surveys: "Pending Surveys",
      completed_surveys: "Completed Surveys",
      expired_surveys: "Expired Surveys",
      paper_request_history: "Paper Request History",
      paper_requests: "Paper Requests"
    },
    account: {
      overview: "Overview",
      email: "E-mail",
      information: "Information",
      language_role: "Language & Role",
      profile: "Profile",
      contact: "Contact",
      personal: "Personal",
      family: "Family",
      education: "Education",
      employment: "Employment",
      financial: "Financial",
      social_media: "Social Media",
      body_size: "Body Size",
      mobile_device: "Mobile Devices",
      special_fields: "Special Fields",
      messages: "Messages",
      notes: "Notes",
      management: "Management"
    },
    employee: {
      overview: {
        staff_number: "Staff Number",
        branch: "Branch",
        unit: "Unit",
        team: "Team",
        email: "E-mail",
        job_info: "Job Info",
        title: "Title",
        leave: {
          manager: "Time Off Manager",
          managers: "Time Off Managers",
          approver: "Time Off Approver"
        },
        hired_date: "Hired Date",
        status: "Status",
        manager: "Manager",
        shift_manager: "Shift Manager",
        shift_managers: "Shift Managers",
        role: "Role"
      },
      information: {
        identity_number: "Identity Number",
        first_name: "First Name",
        last_name: "Last Name",
        work_email: "Work Email",
        phone: "Phone",
        email_and_sms_send_question: "Send e-mail and sms notification to this employee?"
      },
      profile: {
        identity_number: "Identity Number",
        first_name: "First Name",
        last_name: "Last Name",
        work_email: "Work Email",
        phone: "Phone",
        email_and_sms_send_question: "Send e-mail and sms notification to this employee?",
        service_line: "Service Line"
      },
      language_role: {
        language: "Language",
        role: "Role"
      },
      contact: {
        email: "E-mail",
        address: "Address",
        secondary_phone: "Secondary Phone",
        fixed_phone: "Fixed Phone",
        work_phone: "Work Phone",
        spouse_phone: "Spouse Phone",
        extension_number: "Extension Number",
        emergency_contact_name: "Emergency Contact Name",
        emergency_contact_phone: "Emergency Contact Phone"
      },
      personal: {
        pasaport_number: "Passport Number",
        birth_date: "Birth Date",
        place_of_birth: "Place Of Birth",
        nationality: "Nationality",
        blood_type: "Blood Type",
        gender: "Gender",
        disability_level: "Disability Level",
        ex_convict: "Ex Convict",
        driver: "Driver",
        military_service: "Military service",
        suspended_until: "Suspended Until",
        hes_code: "HES Code",
        hes_expired_date: "HES Code Expired Date"
      },
      family: {
        marital_status: "Marital Status",
        spouse_name: "Spouse Name",
        child_count: "Child Count",
        tenant: "Tenant",
        martyrs_relatives: "Martyr Relatives",
        disability_child_count: "Disability Child Count",
        date_of_marriage: "Date of Marriage",
        father_name: "Father Name",
        mother_name: "Mother Name",
        mothers_maiden_name: "Mother's Maiden Name",
        sibling_count: "Sibling Count",
        child_info: "Child Info",
        add_child_info: "Add Child Info"
      },
      education: {
        graduation: "Graduation",
        school: "School",
        faculty: "Faculty",
        department: "Department",
        graduation_grade: "Graduation Grade",
        academic_title: "Academic Title",
        languages: "Languages",
        licence_file: "Licence File"
      },
      employment: {
        staff_number: "Staff Number",
        branch: "Branch",
        unit: "Unit",
        team: "Team",
        job_info: "Job Info",
        manager: "Manager",
        agency: "Agency",
        ssn: "SSN",
        passive: "Passive",
        hired_date: "Hired Date",
        started_date: "Started Date",
        employment_type: "Employment Type",
        collar_type: "Collar Type",
        quite_job: "Quite Job",
        quit_job_reason: "Quit Job Reasons",
        quit_job_reason_description: "Description",
        description: "Description",
        quit_job_date: "Quit Job Date",
        have_an_interview_made: "Interview Made",
        have_an_interview_made_not: "No Interviews Made",
        integration_value: "Integration Number",
        research_and_development_person: "R&D Employee",
        social_security_type: "Social Security Type",
        company: "Company",
        allowance_date: "Allowance Started Date"
      },
      financial: {
        iban: "IBAN",
        ticket_number: "Ticket Number",
        bank_account_number: "Bank Account Number",
        bank_branch: "Bank Branch",
        salary: "Salary",
        ticket_price: "Ticket Price",
        previous_salary: "Previous Salary",
        previous_ticket_price: "Previous Ticket Price",
        yearly_premium: "Yearly Premium",
        monthly_stoppage: "Monthly Stoppage",
        health_insurance_person_count: "Health Insurance Person Count",
        agi: "Minimum Living Allowance",
        gross_salary: "Gross Salary",
        pay_to_net: "Net Salary",
        pay_to_gross: "Gross Salary",
        salary_type: "Salary Payment Type"
      },
      social_media: {
        facebook: "Facebook",
        twitter: "Twitter",
        instagram: "Instagram",
        linkedin: "Linkedin",
        skype: "Skype"
      },
      body_size: {
        weight: "Weight",
        height: "Height",
        shirt_size: "Shirt Size",
        pants_size: "Pants Size",
        shoe_size: "Shoe Size",
        waistcoat: "Waistcoat",
        coat: "Coat"
      },
      management: {
        manager: "Manager",
        leave_managers: "Time Off Managers",
        shift_managers: "Shift Managers",
        overtime_managers: "Overtime Managers",
        expense_managers: "Expense Managers"
      },
      mobile_device: {
        serial_number: "Serial Number",
        multiple_device_allowed: "Multiple Device Allowed"
      }
    },
    performance: {
      results: {
        evaluatee: "Evaluatee",
        published_evaluators: "Published Evaluators",
        unpublished_evaluators: "Unpublished Evaluators",
        kpi_score: "Kpi Score",
        competence_score: "Competence Score",
        totalCount: "Total Participant",
        publishedCount: "Active Participant",
        evaluation_name: "Evaluation Name",
        total_score: "Total Score",
        status: "Status",
        period: "Period",
        scoring: "Scoring",
        beginDate: "Begin Date",
        endDate: "End Date"
      },
      table: {
        name: "Name",
        type: "Performance Type",
        score_range: "Score Range",
        evaluatee: "Employee to be Evaluated",
        expiration_period: "Expiration Period",
        validation_period: "Validation Period",
        status: "Evaluation Status",
        categories: "Categories"
      },
      depths: {
        depth_1: "1. Level",
        depth_2: "2. Level",
        depth_3: "3. Level",
        depth_4: "4. Level",
        depth_5: "5. Level"
      },
      add: {
        kpi: "Add Kpi",
        competence: "Add Competence",
        pool: "I want to add from the pool",
        custom: "I want to add a new",
        kpi_group: "Add Kpi Group",
        competence_group: "Add Competence Group"
      },
      detail: {
        kpi_group: "Kpi Group Detail",
        competence_group: "Competence Group Detail"
      },
      crud: {
        detail: {
          template: "Template Detail",
          evaluation: "Evaluation Plan Detail"
        }
      },
      type: {
        KPI: "Kpi",
        COMPETENCE: "Competence"
      },
      validation: {
        add_item: "You must add questions to the {0} category",
        template_exist_category: "{0} Category has been added",
        template_must_a_category: "There must be at least one category in the performance template",
        question_total_weight_required: "The total weight of the questions must be {0}%",
        question_total_weight: "Total weight of questions {0}%",
        category_not_exist: "No such category exists",
        category_total_weight_equals_in_questions:
          "Since the total weight of the category {0} is {1}%, the total weight of the questions belonging to this category must be {1}%.",
        category_in_question_required: "There must be at least one question in the category {0}",
        question_weight_required: "Enter the weight of {0}"
      },
      information:
        '<p class="body-1 mt-5">' +
        "You can create your kpi and competence list by selecting KPI & COMPETENCE categories." +
        "</p>" +
        '<p class="body-1">' +
        "You can make question-based scoring by entering their weight in the kpi and competence questions defined in the category you selected" +
        "</p>" +
        '<p class="body-1">' +
        "The sum of the questions in the list you added should be equal to the weight you set to KPI or COMPETENCE." +
        "</p>",
      total_evaluatees_count: "Total evaluatees count {0}",
      waiting_evaluates_finish: "{0} people are expected to complete the evaluation",
      complete_evaluation: "This evaluation has been completed",
      expiration_date: "Expiration Date",
      template_name: "Template Name",
      kpi: "Kpi",
      kpi_category: "Kpi Category",
      kpis: "Kpis",
      competence: "Competence",
      competence_category: "Competence Category",
      competences: "Competences",
      result_view: "Show Results",
      waiting: "Waiting",
      hourly: "Hourly",
      weekly: "Weekly",
      monthly: "Monthly",
      yearly: "Yearly",
      score: "Score",
      star: "Star",
      weight: "Weight",
      point: "Point",
      person: "Person",
      persons: "People",
      period_type: "Period",
      score_type: "Score Type",
      score_min_count: "Minimum Score Count",
      score_max_count: "Maximum Score Count",
      evaluation: "Assessment",
      evaluates: "Evaluates",
      evaluators: "Evaluators",
      self: "Self",
      superior: "Superior",
      subordinates: "Subordinates",
      same: "Same",
      flexible: "Flexible",
      period: "Period",
      expiration_dates: "Expiration Date",
      expiration_begin_date: "Expiration Begin Date",
      expiration_end_date: "Expiration End Date",
      level: "Level",
      depth: "Depth",
      validation_date: "Validation Date",
      validation_begin_date: "Validation Begin Date",
      validation_end_date: "Validation End Date",
      published_date_time: "Published Date",
      degree_360: "360 °",
      m2m: "M2M",
      published: "Start Evaluation",
      published_start: "Start Evaluation",
      evaluation_done: "Evaluation Done",
      are_you_sure: "Are you sure?",
      are_you_text: "Do you want to start this assessment?",
      performance_item: {
        title: "Performance"
      },
      my_evaluatees: "My Evaluatees",
      my_evaluated: "My Evaluated",
      info_dialog_title: "Information",
      info_dialog_message:
        "Your evaluation has been processed, when the process is completed, your evaluation will be reflected in the system."
    },
    training: {
      plan: "Training Plan",
      name: "Training Name",
      type: "Training Type",
      organizer: "Organizer",
      lecturer: "Lecturer",
      terminal: "Terminal",
      sessions: "Sessions",
      session: "Session",
      training_type: {
        ONLINE: "Online",
        CLASSROOM: "Classroom",
        ON_SITE: "On Site"
      },
      training_detail: "Training Detail",
      location: "Location",
      description: "Description",
      training: "Training",
      request_training: "Request a Training",
      training_requests: "Training Requests",
      request_date: "Request Date",
      abolisher: "Abolisher",
      approval_history: "Approval History",
      cancelled_date: "Cancelled Date",
      hr_manager: "HR Manager",
      approval_date: "Approval Date",
      cancel_request: "Cancel Request",
      cancellation_note: "Cancellation note?",
      request_cancellation_process: "Training Request Cancellation Process",
      request_approval_process: "Training Request Approval Process",
      denied: "Deny",
      approved: "Approve",
      training_planned: "Is Training Planned"
    },
    expense: {
      name: "Expense Name",
      type: "Expense Type",
      amount: "Amount",
      date: "Requested Date",
      status: "Status",
      attachment: "Attachment",
      create_expense: "Request An Expense",
      travel: "Travel",
      approved_amount: "Approved Amount",
      expense_type: {
        expense_type: "Expense Type"
      },
      payment_type: {
        CASH: "Cash",
        CREDIT_CARD: "Credit Card",
        PREPAID_CARD: "Company Credit Card",
        OTHER: "Other",
        null: "Unspecified"
      }
    },
    imprest: {
      amount: "Amount",
      period: "Imprest Period",
      reason: "Reason",
      status: "Status",
      create_imprest: "Request An Imprest",
      travel: "Travel",
      approved_amount: "Approved Amount",
      date: "Date"
    },
    paper: {
      name: "Paper Name",
      type: "Paper Type",
      issue_date: "Issue Date",
      expire_date: "Expire Date",
      signature_status: "Signature Status",
      signature_types: {
        NOT_REQUIRED: "Not Required",
        STANDARD_SIGNATURE: "Standard Signature",
        BIOMETRIC_SIGNATURE: "Biometric Signature",
        SIGNED: "Signed",
        null: "-",
        E_SIGNATURE: "Digital Signature"
      },
      add_paper: "Add Paper",
      papers: {
        issue_date: "Issue Date",
        expire_date: "Expire Date"
      }
    },
    document: {
      name: "Document Name",
      type: "Document Type",
      category: "Document Category",
      sender: "Sender",
      date: "Date",
      add_document: "Add Document",
      mobile_accessible_disable: "Mobile accessible disable",
      mobile_accessible_enable: "Mobile accessible enable"
    },
    health: {
      name: "Name",
      category: "Category",
      from: "From Date",
      to: "End Date",
      disease_detail: "Disease Detail",
      importance: "Importance",
      importance_enum: {
        LOW: "Low",
        MEDIUM: "Medium",
        HIGH: "High"
      },
      medicines: "Medicines",
      attachment: "Attachment",
      description: "Description"
    },
    debit_tickets: {
      asset: "Asset",
      borrow_date: "Borrow Date",
      due_date: "Due Date",
      give_back_date: "Give Back Date",
      quantity: "Quantity",
      transfer: "Transfer",
      transfer_approval: "Transfer Approval",
      approver: "Employee To Be Transferred",
      debit_transfer: "Debit Ticket Transfer",
      download_form: "Download Form",
      DEBIT_TRANSFER_DUPLICATE_EXCEPTION: "There is a previously created transfer request for this debit ticket",
      DATA_INTEGRITY_VIOLATION_EXCEPTION: "This debit has already been registeref on you"
    },
    payroll: {
      month: "Month",
      created_date: "Created Date",
      file: "File"
    },
    events: {
      name: "Name",
      type: "Type",
      owner: "Owner",
      online: "Online",
      begin_date: "Begin Date",
      end_date: "End Date",
      activity: "Activity"
    },
    expense_approval: {
      employee: "Employee",
      description: "Description",
      amount: "Amount",
      status: "Status",
      request_date: "Request Date",
      document: "Document",
      expense_approval: "Expense Approval",
      message:
        "{0} requested an expense amount of <b>{3}</b> in type of <b>{2}</b> on <b>{1}</b>, do you want to approve?"
    },
    imprest_approval: {
      employee: "Employee",
      amount: "Amount",
      status: "Status",
      request_date: "Request Date",
      imprest_approval: "Imprest Approval",
      message: "{0} requested an imprest amount of <b>{2}</b> on <b>{1}</b>, do you want to approve?",
      approved_amount: "Approved Amount",
      requested_amount: "Requested Amount"
    },
    hiring: {
      job_posting: "Job Posting",
      applicant: "Applicant",
      applicants: "Applicants",
      interview: "Interview",
      assessment_date: "Assessment Date",
      working_date: "Working Date",
      last_job: "Last Job",
      last_position_level: "Last Position Level",
      evaluator: "Evaluator",
      evaluators: "Evaluators",
      weight: "Weight",
      link: "Link",
      skill: "Skill",
      rate: "Rate",
      rating: "Rating",
      table: {
        applicant: "Applicant",
        job_posting: "Job Posting",
        last_job: "Last Job",
        assessment: "Assessment",
        rating: "Rating",
        assessment_date: "Assessment Date",
        status: "Status",
        evaluators: "Evaluators",
        evaluator: "Evaluator"
      },
      skill_type: {
        soft: "Soft",
        technical: "Technical",
        question: "Question",
        link: "Link"
      }
    },
    message: {
      message_type: "Message Type",
      sender: "Sender",
      receivers: "Receivers",
      importance: "Importance",
      title: "Title",
      body: "Body",
      whom: "Whom",
      date: "Date",
      message_type_enum: {
        EMAIL: "E-mail",
        SMS: "Sms",
        IN_APP: "Notification"
      },
      message_importance_enum: {
        LOW: "Low",
        MEDIUM: "Medium",
        HIGH: "High"
      },
      send_message: "Send Message",
      success_message: "{1} sent to {0} person | {1} sent to {0} people"
    },
    suggestion: {
      category: "Suggestion Category",
      title: "Suggestions",
      add_suggestion: "Add Suggestion",
      body: "Message",
      owner: "Owner"
    },
    note: {
      notes: "Notes",
      title: "Title",
      body: "Body",
      attachment: "Attachment",
      description: "Description",
      add_note: "Add Note",
      owner: "Employee",
      importance: "Importance"
    },
    travel: {
      request_travel: "Request A Travel",
      leave_request: "Time Off Request",
      travels: "Travels",
      travel: "Travel",
      travel_detail: "Travel Detail",
      start_work_date: "Start Work Date",
      is_domestic: "Is Domestic?",
      country: "Country",
      city: "City",
      region: "Region",
      is_rent_car: "Is Rent Car?",
      is_shuttle: "Is Shuttle?",
      description: "Description",
      reason: "Reason",
      status: "Status",
      expense_message: "<strong>{0}</strong> requested to spend <strong>{2}</strong> on <strong>{1}</strong>",
      imprest_message:
        "<strong>{0}</strong> requested an advance payment of <strong>{2}</strong> on <strong>{1}</strong>",
      subsistence_message:
        "The employee named <strong>{0}</strong> was given a subsistence of <strong>{2}</strong> on <strong>{1}</strong>",
      travel_dates: "Travel Dates",
      pending_travel_approvals: "Pending Travel Approvals",
      travel_process: "Travel Process",
      travel_details: "Travel Details",
      no_detail_item: "No detail item",
      add_new_detail: "Add New Detail",
      detail_type: "Detail Type",
      detail_subtype: "Detail Subtype",
      travel_detail_type_enum: {
        TRANSFER: "Transfer",
        LAYOVER: "Layover",
        BUSINESS: "Business",
        TRAVEL_INSURANCE: "Travel Insurance"
      },
      travel_detail_subtype_enum: {
        BUS: "Bus",
        PLANE: "Plane",
        TRAIN: "Train",
        SHIP: "Ship",
        CAR: "Car",
        HOTEL: "Hotel",
        HOSTEL: "Hostel",
        VILLA: "Villa",
        FACTORY: "Factory",
        OFFICE: "Office",
        TRAVEL_HEALTH_POLICY: "Travel Health Policy"
      },
      begin_date: "Begin Date",
      end_date: "End Date",
      expenses: "Expenses",
      imprests: "Imprests",
      debits: "Debits",
      borrow_date: "Borrow Date",
      due_date: "Due Date",
      give_back_date: "Give Back Date",
      quantity: "Quantity",
      name: "Name",
      category: "Category",
      model: "Model",
      subsistences: "Subsistences",
      project: "Project",
      attrition: "Attrition Rate",
      new_leave_request: "Request Time Off",
      adding_expense: "Adding Expense",
      adding_imprest: "Adding Imprest",
      adding_subsistence: "Adding Subsistence",
      adding_debit: "Adding Debit",
      assignment_form: "Assignment Form"
    },
    travel_request: {
      employee: "Employee",
      begin_date: "Begin Date",
      end_date: "End Date",
      start_work_date: "Start Work Date",
      status: "Status",
      request_date: "Request Date",
      travel_approval: "Travel Approval",
      message: "<b>{0}</b> requested a travel between <b>{1}</b> - <b>{2}</b>, do you want to approve?"
    },
    vehicle: {
      plate_number: "Plate Number",
      brand: "Brand",
      model: "Model",
      year: "Year",
      branch: "Branch",
      employee: "Employee"
    },
    contact: {
      employee: "Employee",
      email: "E-mail",
      phone: "Phone Number"
    },
    projects: {
      project: "Project",
      employee_count_message: "No employees | 1 employee | {count} employees"
    },
    debit_ticket_transfer_request: {
      requester: "Requester",
      asset: "Asset",
      approval_message:
        "<strong>{0}</strong> has requested to transfer the asset which has the following information to your embezzlement, do you want to confirm?"
    },
    survey: {
      name: "Survey Name",
      published_date: "Publish Date",
      end_date: "End Date",
      start_survey: "Start Survey",
      survey_privacy: "Survey Privacy",
      status: "Status",
      pending_title: "Surveys You Should Complete",
      completed_title: "Surveys I Participated",
      expired_title: "Expired Surveys",
      complete_survey: "Complete Survey",
      survey_information: "Survey Information",
      exit: "Exit",
      save_and_finish: "Save And Finish",
      status_enum: {
        completed: "Completed",
        continuous: "Continuous",
        expired: "Expired"
      },
      section_count: "Section Count",
      question_count: "Question Count",
      estimated_duration: "Estimated Duration",
      description: "Description",
      anonymous_on: "Closed Voting",
      anonymous_off: "Open Voting",
      enter_answer: "You can enter an answer",
      your_answer: "Your answer",
      please_select: "please select"
    },
    employee_requesting: "Requester",
    employee_creating: "Reporter"
  },
  feedback: {
    reasons: "Reasons",
    reason: "Reason",
    feeling: "Face expression",
    feeling_enum: {
      BAD: "Bad",
      SAD: "Sad",
      NORMAL: "Normal",
      HAPPY: "Happy",
      ECSTATIC: "Very Happy"
    },
    title: "Feedback {0} / {1}",
    comment: "Description"
  },
  menu: {
    dashboard: "Dashboard",
    time: "Time",
    hr: "Human Resources",
    leave: "Time Off",
    clock_in_out: "Clock In / Out"
  },
  message: {
    saved_successfully: "Saved Successfully",
    registration_failed: "Saved Failed",
    updated_successfully: "Updated Successfully",
    deleted_successfully: "Deleted Successfully",
    download_email_report: "Click the download button to download the file",
    clock_ip_error: "Ip address is not among the allowed ip addresses",
    evaluation_date_range_error_message: "You are not in the active evaluation period",
    leave_calculate_error: "Calculation error",
    required_document_message: "There is document you need to upload",
    notice_period_error: "This time off must be requested at least {0} days in advance",
    max_consecutive_error: "You can request up to {0} days of time off at a time",
    min_consecutive_error: "You can request a minimum of {0} days of time off at a time",
    leave_request_exist_error: "There is a requested time off on these dates",
    total_leave_balance_error1: "You do not have enough balance for this time off",
    total_leave_balance_error2:
      "For this time off, you can use a maximum of {0} days from the advance balance, you can not request time off because your advance balance is insufficient.",
    network_error: "Network Error",
    no_record_found: "No Records Found",
    max_range_error: "You can choose {0} days at most!"
  },
  global: {
    comment: "Comment",
    description: "Description",
    day: "Day",
    note: "Note",
    employee: "Employee",
    address: "Address",
    explanation: "Explanation",
    hour: "Hour",
    month: "Month",
    phone: "Phone",
    status: "Status",
    file: "File",
    greeting: "Hello, {0}",
    search_for_employee: "Search for employee...",
    others: "Others",
    importance_enum: {
      LOW: "Low",
      MEDIUM: "Medium",
      HIGH: "High"
    },
    importance: "Importance",
    date: "Date",
    loading_message: "Loading...",
    birth_date: "Birth Date",
    min_character_message: "You must enter {0} characters at least",
    no_employee_message: "No employee was found in the criteria you searched for.",
    employees: "Employees",
    beginDate: "Begin Date",
    endDate: "End Date",
    beginTime: "Begin Time",
    endTime: "End Time",
    year: "Year",
    message: "Message",
    country: "Country",
    active_employees: "Active Employees",
    passive_employees: "Passive Employees",
    maxEmployeeCount: "Max Employee Count",
    years_old: "years old",
    print: "Print",
    expense_management: "Expense Management",
    paymentType: "Payment Type",
    employee_selected: "Select employee...|1 employee selected|{count} employees selected",
    shift_selected: "Select shift...|1 shift selected|{count} shifts selected",
    shifts_type: "{0} Shifts",
    total_hours: "Total Hours",
    name: "Name",
    reason: "Reason",
    organization: "organization",
    minute: "Minute",
    from: "From",
    to: "To",
    shift: "Shift",
    uploaded_files: "Uploaded Files",
    manager: "Manager",
    selected: "{0} selected"
  },
  assessment: {
    very_poor: "Very Poor",
    poor: "Poor",
    normal: "Normal",
    good: "Good",
    very_good: "Very Good",
    na: "Not Assessment"
  },
  buttons: {
    home: "Dashboard",
    ok: "Ok",
    delete: "Delete",
    back: "Back",
    save: "Save",
    update: "Update",
    edit: "Edit",
    cancel: "Cancel",
    close: "Close",
    dont_save: "Exit without saving",
    dont_save_continue: "Continue without saving",
    save_changes: "Save Changes",
    evaluation_finish: "Finish Evaluation",
    assign: "Assign",
    add: "Add {0}",
    add_and_new: "Add and New",
    refresh: "Refresh",
    filter: "Filter",
    export: "Export",
    create: "Create {0}",
    create_and_new: "Create and New",
    download: "Download",
    yes: "Yes",
    no: "No",
    clear: "Clear",
    filter_results: "Filter Results",
    delete_document: "Delete Document",
    scan: "Scan",
    send_message: "Send Message",
    send: "Send",
    apply: "Apply",
    info: "Information",
    prev: "Previous {0}",
    next: "Next {0}",
    list_table: "Table Mode",
    list_card: "Card Mode",
    continue: "Continue",
    show_all: "Show All",
    cancel_leave: "Cancel Time Off",
    select_all: "Select All",
    upload: "Upload",
    file_upload: "File Upload",
    file_download: "File Operations",
    import: "Import",
    upload_template: "Upload Template",
    download_template: "Download Template",
    trigger: "Trigger",
    action: "Action",
    send_registration_credentials: "Send Registration Credentials",
    on: "On",
    off: "Off",
    uploads: {
      employee: "Import Employees",
      leave: "Import Time Off"
    },
    multiple_delete_shift: "Bulk Shift Delete",
    multiple_upload_shift: "Bulk Shift Upload",
    multiple_actions: "Bulk Actions",
    send_email_and_sms_notification: "Send E-mail & SMS Notification",
    add_expense: "Add Expense",
    add_imprest: "Request An Imprest",
    add_overtime: "Request An Overtime",
    add_leave_request: "Add Time Off Request",
    evaluatee: "Evaluatee",
    add_skill: "Add Skill",
    sign: "Sign",
    skip: "Skip",
    back_to_home: "Back to Home",
    please_select: "Please select...",
    preview: "Preview"
  },
  response: {
    success: {
      ok: "The operation is successful",
      update: "Updated Successfully",
      delete: "Deleted Successfully",
      send_sms_password_code: "Password reset code was sent as SMS message"
    },
    error: {
      user_not_found: "Username or password is invalid",
      network_error: "Network Error"
    }
  },
  request_status: {
    PENDING: "Pending Approve",
    pending: "Pending Approve",
    approved: "Approved",
    APPROVED: "Approved",
    denied: "Denied",
    DENIED: "Denied",
    cancelled: "Cancelled",
    CANCELLED: "Cancelled",
    NEED_EXTRA_DETAIL: "Need Extra Detail",
    need_extra_detail: "Need Extra Detail",
    undefined: "Undefined"
  },
  shift_types: {
    FIX: "Fix",
    FLEXIBLE: "Flexible",
    OFF_DAY: "Off Day",
    OFFDAY: "Off Day",
    HOLIDAY: "Holiday",
    WORKDAY: "Workday",
    BREAK: "Break",
    OVERTIME: "Overtime",
    OVERTIME_SHIFT: "Overtime",
    ON_LEAVE: "On Time Off"
  },
  shift: {
    overtime: "Overtime",
    missing_time: "Missing Time",
    shift_parameters: "Shift Parameters",
    break: "Break",
    sub_events: "Sub Events",
    included_calculation: "Included in the calculation",
    not_included_calculation: "Not included in the calculation",
    included_overtime_calculation: "Overtime is not included in the calculation",
    not_included_overtime_calculation: "Overtime is included in the calculation",
    not_included_missing_time_calculation: "Missing time is not included in the calculation",
    included_missing_time_calculation: "Missing time is  included in the calculation",
    work_day: "Work Day",
    overtime_day: "Overtime Day",
    full_time: "Full Time",
    absent: "Absent",
    unpaid_leave: "Unpaid Time Off",
    paid_leave: "Paid Time Off",
    public_holiday: "Public Holiday",
    on_leave: "On Time Off",
    off_day: "Off Day",
    break_day: "Break Day",
    incalculable: "Incalculable Shift"
  },
  sensors: {
    NFC: "Nfc",
    FACE: "Face",
    RFID: "Rfid",
    FINGER: "Fingerprint",
    BLUETOOTH: "Bluetooth",
    GATEWARE: "Gateware",
    BEACON: "Beacon",
    GPS: "Gps",
    WIFI: "Wifi",
    ID: "Id",
    QR: "Qr",
    QR_CODE: "QR Code",
    PASSWORD: "Password",
    PIN: "Pin",
    CARD: "Card",
    FIXED: "Fixed",
    EXTERNAL: "External",
    JAN: "JAN",
    WEB: "Web"
  },
  identity_type: {
    FACE: "Face",
    PASSWORD: "Password",
    PIN: "Pin",
    FINGER: "Fingerprint",
    QR_CODE: "QR Code",
    CARD: "Card",
    EXTERNAL: "External"
  },
  event_types: {
    BREAK_IN: "Break In",
    BREAK_OUT: "Break Out",
    LUNCH_IN: "Lunch In",
    LUNCH_OUT: "Lunch Out",
    DUTY_IN: "Duty In",
    DUTY_OUT: "Duty Out",
    CLOCK_IN: "Clock In",
    CLOCK_OUT: "Clock Out",
    UNKNOWN: "In or Out",
    undefined: "Unknown"
  },
  currency: {
    amount: "Amount",
    code: "Code",
    requested_amount: "Requested Amount",
    approved_amount: "Approved Amount",
    codes: {
      TRY: "TRY",
      USD: "USD",
      EUR: "EUR"
    }
  },
  titles: {
    expense: {
      create: "Add Expense",
      detail: "Expense Detail"
    },
    imprest: {
      create: "Add Imprest",
      detail: "Imprest Detail"
    }
  },
  language: {
    tr: "Turkish",
    en: "English",
    de: "Deutsch",
    TURKISH: "Turkish",
    ENGLISH: "English",
    GERMAN: "Deutsch"
  },
  status: {
    active: "Active",
    passive: "Passive",
    enable: "Enable",
    disable: "Disable"
  },
  gender: {
    male: "Male",
    female: "Female",
    other: "Other",
    others: "Others",
    MALE: "Male",
    FEMALE: "Female",
    OTHER: "Other"
  },
  marital_status: {
    married: "Married",
    single: "Single",
    divorced: "Divorced"
  },
  education: {
    student: "Student",
    primary_school: "Primary School",
    secondary_school: "Secondary School",
    high_school: "High School",
    collage: "Collage",
    university: "University",
    master: "Master",
    phd: "PhD"
  },
  education_languages: {
    turkish: "Turkish",
    english: "English",
    german: "German",
    french: "French",
    dutch: "Dutch",
    spanish: "Spanish",
    italian: "İtalian",
    arabic: "Arabic",
    russian: "Russian",
    persian: "Persian",
    chinese: "Chinese",
    japanese: "Japanese",
    hindi: "Hindi",
    latin: "Latin",
    portuguese: "Portoguese",
    korean: "Korean"
  },
  military_service: {
    done: "Done",
    undone: "Undone",
    exempt: "Exempt",
    suspended: "Suspended"
  },
  academic_title: {
    lecturer: "Lecturer",
    research_assistant: "Research Assistant",
    assistant_professor: "Assistant Proffessor",
    associate_professor: "Associate Proffessor",
    professor: "Professor"
  },
  employment_type: {
    full_time: "Full Time",
    part_time: "Part Time",
    casual: "Casual",
    contracted: "Contracted",
    freelance: "Freelancer",
    intern: "Intern",
    mobile: "Mobile",
    retired: "Retired",
    trial: "Trial",
    seasonal: "Seasonal",
    disabled: "Disabled"
  },
  collar_type: {
    white: "White",
    blue: "Blue",
    gray: "Gray"
  },
  quite_job_reasons: {
    resignation: "Resignation",
    cancellation_of_labor_contract: "Cancellation of Labor Contract",
    military_service: "Military Service",
    other: "Other"
  },
  blood_type: {
    o_rh_negative: "0-",
    o_rh_positive: "0+",
    a_rh_negative: "A-",
    a_rh_positive: "A+",
    b_rh_negative: "B-",
    b_rh_positive: "B+",
    ab_rh_negative: "AB-",
    ab_rh_positive: "AB+"
  },
  time_duration: {
    hour: "Hour",
    minute: "Minute",
    second: "Second"
  },
  date: {
    year: "Year",
    month: "Month",
    day: "Day",
    time: "Time",
    week: "Week",
    minute: "Minute"
  },
  validations: {
    messages: {
      phone: "The {_field_} field must be a valid phone number",
      password_match: "Passwords don't match",
      integer: "The {_field_} field must be an integer"
    }
  },
  nationalities: {
    Afghan: "Afghan",
    Albanian: "Albanian",
    Algerian: "Algerian",
    American: "American",
    Andorran: "Andorran",
    Angolan: "Angolan",
    Antiguans: "Antiguans",
    Argentinean: "Argentinean",
    Armenian: "Armenian",
    Australian: "Australian",
    Austrian: "Austrian",
    Azerbaijani: "Azerbaijani",
    Bahamian: "Bahamian",
    Bahraini: "Bahraini",
    Bangladeshi: "Bangladeshi",
    Barbadian: "Barbadian",
    Barbudans: "Barbudans",
    Botswana: "Botswana",
    Belarusian: "Belarusian",
    Belgian: "Belgian",
    Belizean: "Belizean",
    Beninese: "Beninese",
    Bhutanese: "Bhutanese",
    Bolivian: "Bolivian",
    Bosnian: "Bosnian",
    Brazilian: "Brazilian",
    British: "British",
    Bruneian: "Bruneian",
    Bulgarian: "Bulgarian",
    Burkinabe: "Burkinabe",
    Burmese: "Burmese",
    Burundian: "Burundian",
    Cambodian: "Cambodian",
    Cameroonian: "Cameroonian",
    Canadian: "Canadian",
    "Cape Verdean": "Cape Verdean",
    "Central African": "Central African",
    Chadian: "Chadian",
    Chilean: "Chilean",
    Chinese: "Chinese",
    Colombian: "Colombian",
    Comoran: "Comoran",
    Congolese: "Congolese",
    "Costa Rican": "Costa Rican",
    Croatian: "Croatian",
    Cuban: "Cuban",
    Cypriot: "Cypriot",
    Czech: "Czech",
    Danish: "Danish",
    Djibouti: "Djibouti",
    Dominican: "Dominican",
    Dutch: "Dutch",
    "East Timorese": "East Timorese",
    Ecuadorean: "Ecuadorean",
    Egyptian: "Egyptian",
    Emirian: "Emirian",
    "Equatorial Guinean": "Equatorial Guinean",
    Eritrean: "Eritrean",
    Estonian: "Estonian",
    Ethiopian: "Ethiopian",
    Fijian: "Fijian",
    Filipino: "Filipino",
    Finnish: "Finnish",
    French: "French",
    Gabonese: "Gabonese",
    Gambian: "Gambian",
    Georgian: "Georgian",
    German: "German",
    Ghanaian: "Ghanaian",
    Greek: "Greek",
    Grenadian: "Grenadian",
    Guatemalan: "Guatemalan",
    "Guinea-Bissauan": "Guinea-Bissauan",
    Guinean: "Guinean",
    Guyanese: "Guyanese",
    Haitian: "Haitian",
    Herzegovinian: "Herzegovinian",
    Honduran: "Honduran",
    Hungarian: "Hungarian",
    "I-Kiribati": "I-Kiribati",
    Icelander: "Icelander",
    Indian: "Indian",
    Indonesian: "Indonesian",
    Iranian: "Iranian",
    Iraqi: "Iraqi",
    Irish: "Irish",
    Israeli: "Israeli",
    Italian: "Italian",
    Ivorian: "Ivorian",
    Jamaican: "Jamaican",
    Japanese: "Japanese",
    Jordanian: "Jordanian",
    Kazakhstani: "Kazakhstani",
    Kenyan: "Kenyan",
    "Kittian and Nevisian": "Kittian and Nevisian",
    Kuwaiti: "Kuwaiti",
    Kyrgyz: "Kyrgyz",
    Laotian: "Laotian",
    Latvian: "Latvian",
    Lebanese: "Lebanese",
    Liberian: "Liberian",
    Libyan: "Libyan",
    Liechtensteiner: "Liechtensteiner",
    Lithuanian: "Lithuanian",
    Luxembourger: "Luxembourger",
    Macedonian: "Macedonian",
    Malagasy: "Malagasy",
    Malawian: "Malawian",
    Malaysian: "Malaysian",
    Maldivan: "Maldivan",
    Malian: "Malian",
    Maltese: "Maltese",
    Marshallese: "Marshallese",
    Mauritanian: "Mauritanian",
    Mauritian: "Mauritian",
    Mexican: "Mexican",
    Micronesian: "Micronesian",
    Moldovan: "Moldovan",
    Monacan: "Monacan",
    Mongolian: "Mongolian",
    Moroccan: "Moroccan",
    Mosotho: "Mosotho",
    Motswana: "Motswana",
    Mozambican: "Mozambican",
    Namibian: "Namibian",
    Nauruan: "Nauruan",
    Nepalese: "Nepalese",
    "New Zealander": "New Zealander",
    Nicaraguan: "Nicaraguan",
    Nigerian: "Nigerian",
    Nigerien: "Nigerien",
    "North Korean": "North Korean",
    "Northern Irish": "Northern Irish",
    Norwegian: "Norwegian",
    Omani: "Omani",
    Pakistani: "Pakistani",
    Palauan: "Palauan",
    Panamanian: "Panamanian",
    "Papua New Guinean": "Papua New Guinean",
    Paraguayan: "Paraguayan",
    Peruvian: "Peruvian",
    Polish: "Polish",
    Portuguese: "Portuguese",
    Qatari: "Qatari",
    Romanian: "Romanian",
    Russian: "Russian",
    Rwandan: "Rwandan",
    "Saint Lucian": "Saint Lucian",
    Salvadoran: "Salvadoran",
    Samoan: "Samoan",
    "San Marinese": "San Marinese",
    "Sao Tomean": "Sao Tomean",
    Saudi: "Saudi",
    Scottish: "Scottish",
    Senegalese: "Senegalese",
    Serbian: "Serbian",
    Seychellois: "Seychellois",
    "Sierra Leonean": "Sierra Leonean",
    Singaporean: "Singaporean",
    Slovakian: "Slovakian",
    Slovenian: "Slovenian",
    "Solomon Islander": "Solomon Islander",
    Somali: "Somali",
    "South African": "South African",
    "South Korean": "South Korean",
    Spanish: "Spanish",
    "Sri Lankan": "Sri Lankan",
    Sudanese: "Sudanese",
    Surinamer: "Surinamer",
    Swazi: "Swazi",
    Swedish: "Swedish",
    Swiss: "Swiss",
    Syrian: "Syrian",
    Taiwanese: "Taiwanese",
    Tajik: "Tajik",
    Tanzanian: "Tanzanian",
    Thai: "Thai",
    Togolese: "Togolese",
    Tongan: "Tongan",
    "Trinidadian or Tobagonian": "Trinidadian or Tobagonian",
    Tunisian: "Tunisian",
    Turkish: "Turkish",
    Tuvaluan: "Tuvaluan",
    Ugandan: "Ugandan",
    Ukrainian: "Ukrainian",
    Uruguayan: "Uruguayan",
    Uzbekistani: "Uzbekistani",
    Venezuelan: "Venezuelan",
    Vietnamese: "Vietnamese",
    Welsh: "Welsh",
    Yemenite: "Yemenite",
    Zambian: "Zambian",
    Zimbabwean: "Zimbabwean"
  },
  disability_level: {
    first_level: "First Level",
    second_level: "Second Level",
    third_level: "Third Level"
  },
  social_security_type: {
    SS_4A: "4A",
    SS_4B: "4B",
    SS_4C: "4C"
  },
  clock_ip_error: "Ip address is not among the allowed ip addresses",
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday"
  },
  api_error: {
    756: "There is a time off request between the dates you requested",
    766: "Your password must be at least 8 and at most 16 characters long",
    767: "Your password must contain lowercase letters, uppercase letters, numbers and special characters: .-/@#$%^&_+=()",
    768: "Your password cannot include your name, surname or staff number",
    769: "This password has been used before, please enter a different password than your last {0} passwords"
  },
  http: {
    200: "Successfully send",
    201: "Successfully",
    202: "Successfully saved",
    401: "Unauthorized",
    404: "Not found",
    409: "Data conflict",
    415: "Unsupportted Media Type",
    204: "Successfully",
    304: "Not Modified",
    500: "Internel Server Error",
    503: "Service Unavailable"
  },
  expense_items: "Expense Items",
  expense_item_name: "Expense Item Name",
  update_expense_item: "Update Expense Item",
  add_expense_item: "Add Expense Item",
  file_format_error: "File Format Error",
  file_size_error: "File Size Error",
  not_selected_expense_item_error_message: "You did not add an expense item",
  expense_description: "Expense Description",
  abolisher_employee: "Abolisher Employee",
  expense_manager: "Expense Manager",
  event_date: "Event Date",
  cancelled_date: "Cancellation Date",
  approval_history: "Approval History",
  request_processes: {
    super_admin: "Approved by Super Admin",
    approved: "Approved the request",
    pending: "Pending for approval",
    stopped: "Approval process stopped because the request was rejected",
    waiting: "It's not time for approval",
    denied: "Request denied"
  },
  sso_forgot_password_warning: {
    title: "Warning",
    message: "Please log in to Idenfit using your Accenture email."
  },
  time_abbr: {
    hour: "hr",
    minute: "min",
    second: "sec"
  },
  vedubox_user_not_found: "Vedubox User Not Found",
  gdpr: {
    acceptance: {
      title: "KVKK Açık Rıza Metni Onayı",
      checkbox: "KVKK Açık Rıza metnini onaylıyorum.",
      save: "Kaydet ve Devam Et",
      formError: "Devam edebilmek için KVKK Açık Rıza metnini onaylamanız gerekmektedir."
    }
  },
  web_terminal_loading: "Web terminal is loading",
  web_terminal_not_found: "Web terminal is not found",
  multiple_assigned_shift_warning: "You have more than one assigned shift. Please choose one of the shifts below.",
  paper_approval: "Paper Approval",
  paperApprovalProcessWarningMessage: "You need to download or preview the file",
  minLength: "At least 8 characters",
  hasLowerCaseAndUpperCase: "Contains digit, uppercase and lowercase letters",
  hasSpecialCharacter: "Contains special characters",
  passwordExpireNotificationMessage:
    "There are 0 day left until your password expires | There are 1 day left until your password expires | There are {count} days left until your password expires",
  pendingRequests: "Pending Requests",
  allPendingRequests: "All Pending Requests",
  maxImprestAmountMessage: "Maximum limit that can be requested: {0}",
  or: "or",
  continueWithMicrosoft: "Continue with Microsoft"
};
