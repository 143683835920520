export default {
  auth: {
    email: "Correo electrónico",
    password: "Contraseña",
    logout: "Salir de",
    sign_in: "Iniciar sesión",
    sign_in_with_sso: "Iniciar sesión con SSO",
    login_screen: "pantalla de inicio de sesión",
    back_to_sign_in: "Volver a la pantalla de inicio",
    reset_password: "Restablecer contraseña",
    reset_password_code: "Restablecer código",
    forget_password: "Contraseña olvidada",
    new_password: "Nueva contraseña ",
    re_new_password: "Nueva contraseña (otra vez)",
    login_failed: "Idenfit no pudo encontrar su ID",
    change_password: "Cambiar contraseña",
    continue: "Continuar",
    showcase: {
      welcome: "Bienvenido^3000",
      cloud: "Plataforma en la nube de identificación y gestión de personal de próxima generación^3000",
      identify: "Le permite identificar, rastrear y gestionar su fuerza de trabajo de forma fácil y segura^4000",
      manage: "Puede gestionar en cualquier momento, en cualquier lugar y de cualquier manera que desee^4000",
      freedom: "Puede ver todo su sistema^3000"
    },
    email_not_found: "No se ha encontrado esta dirección de correo electrónico",
    invalid_reset_token: "Código de reinicio no válido",
    reset_password_expired: "Debe esperar al menos 3 minutos para restablecer una nueva contraseña",
    reset_code_expired: "El código de restablecimiento de contraseña ha caducado",
    send_sms_password_code: "El código de renovación de contraseña fue enviado como sms",
    password_change_successfully: "Su contraseña ha sido cambiada con éxito",
    invalid_email_or_password: "El correo electrónico o la contraseña son incorrectos",
    invalid_password: "La contraseña es incorrecta",
    current_password: "Su contraseña actual",
    sso_error: "Se ha producido un error"
  },
  dashboard: {
    access: {
      table: {
        last_event: "Eventos recientes",
        personal: "Empleado",
        terminal_type: "Tipo de terminal",
        general_info: "Información General",
        terminal: "Terminal",
        event_type: "Tipo de Evento",
        identity_type: "Tipo de Identidad",
        time: "Hora",
        event: "Evento",
        location: "Localización",
        place: "Localización",
        place_beacon: "Localización Baliza",
        minor: "Menor",
        major: "Mayor",
        parent: "Ubicación a la que está conectado",
        duration: "Tiempo de estancia",
        first_seen: "Hora de entrada",
        last_seen: "Hora de salida",
        max_person_count: "Número máximo de personas",
        begin_date_time: "Hora de inicio",
        end_date_time: "Hora de finalización",
        employee_dialog_title: "Información de entrada y salida de empleados",
        empty_zone_message: "No hay nadie en la zona",
        loading_message: "Creando"
      },
      monthly_dashboard: "Tablero Mensual",
      monthly_events: "Eventos Mensuales",
      monthly_timesheet_report: "Mi Informe Mensual de Horarios",
      absence: "Ausencias",
      incalculable: "Mis incalculables",
      lates: "Retrasos",
      overtime: "Demandas de horas extraordinarias",
      early_leaver: "Citas Anticipadas",
      missing_time: "Mis Turnos Perdidos",
      expected: "Esperados",
      total_tardiness_average: "Promedio de Tardanzas",
      total_leaver_average: "Promedio de Salidas Tempranas",
      total_overtime_average: "Promedio de Horas Extra",
      tardiness_minute: "En el momento en que es tarde",
      early_minute: "Es hora de salir temprano",
      overtime_minute: "Minutos de horas extra",
      short_minute: "min",
      today: "Hoy"
    },
    currency: {
      buying: "Compras",
      selling: "Ventas",
      currencies: "Moneda"
    },
    about: {
      about: "Quiénes somos",
      mission: "Misión",
      vision: "Visión"
    },
    menu_of_day: "Menú del día",
    messages: {
      no_data_reminders: "No hay notificación de recordatorio",
      no_data_messages: "No hay notificación de mensaje",
      no_data_leaves: "No hay solicitud de permiso",
      no_data_overtimes: "No hay solicitud de horas extraordinarias",
      no_data_expenses: "No hay solicitud de gastos",
      no_data_imprests: "No hay solicitud de anticipos",
      no_data_incalculables: "No hay empleados no responsables",
      no_data_absences: "No hay empleados ausentes"
    },
    request_type: {
      leave: "Solicitudes de permisos",
      overtime: "Demandas de horas extraordinarias",
      expense: "Reclamaciones de gastos",
      imprest: "Solicitudes de anticipos"
    },
    weather_forecast: "Previsión meteorológica y tipos de cambio actuales",
    reminder_tab_title: "Recordatorios",
    message_tab_title: "Mensajes",
    data_type_enum: {
      LEAVE_REQUEST: "Solicitudes de permisos",
      ACTIVITY: "Eventos",
      REMINDER: "Recordatorios"
    }
  },
  time: {
    crud: {
      create_overtime: "Crear solicitud de horas extra"
    },
    event: {
      clock_in: "Entrada",
      clock_out: "Salida",
      duty_in: "Entrada de Tareas",
      duty_out: "Salida de tarea",
      break_in: "Entrada Pausa",
      break_out: "Salida de pausa",
      lunch_in: "Entrada de Comida",
      lunch_out: "Salida de comida"
    },
    menu: {
      shifts: "Turnos",
      timesheets: "Horario",
      overtime_requests: "Horas extras",
      overtime_approval: "Solicitudes Pendientes de Aprobación",
      employee_calendar: "Calendario del Empleado"
    },
    shift_types: {
      FIX: "Fijo",
      FLEXIBLE: "Flexible",
      OFF_DAY: "dias festivo de la semana",
      OFFDAY: "dias festivo de la semana",
      HOLIDAY: "Día festivo",
      WORKDAY: "Día laborable",
      BREAK: "Día de descanso"
    },
    overtime_approval: {
      employee: "Empleado",
      workplan: "Turno",
      request_status: "Estado",
      reason: "Motivo",
      request_time: "Fecha de solicitud"
    },
    overtime_request: {
      approve_title: "Aprobación de Horas Extra",
      add_overtime: "Crear solicitud de horas extras",
      date: "Historial de turnos",
      workplan: "Turno",
      overtime_in_minutes: "Tiempo de trabajo",
      short_minute: "min",
      request_date: "Fecha de solicitud",
      period: "Período",
      periods: {
        CLOCK_IN: "Entrada de Trabajo",
        CLOCK_OUT: "Salida de trabajo",
        CLOCK_IN_OUT: "Entrada y Salida del Turno"
      },
      message: "¿Desea aprobar la solicitud de horas extras de {0} <b> {2} min </b> para el turno de {1}?"
    },
    timesheet: {
      employee: "Empleado",
      employees: "Personal",
      date: "Historial",
      type: "Tipo",
      work_plan: "Plan de Trabajo",
      shift: "Turno",
      clock_in: "Introducción",
      clock_in_title: "Hora de Entrada",
      late: "Retraso",
      reason: "Motivo",
      late_title: "Entrada Tardía",
      it: "KT",
      it_title: "Tipo de identidad",
      terminal: "Terminal",
      clock_out: "Salida",
      clock_out_title: "Hora de salida",
      early: "Temprano",
      early_title: "Salida Temprana",
      ewt: "TTE",
      ewt_title: "Tiempo de Trabajo Esperado",
      awt: "GÇS",
      twt_title: "Tiempo Total de Trabajo",
      twt: "TTT",
      awt_title: "Tiempo de trabajo realizado",
      ot: "HE",
      ot_title: "Horas extraordinarias",
      mt: "TI",
      mt_title: "Turno Incompleto",
      name: "Empleado",
      time: "Tiempo",
      identity_type: "Tipo de Identidad",
      event_type: "Tipo de Evento",
      event_time: "Hora del evento",
      all_events: "Todos los eventos",
      status: "Estado",
      shift_name: "Nombre de turno",
      branch: "Sucursal",
      unit: "Unidad",
      team: "Equipo",
      jobInfo: "Misión",
      staffNumber: "Nº de Registro",
      information: {
        work_day: "Día de trabajo",
        work_day_desc: "Significa que el personal vendrá a trabajar ese día",
        work_off: "Dias festivos de la semana",
        work_off_desc: "Ese día es festivo para el personal",
        public_holiday: "Día festivo",
        public_holiday_desc: "Ese día es fiesta oficial para el personal",
        did_not_come_to_work: "No vino a trabajar",
        did_not_come_to_work_desc: "El empleado no vino a trabajar ese día, aunque era su turno",
        on_leave: "Está de permiso",
        on_leave_desc: "El empleado tiene el día libre",
        calculated: "Calculado",
        calculated_desc: "El texto en color verde significa que el cálculo se ha realizado",
        not_calculated: "No se ha podido calcular",
        not_calculated_desc: "El texto en rojo significa que no se puede calcular."
      },
      total: "Total",
      ot_mt: "Diferencia"
    },
    calendar: {
      holiday: "Días festivos",
      off_day: "Dias festivos de la semana",
      breakday: "Día de descanso",
      employee_overtime: "Si el empleado acude a trabajar en esta fecha, se computa como hora extra",
      employee_off_day: "Es la fiesta semanal del empleado",
      employee_break_day: "Es un día de descanso para el empleado",
      shift_loading: "Se organizan los turnos de Idenfit",
      create_shift: "Crear Turno",
      shift_detail: "Detalle Turno",
      leave_type: "Tipo de permiso",
      begin_time: "Hora de inicio",
      end_time: "Hora de finalización",
      break_time: "Tiempo de pausa",
      shift_time: "Duración del Turno",
      clock_in_max_overtime: "Horas Extra Máximas de Entrada",
      clock_out_max_overtime: "Hora Extra Máxima de Salida",
      lately_tolerance: "Tolerancia de Retrasos",
      early_tolerance: "Tolerancia de Salida Anticipada",
      overtime: "Horas extraordinarias",
      early_clock_out_tolerance: "Tolerancia de salida anticipada",
      lately_clock_in_tolerance: "Tolerancia de retraso",
      max_clock_in_overtime_in_minutes: "Tiempo Extra Máximo de Entrada",
      max_clock_out_overtime_in_minutes: "Hora Extra Máxima de Salida",
      min_clock_in_overtime_in_minutes: "Tiempo Extra Mínimo de Entrada",
      min_clock_out_overtime_in_minutes: "Horas extraordinarias mínimas de salida",
      minimum_overtime: "Horas extraordinarias mínimas",
      maximum_overtime: "Horas extra máximas",
      midnight_tolerance: "Tolerancia Solsticio",
      shift_type: "Tipo de Turno",
      count_as_overtime: "Horas Extras",
      total_work_time: "Tiempo total de trabajo",
      selectWeekends: "Días de permisos semanales",
      selectBreaks: "Días de Descanso",
      overtime_request: {
        approve_title: "Aprobación de Horas Extra",
        add_overtime: "Crear solicitud de horas extras",
        detail_overtime: "Detalle de solicitud de horas extras",
        date: "Historial de turnos",
        workplan: "Turno",
        overtime_in_minutes: "Tiempo de trabajo",
        request_status: "Estado",
        request_date: "Fecha de solicitud",
        approval_date: "Fecha de Aprobación",
        period: "Período",
        history: "Historial de confirmaciones",
        periods: {
          CLOCK_IN: "Entrada de trabajo",
          CLOCK_OUT: "Salida del trabajo",
          CLOCK_IN_OUT: "Entrada y Salida de Turno"
        },
        approved: "{0} <b>¿Desea aprobar la solicitud de horas extras de {2} min </b> para su turno de {1}?",
        rejected: "{0} <b>¿Desea rechazar la solicitud de horas extras de {2} min </b> para su turno de {1}?",
        approval_to_manager: "Enviar aprobación al Gestor de Horas Extra",
        not_approval_to_manager: "No enviar aprobación al Gestor de Horas Extra",
        pending_approval_manager: "Gestor pendiente de aprobación",
        processes: {
          approved: "Ha aprobado la solicitud",
          pending: "Se espera que la autorice",
          stopped: "El proceso de aprobación se detuvo porque la solicitud fue rechazada",
          waiting: "Está esperando a que el gestor anterior la apruebe",
          denied: "Solicitud rechazada"
        }
      },
      multiple_shift_deleting: "Borrado de turno de lotes",
      cancel_request: "Cancelar",
      cancel_request_title: "Cancelar solicitud de turno"
    }
  },
  leave: {
    crud: {
      create: "Crear solicitud de permisos"
    },
    menu: {
      leave_accruals: "Derechos de permisos",
      leave_requests: "Solicitudes de permisos",
      leave_approvals: "Autorizaciones de permisos  pendientes"
    },
    hourly: "Permisos por horas",
    daily: "Permisos por dias",
    type: "Tipo de permisos",
    begin_time: "Hora de inicio",
    end_time: "Hora de finalización",
    begin_date: "Fecha de inicio",
    end_date: "Fecha de finalización",
    approved_time: "Fecha de permiso",
    request_time: "Fecha de solicitud",
    approve_or_reject: "Aprobar o Rechazar",
    status: "Estado",
    no_approver: "Sin confirmación",
    manager: "Gestor de permisos",
    managers: "Gestores de permisos",
    balance: "Saldo",
    not_leave_accruals: "Este empleado aún no ha obtenido permisos",
    permission: {
      employee_leave_approver_message: "Este empleado puede aprobar solicitudes de permisos de otros empleados",
      employee_not_leave_approver_message: "Este empleado no puede aprobar solicitudes depermisos de otros empleados",
      employee_shift_approver_message: "Este empleado puede asignar turnos a otros empleados",
      employee_not_shift_approver_message: "Este empleado no puede asignar turnos a otros empleados",
      employee_overtime_approver_message: "Este empleado puede aprobar solicitudes de horas extras de otros empleados",
      employee_not_overtime_approver_message:
        "Este empleado no puede aprobar solicitudes de horas extras de otros empleados"
    },
    notification: {
      employee_receiver_leave_notification_message:
        "Este empleado puede recibir notificaciones de permisos de otros empleados",
      employee_not_receiver_leave_notification_message:
        "Este empleado no puede recibir notificaciones de permisos de otros empleados",
      employee_receiver_shift_notification_message:
        "Este empleado puede recibir notificaciones de asignación de turnos de otros empleados",
      employee_not_receiver_shift_notification_message:
        "Este empleado no puede recibir notificaciones de asignación de turnos de otros empleados",
      employee_receiver_overtime_notification_message:
        "Este empleado puede recibir notificaciones de reclamación de horas extras de otros empleados",
      employee_not_receiver_overtime_notification_message:
        "Este empleado no puede recibir notificaciones de solicitud de horas extras de otros empleados."
    },
    respect: "Los gestores de permisos anteriores tendrán prioridad",
    order_is_important: "El orden del gestor es importante",
    already_selected_manager: "Ya ha elegido este gestor",
    there_is_no_leave_manager: "No hay ningún gestor de permisos designado",
    add_leave_manager: "Añadir gestor de permisos",
    used: "Utilizado",
    remaining: "Restante",
    on_leave: "Permiso",
    period: "Duración",
    duration: "Duración",
    periods: {
      first_half: "Mitad 1",
      second_half: "Semestre 2",
      first_quarter: "1er trimestre",
      second_quarter: "2º trimestre",
      third_quarter: "3er trimestre",
      fourth_quarter: "4º trimestre"
    },
    request: {
      this_day_does_not_count_as_leave: "Hoy {0} no cuenta como día libre",
      leave_balance: "Saldo de permisos",
      max_exceed_leave_balance: "Saldo Anticipado",
      leave_request_count: "Solicitado",
      used: "Utilizado",
      remaining: "Restante",
      approved: "Confirmar",
      denied: "Rechazar",
      leave_decreased_count: "Dejar Deducir"
    },
    accruals: {
      name: "Nombre del Permiso",
      count: "Pago total",
      used: "Utilizado",
      balance: "Remanente",
      previous_balance: "Saldo anterior"
    },
    approvals: {
      leave_approval: "Aprobación de la permisos",
      message:
        "¿Desea aprobar la solicitud de permisos de <b>{5}</b> días realizada entre <b>{3}</b> y <b>{4}</b> por un empleado llamado <b>{0}</b> con un saldo de permisos de <b>{1}</b> días?"
    },
    cancels: {
      leave_cancel: "Anulación de permiso",
      message: "¿Motivo de la anulación?",
      error_message: "El campo ¿Motivo de la anulación? es obligatorio"
    },
    error_messages: {
      notice_period_error: "Este permiso debe solicitarse con una antelación mínima de {0} días",
      max_consecutive_error: "Puede solicitar permisos por un máximo de {0} días cada vez",
      min_consecutive_error: "Puede solicitar un permiso de un máximo de {0} días cada vez",
      leave_request_exist_error: "Hay una permisosolicitada en estas fechas",
      total_leave_balance_error1: "No dispone de fondos suficientes para esta permiso",
      total_leave_balance_error2:
        "Puede utilizar un máximo de {0} días de saldo anticipado para estas permisos, No puede solicitar permisos porque su saldo anticipado no es suficiente"
    },
    e_signature: "Firma digital",
    e_signature_leave_form: "Formulario de permiso firmado electrónicamente",
    leave_form: "Formulario de permiso",
    signed: "Firmado",
    not_signed: "No firmado",
    requests: {
      days_off_automatically: "Días añadidos automáticamente",
      requested_leave_type_documents: "Documentos necesarios para la permiso"
    },

    today_counts_as_a_day_off: "Hoy cuenta como día libre",
    required_document_message: " | Tiene {count} documentos para subir | Tiene {count} documentos para subir",
    isNexyDayMessage: "Comienzo del permiso al día siguiente"
  },
  leave_request_status: {
    pending: "En espera de aprobación",
    PENDING: "En espera de aprobación",
    approved: "Aprobado",
    APPROVED: "Aprobado",
    denied: "Rechazado",
    DENIED: "Rechazado",
    rejected: "Rechazado",
    REJECTED: "Rechazado",
    cancelled: "Anulado",
    CANCELLED: "Anulado",
    need_extra_detail: "Se solicita información adicional",
    NEED_EXTRA_DETAIL: "Detalles adicionales solicitados",
    null: "Desconocido",
    NULL: "Desconocido",
    undefined: "Desconocido",
    UNDEFINED: "Desconocido",
    closed: "Cerrado",
    CLOSED: "Cerrado",
    close_pending: "Esperando el Cierre",
    CLOSE_PENDING: "En espera de cierre",
    NOT_COME: "Fuera de servicio",
    not_come: "Fuera de servicio",
    super_admin: "Aprobación del administrador",
    SUPER_ADMIN: "Aprobación del administrador"
  },
  time_duration: {
    hour: "Horas",
    minute: "Minutos",
    second: "segundos"
  },
  leave_type: {
    paid: "De pago",
    unpaid: "Gratuito",
    on_duty: "Oficial",
    restricted_holiday: "permisos restringidas"
  },
  leave_status: {
    APPROVED: "Aprobado",
    PENDING: "Pendiente",
    DENIED: "Rechazada",
    CANCELLED: "Cancelado",
    NEED_EXTRA_DETAIL: "Detalles requeridos",
    not_come: "No era su turno",
    NOT_COME: "No en orden"
  },
  leave_duration: {
    FULL_DAY: "Día completo {0}",
    HALF_DAY: "Medio día {0}",
    QUARTER_DAY: "Cuarto de día {0}",
    HOURLY_DAY: "Cuarto de día {0}",
    HOURLY: "Por hora {0}"
  },
  hr: {
    menu: {
      profile: "Perfil",
      performances: "Rendimiento",
      hiring: "Contratación",
      trainings: "Formación",
      expenses: "Gastos",
      imprests: "Anticipos",
      papers: "Documentos",
      paper: "Papel",
      documents: "Documentos",
      health: "Salud",
      debit_tickets: "Debitos",
      payroll: "Nóminas",
      events: "Eventos",
      approvals: "Solicitudes pendientes de aprobación",
      expense_approval: "Reclamaciones de gastos",
      imprest_approval: "Solicitudes de Anticipo",
      messages: "Mensajes",
      inbox: "Bandeja de entrada",
      outbox: "Bandeja de salida",
      suggestions: "Opiniones y Sugerencias",
      notes: "Notas",
      travels: "Viajar",
      travel_requests: "Solicitudes de viaje",
      vehicles: "Herramientas",
      menus: "Menú de comida",
      contact: "Comunicación",
      expense: "Gastos",
      expense_request_history: "Historial de solicitudes de gastos",
      imprest: "Avance",
      imprest_request_history: "Historial de solicitudes de anticipos",
      travel: "Viajes",
      travel_request_history: "Historial de solicitudes de viaje",
      debit_ticket_transfer_requests: "Historial de solicitudes de transferencia",
      training: "Educación",
      training_requests: "Solicitudes de formación",
      training_request_history: "Historial de solicitudes de formación",
      survey: "Encuestas",
      pending_surveys: "Mis encuestas pendientes",
      completed_surveys: "Encuestas en las que he participado",
      expired_surveys: "Cuestionarios caducados",
      paper_request_history: "Historial de solicitudes de artículos",
      paper_requests: "Solicitud de papel"
    },
    account: {
      overview: "Vista rápida",
      email: "Correo electrónico",
      profile: "Perfil",
      language_role: "Idioma y función",
      contact: "Comunicación",
      personal: "Personal",
      family: "Familia",
      education: "Educación",
      employment: "Asignación",
      financial: "Financiera",
      social_media: "Social Media",
      body_size: "Medidas corporales",
      mobile_device: "Dispositivos Móviles",
      messages: "Mensajes",
      notes: "Notas",
      special_fields: "Áreas Especiales",
      management: "Gobernanza"
    },
    employee: {
      overview: {
        staff_number: "Nº de registro",
        branch: "Sucursal",
        unit: "Unidad",
        team: "Equipo",
        email: "Correo electrónico",
        job_info: "Descripción del puesto",
        title: "Título",
        leave: {
          manager: "Gestor de permisos",
          managers: "Gestores de permisos",
          approver: "permiso"
        },
        hired_date: "Fecha de contratación",
        status: "Estatus",
        manager: "Admin",
        shift_manager: "Jefe de Turno",
        shift_managers: "Jefes de turno",
        role: "Función"
      },
      information: {
        identity_number: "Identidad No",
        first_name: "Se llama",
        last_name: "Apellidos",
        work_email: "Correo electrónico (Empresa)",
        phone: "Teléfono",
        email_and_sms_send_question: "¿Debe enviarse una notificación por correo electrónico y sms a este empleado?"
      },
      profile: {
        identity_number: "Identidad No",
        first_name: "Se llama",
        last_name: "Apellidos",
        work_email: "Correo electrónico (Empresa)",
        phone: "Teléfono",
        email_and_sms_send_question: "¿Debe enviarse una notificación por correo electrónico y sms a este empleado?",
        service_line: "Línea de servicio"
      },
      language_role: {
        language: "Idioma",
        role: "Función"
      },
      contact: {
        email: "Correo electrónico",
        address: "Dirección",
        secondary_phone: "Segundo Teléfono",
        fixed_phone: "Teléfono fijo",
        work_phone: "Teléfono de trabajo",
        spouse_phone: "Teléfono del cónyuge",
        extension_number: "Código Interno",
        emergency_contact_name: "Nombre Apellidos Emergencia",
        emergency_contact_phone: "Teléfono de Emergencia"
      },
      personal: {
        pasaport_number: "Número de pasaporte",
        birth_date: "Fecha de Nacimiento",
        place_of_birth: "Lugar de nacimiento",
        nationality: "Nacionalidad",
        blood_type: "Tipo de sangre",
        gender: "Sexo",
        disability_level: "Grado de discapacidad",
        ex_convict: "Delitos penales",
        driver: "Conductor",
        military_service: "Servicio militar",
        suspended_until: "suspendido hasta final de año",
        hes_code: "Código HES",
        hes_expired_date: "Código HES Fecha de validez"
      },
      family: {
        marital_status: "Estado civil",
        spouse_name: "Nombre de la esposa",
        child_count: "Número de hijos",
        tenant: "Arrendatario",
        martyrs_relatives: "Familiares de los mártires",
        disability_child_count: "Número de hijos",
        date_of_marriage: "Fecha de Matrimonio",
        father_name: "Nombre del Padre",
        mother_name: "Nombre de la madre",
        mothers_maiden_name: "Apellido de soltera de la madre",
        sibling_count: "Número de hermanos",
        child_info: "Conocimiento del niño",
        add_child_info: "Añadir información del niño"
      },
      education: {
        graduation: "Graduación",
        school: "Escuela",
        faculty: "Facultad",
        department: "Sección",
        graduation_grade: "Título de Graduación",
        academic_title: "Título Académico",
        languages: "Idiomas",
        licence_file: "Expediente de Licencia"
      },
      employment: {
        hired_date: "Fecha de contratación",
        manager: "Admin",
        staff_number: "Nº Registro",
        branch: "Sucursal",
        unit: "Unidad",
        team: "Equipo",
        job_info: "Misión",
        agency: "Agente",
        ssn: "Nº SSI",
        passive: "Pasivo",
        started_date: "Fecha de inicio",
        employment_type: "Modo de operación",
        collar_type: "Tipo de Collar",
        quite_job: "Separación del Trabajo",
        quit_job_reason: "Motivo de la dimisión",
        quit_job_reason_description: "Descripción",
        quit_job_date: "Fecha del Despido",
        have_an_interview_made: "Entrevista realizada",
        have_an_interview_made_not: "No se realizó ninguna entrevista",
        integration_value: "Número de integración",
        research_and_development_person: "Trabajador de I+D",
        social_security_type: "Tipo de Seguridad Social",
        company: "Empresa",
        allowance_date: "Fecha de inicio del pago"
      },
      financial: {
        iban: "IBAN",
        ticket_number: "Nº de Tarjeta de Comida",
        bank_account_number: "Número de cuenta bancaria",
        bank_branch: "Sucursal de la cuenta",
        salary: "Salario",
        ticket_price: "Saldo de la tarjeta de comida",
        previous_salary: "Salario anterior",
        previous_ticket_price: "Saldo anterior de la tarjeta de comida",
        yearly_premium: "Prima anual",
        monthly_stoppage: "Retención mensual",
        health_insurance_person_count: "Número de asegurados en la familia",
        agi: "Subsidio Mínimo Vital (AGI)",
        gross_salary: "Salario bruto",
        pay_to_net: "Salario neto",
        pay_to_gross: "Salario bruto",
        salary_type: "Tipo de pago del salario"
      },
      social_media: {
        facebook: "Facebook",
        twitter: "Twitter",
        instagram: "İnstagram",
        linkedin: "Linkedin",
        skype: "Skype"
      },
      body_size: {
        weight: "Peso",
        height: "Chico",
        shirt_size: "Talla de camisa",
        pants_size: "Talla de pantalón",
        shoe_size: "Número de zapato",
        waistcoat: "Chaleco",
        coat: "Mont"
      },
      management: {
        manager: "Director General",
        leave_managers: "Jefes de licencia",
        shift_managers: "Jefes de turno",
        overtime_managers: "Gestores de horas extraordinarias",
        expense_managers: "Gestores de gastos"
      },
      mobile_device: {
        serial_number: "Número de serie",
        multiple_device_allowed: "Entrada de múltiples dispositivos"
      }
    },
    performance: {
      results: {
        evaluatee: "Empleado a evaluar",
        published_evaluators: "Evaluadores",
        unpublished_evaluators: "No evaluadores",
        kpi_score: "Puntuación objetivo",
        competence_score: "Puntuación de competencia",
        totalCount: "Total Participantes",
        publishedCount: "Participante activo",
        evaluation_name: "Nombre de la evaluación",
        total_score: "Puntos totales",
        status: "Estado de la evaluación",
        period: "Período",
        scoring: "Puntuación",
        beginDate: "Fecha de inicio",
        endDate: "Fecha de finalización"
      },
      table: {
        name: "Nombre de la evaluación",
        type: "Tipo de evaluación",
        score_range: "Rango de puntuación",
        evaluatee: "Empleado a evaluar",
        expiration_period: "Periodo",
        validation_period: "Duración",
        status: "Estado de la evaluación",
        categories: "Categorías"
      },
      depths: {
        depth_1: "Nivel 1",
        depth_2: "Nivel 2",
        depth_3: "Nivel 3",
        depth_4: "Nivel 4",
        depth_5: "Nivel 5"
      },
      add: {
        kpi: "Añadir objetivo",
        competence: "Añadir Competencia",
        pool: "Quiero añadir de la reserva",
        custom: "Quiero añadir uno nuevo",
        kpi_group: "Añadir Grupo Objetivo",
        competence_group: "Añadir grupo de competencia"
      },
      detail: {
        kpi_group: "Detalle del grupo objetivo",
        competence_group: "Detalle del grupo de competencias"
      },
      crud: {
        detail: {
          template: "Detalle Plantilla",
          evaluation: "Plan de evaluación Detalle"
        }
      },
      type: {
        KPI: "Objetivo",
        COMPETENCE: "Competencia"
      },
      validation: {
        add_item: "Debe añadir preguntas a la categoría {0}",
        template_exist_category: "Categoría añadida a la categoría {0}",
        template_must_a_category: "La plantilla de rendimiento debe tener al menos una categoría",
        question_total_weight_required: "La suma de las ponderaciones de las preguntas debe ser {0}%",
        question_total_weight: "La suma de las ponderaciones de las preguntas es {0}%",
        category_not_exist: "No existe tal categoría",
        category_total_weight_equals_in_questions:
          "Como el peso total de la categoría {0} es {1}%, el peso total de las preguntas pertenecientes a esta categoría debe ser {1}%",
        category_in_question_required: "Debe haber al menos una pregunta en la categoría {0}",
        question_weight_required: "Introduzca la ponderacion {0}"
      },
      information:
        '<p class="body-1 mt-5">' +
        "KPI & COMPETENCE kategorilerini seçerek kpi ve competence listenizi oluşturabilirsiniz.\n" +
        "</p>" +
        '<p class="body-1">' +
        "Seçtiğiniz kategoriye tanımlanmış olan kpi ve competence sorularına ağırlıklarını girerek soru bazlı puanlama yapabilirsiniz.\n" +
        "</p>" +
        '<p class="body-1">' +
        "Eklediğiniz listedeki soruların toplamı KPI'a veya COMPETENCE'e belirlediğiniz ağırlığa eşit olmalı.\n" +
        "</p>",
      total_evaluatees_count:
        "El número total de participantes en la evaluación <span class='headline font-weight-bold'>{0}</span>",
      waiting_evaluates_finish: "{0} se espera para completar la evaluación",
      complete_evaluation: "Esta evaluación se ha completado",
      expiration_date: "Fecha de finalización",
      template_name: "Nombre de la plantilla",
      kpi: "Objetivo",
      kpi_category: "Categoría del objetivo",
      kpis: "Objetivos",
      competence: "Competencia",
      competence_category: "Categoría de competencia",
      competences: "Competencias",
      result_view: "Mostrar resultados",
      waiting: "Esperados",
      hourly: "Por hora",
      weekly: "Semanal",
      monthly: "Mensual",
      yearly: "Anuario",
      score: "Puntuación",
      star: "Estrella",
      weight: "Ponderacion",
      point: "Puntuación",
      person: "Persona",
      persons: "Contactos",
      period_type: "Periodo",
      score_type: "Tipo de puntuación",
      score_min_count: "Puntuación más baja",
      score_max_count: "Puntuación más alta",
      evaluation: "Evaluación",
      evaluatees: "Serán evaluados",
      evaluators: "Se evaluarán",
      self: "A sí mismo",
      superior: "Por encima",
      subordinates: "Subordinados",
      same: "Iguala",
      flexible: "Elección flexible",
      period: "Período de Evaluación",
      expiration_dates: "Intervalo de Período",
      expiration_begin_date: "Inicio del Período a Evaluar",
      expiration_end_date: "Fin del Período a Evaluar",
      level: "Nivel",
      depth: "Profundidad",
      validation_date: "Intervalo de Evaluación",
      validation_begin_date: "Fecha de Inicio de la Evaluación",
      validation_end_date: "Fecha de finalización de la evaluación",
      published_date_time: "Fecha de publicación",
      degree_360: "360 °",
      m2m: "M2M",
      published: "Iniciar Evaluación",
      published_start: "Evaluar",
      evaluation_done: "Evaluar",
      are_you_sure: "¿Está seguro?",
      are_you_text: "¿Desea iniciar esta evaluación?",
      performance_item: {
        title: "Rendimiento"
      },
      my_evaluatees: "Qué voy a evaluar",
      my_evaluated: "Lo que he evaluado",
      off_message_title: "En desarrollo",
      off_message_content: "El módulo de rendimiento está fuera de uso debido a procesos de optimización",
      info_dialog_title: "Información",
      info_dialog_message:
        "Su evaluación ha sido procesada, su evaluación se reflejará en el sistema cuando finalice el proceso."
    },
    training: {
      plan: "Plan de formación",
      name: "Nombre de la Formación",
      type: "Tipo de Educación",
      organizer: "Planificador",
      lecturer: "Formador",
      terminal: "Terminal",
      sessions: "Sesiones",
      session: "Sesiones",
      training_type: {
        ONLINE: "En línea",
        CLASSROOM: "Aula",
        ON_SITE: "Campo"
      },
      training_detail: "Educación Detalle",
      location: "Localización",
      description: "Descripción",
      training: "Educación",
      request_training: "Solicitar Formación",
      training_requests: "Solicitud de formación",
      request_date: "Fecha de la solicitud",
      abolisher: "Empleado que cancela",
      approval_history: "Historial de confirmación",
      cancelled_date: "Fecha de cancelación",
      hr_manager: "Director de Recursos Humanos",
      approval_date: "Fecha de aprobación",
      cancel_request: "Cancelar",
      cancellation_note: "¿Razón de la cancelación?",
      request_cancellation_process: "Cancelación de la solicitud de formación",
      request_approval_process: "Proceso de aprobación de la solicitud de formación",
      denied: "Rechazar",
      approved: "Confirmar",
      training_planned: "¿Está prevista la formación?"
    },
    expense: {
      name: "Nombre del gasto",
      type: "Tipo de gasto",
      amount: "Importe",
      date: "Historial",
      status: "Estado",
      attachment: "Archivo",
      create_expense: "Solicitud de gasto",
      travel: "Viajar",
      approved_amount: "Importe aprobado",
      expense_type: {
        expense_type: "Tipo de gasto"
      },
      payment_type: {
        CASH: "Efectivo",
        CREDIT_CARD: "Tarjeta de crédito",
        PREPAID_CARD: "Tarjeta de crédito de la empresa",
        OTHER: "Otros",
        null: "Sin especificar"
      }
    },
    imprest: {
      amount: "Importe",
      period: "Historial",
      reason: "Motivo",
      status: "Estado",
      create_imprest: "Solicitud de anticipo",
      travel: "Viajar",
      approved_amount: "Importe aprobado",
      date: "Historial"
    },
    paper: {
      name: "Nombre del documento",
      type: "Tipo de documento",
      issue_date: "Fecha de expedición",
      expire_date: "Fecha de finalización",
      signature_status: "Estado de la firma",
      signature_types: {
        NOT_REQUIRED: "No obligatorio",
        STANDARD_SIGNATURE: "Firma estándar",
        BIOMETRIC_SIGNATURE: "Firma biométrica",
        SIGNED: "Firma",
        null: "-",
        E_SIGNATURE: "Firma digital"
      },
      add_paper: "Añadir documento",
      papers: {
        issue_date: "Fecha de expedición",
        expire_date: "Fecha de finalización"
      }
    },
    document: {
      name: "Nombre del fichero",
      type: "Tipo de archivo",
      category: "Categoría de Documento",
      sender: "De",
      date: "Fecha de envío",
      add_document: "Añadir documento",
      mobile_accessible_disable: "No se puede ver en el móvil",
      mobile_accessible_enable: "Puede ver en móvil"
    },
    health: {
      name: "Nombre de la enfermedad",
      category: "Categoría de la enfermedad",
      from: "Fecha de inicio",
      to: "Fecha de finalización",
      disease_detail: "Detalle de la enfermedad",
      importance: "Importancia",
      importance_enum: {
        LOW: "Bajo",
        MEDIUM: "Centro",
        HIGH: "Alta"
      },
      medicines: "Medicamentos",
      attachment: "Ficha adicional",
      description: "Descripción"
    },
    debit_tickets: {
      asset: "Presencia",
      borrow_date: "Fecha de Préstamo",
      due_date: "Fecha Fin",
      give_back_date: "Fecha de Devolución",
      quantity: "Piezas",
      transfer: "Transferencia",
      transfer_approval: "Confirmación de transferencia",
      approver: "Empleado a transferir",
      debit_transfer: "Transferencia de debitos",
      download_form: "Descargar formulario",
      DEBIT_TRANSFER_DUPLICATE_EXCEPTION: "Existe una solicitud de transferencia creada anteriormente para este debito",
      DATA_INTEGRITY_VIOLATION_EXCEPTION: "Este debito ya le ha sido asignado."
    },
    payroll: {
      month: "Mes",
      created_date: "Fecha de creación",
      file: "Archivo"
    },
    events: {
      name: "Nombre del Evento",
      type: "Tipo de Evento",
      owner: "Constructor",
      online: "En línea",
      begin_date: "Fecha de inicio",
      end_date: "Fecha de finalización",
      activity: "Evento"
    },
    expense_approval: {
      employee: "Empleado",
      description: "Descripción",
      amount: "Importe",
      status: "Estado",
      request_date: "Fecha de solicitud",
      document: "Expediente",
      expense_approval: "Aprobación de gasto",
      message: "{0} solicitado <b>{3}</b> gasto de tipo <b>{2}</b> en <b>{1}</b>, ¿desea aprobarlo?"
    },
    imprest_approval: {
      employee: "Empleado",
      amount: "Importe",
      status: "Estado",
      request_date: "Fecha de la solicitud",
      imprest_approval: "Aprobación Anticipada",
      message: "{0} solicitó <b>{2}</b> anticipo el <b>{1}</b>, desea aprobarlo",
      approved_amount: "Importe aprobado",
      requested_amount: "Importe solicitado"
    },
    hiring: {
      job_posting: "Anuncio",
      applicant: "Solicitante",
      applicants: "Solicitantes",
      interview: "Entrevista",
      assessment_date: "Fecha de la entrevista",
      working_date: "Fecha de inicio",
      last_job: "Su último trabajo",
      last_position_level: "Último puesto en el que trabajó",
      evaluator: "Evaluador",
      evaluators: "Evaluadores",
      weight: "Ponderacion",
      link: "Enlace",
      skill: "Habilidades",
      rate: "Puntuación",
      rating: "Puntuación",
      table: {
        applicant: "Candidato",
        job_posting: "Anuncio",
        last_job: "Último empleo",
        assessment: "Evaluación General",
        rating: "Resultado de la Evaluación",
        assessment_date: "Fecha de Evaluación",
        status: "Estado de finalización",
        evaluators: "Evaluadores",
        evaluator: "Evaluado por"
      },
      skill_type: {
        soft: "Suave",
        technical: "Técnica",
        question: "Pregunta",
        link: "Enlace"
      }
    },
    message: {
      message_type: "Tipo de mensaje",
      sender: "De",
      receivers: "Compradores",
      importance: "Importancia",
      sms: "Sms",
      email: "E-mail",
      in_app: "Declaración",
      title: "Título",
      body: "Contenido del Mensaje,",
      whom: "A",
      date: "Fecha de envío",
      message_type_enum: {
        EMAIL: "Email",
        SMS: "Sms",
        IN_APP: "Notificación"
      },
      message_importance_enum: {
        LOW: "Baja",
        MEDIUM: "Centro",
        HIGH: "Alto"
      },
      send_message: "Enviar Mensaje",
      success_message: "{0} enviado {1} a {0} persona"
    },
    suggestion: {
      category: "Opinión & Sugerencia Categoría",
      title: "Opiniones & Sugerencias",
      add_suggestion: "Añadir Comentarios & Sugerencias",
      body: "Mensaje",
      owner: "Creador"
    },
    note: {
      notes: "Notas",
      title: "Título",
      body: "Contenido",
      attachment: "Archivo adicional",
      description: "Descripción",
      add_note: "Añadir nota",
      owner: "Empleado",
      importance: "Importancia"
    },
    travel: {
      request_travel: "Solicitar Viaje",
      leave_request: "Solicitud de permiso",
      travels: "Viajar",
      travel: "Viajar",
      travel_detail: "Detalles del viaje",
      start_work_date: "Fecha de inicio",
      is_domestic: "¿Viajar dentro del país?",
      country: "País de destino",
      city: "Ciudad de destino",
      region: "Destino",
      is_rent_car: "¿Tiene coche de alquiler?",
      is_shuttle: "¿Hay traslado al aeropuerto?",
      description: "Descripción",
      reason: "Motivo",
      status: "Estado",
      expense_message: "<strong>{0}</strong>, <strong>{1}</strong> solicitó <strong>{2}</strong> en gastos",
      imprest_message: "<strong>{0}</strong> solicitó un anticipo de <strong>{2}</strong> el <strong>{1}</strong>",
      subsistence_message:
        "Al empleado <strong>{0}</strong> se le concedió un anticipo de <strong>{2}</strong> el <strong>{1}</strong>",
      travel_dates: "Fechas del viaje",
      pending_travel_approvals: "Aprobaciones de viaje pendientes",
      travel_process: "Proceso de solicitud de viaje",
      travel_details: "Detalles del viaje",
      no_detail_item: "No se ha añadido ningún detalle",
      add_new_detail: "Añadir nuevo detalle",
      detail_type: "Tipo de detalle",
      detail_subtype: "Subtipo de detalle",
      travel_detail_type_enum: {
        TRANSFER: "Transferir",
        LAYOVER: "Alojamiento",
        BUSINESS: "Negocio",
        TRAVEL_INSURANCE: "Seguro de Viaje"
      },
      travel_detail_subtype_enum: {
        BUS: "Autobús",
        PLANE: "Avión",
        TRAIN: "Tren",
        SHIP: "Barco",
        CAR: "Automóvil",
        HOTEL: "Hotel",
        HOSTEL: "Hostal",
        VILLA: "Chalet",
        FACTORY: "Fábrica",
        OFFICE: "Oficina",
        TRAVEL_HEALTH_POLICY: "Política sanitaria de viaje"
      },
      begin_date: "Fecha de inicio",
      end_date: "Fecha de finalización",
      expenses: "Gastos",
      imprests: "Anticipos",
      debits: "Debitos",
      borrow_date: "Historial de préstamos",
      due_date: "Fecha de finalización",
      give_back_date: "Fecha de devolución",
      quantity: "Piezas",
      name: "Nombre",
      category: "Categoría",
      model: "Modelo",
      subsistences: "Descuentos",
      project: "Proyecto",
      attrition: "Cuota de amortización",
      new_leave_request: "Crear un nuevo permiso",
      adding_expense: "Añadir Gasto",
      adding_imprest: "Adición de Anticipo",
      adding_subsistence: "Adición de viatico",
      adding_debit: "Adición de debito",
      assignment_form: "Formulario de asignación"
    },
    travel_request: {
      employee: "Empleado",
      begin_date: "Fecha de inicio",
      end_date: "Fecha de finalización",
      start_work_date: "Fecha de inicio",
      status: "Estado de la encuesta",
      request_date: "Fecha de solicitud",
      travel_approval: "Confirmación de viaje",
      message: "<b>{0}</b> ha solicitado viajar de <b>{1}</b> a <b>{2}</b>, ¿desea confirmar?"
    },
    vehicle: {
      plate_number: "Número de matrícula",
      brand: "Marca",
      model: "Modelo",
      year: "Año",
      branch: "Sucursal",
      employee: "Empleado"
    },
    contact: {
      employee: "Empleado",
      email: "Correo electrónico",
      phone: "Número de teléfono"
    },
    projects: {
      project: "Proyecto",
      employee_count_message: "Sin empleados | 1 empleado | {count} empleados"
    },
    debit_ticket_transfer_request: {
      requester: "Solicitante",
      asset: "Presencia",
      approval_message:
        "<strong>{0}</strong> ha solicitado transferir el siguiente activo a su posesión, ¿desea aprobarlo?"
    },
    survey: {
      name: "Nombre de la encuesta",
      published_date: "Fecha de Publicación",
      end_date: "Fecha de finalización",
      start_survey: "Inicio Encuesta",
      survey_privacy: "Confidencialidad de la encuesta",
      pending_title: "Encuestas que debe completar",
      completed_title: "Encuestas en las que participé",
      expired_title: "Encuestas caducadas",
      complete_survey: "Encuesta completa",
      survey_information: "Información de la encuesta",
      exit: "Salir",
      save_and_finish: "Guardar y finalizar",
      status_enum: {
        completed: "Completado",
        continuous: "En curso",
        expired: "Expirado"
      },
      section_count: "Número de Secciones",
      question_count: "Número de preguntas",
      estimated_duration: "Duración estimada",
      description: "Descripción",
      anonymous_on: "Votación secreta",
      anonymous_off: "Votación abierta",
      enter_answer: "Puede introducir una respuesta",
      your_answer: "responder",
      please_select: "Seleccionar"
    },
    employee_requesting: "Solicitante",
    employee_creating: "Autor de la solicitud"
  },
  feedback: {
    reasons: "Motivos",
    reason: "Razones",
    feeling: "Expresión facial",
    feeling_enum: {
      BAD: "Muy malo",
      SAD: "Malo",
      NORMAL: "Normal",
      HAPPY: "Feliz",
      ECSTATIC: "Muy feliz"
    },
    title: "Comentarios {0} / {1}",
    comment: "Descripción"
  },
  menu: {
    dashboard: "Página de inicio",
    time: "Tiempo",
    hr: "Recursos Humanos",
    leave: "Permisos",
    clock_in_out: "Entrada / Salida"
  },
  message: {
    saved_successfully: "Registro correcto",
    registration_failed: "Registro fallido",
    updated_successfully: "Actualización correcta",
    deleted_successfully: "Eliminación correcta",
    download_email_report: "Su informe está listo, haga clic en el botón de descarga para descargarlo",
    clock_ip_error: "La dirección ip no se encuentra entre las direcciones ip permitidas",
    evaluation_date_range_error_message: "No se encuentra en el periodo de evaluación activo",
    leave_calculate_error: "Error de cálculo",
    required_document_message: "Hay un documento que debe cargar",
    notice_period_error: "Este permiso debe solicitarse con al menos {0} días de antelación",
    max_consecutive_error: "Puede solicitar una excedencia por un máximo de {0} días cada vez",
    min_consecutive_error: "Puede solicitar un permiso de un máximo de {0} días cada vez",
    leave_request_exist_error: "Hay un permiso solicitado en estas fechas",
    total_leave_balance_error1: "No dispone de fondos suficientes para este permiso",
    total_leave_balance_error2:
      "Puede utilizar un máximo de {0} días de saldo anticipado para este permiso, No puede solicitar el permiso porque su saldo anticipado no es suficiente",
    network_error: "Error de conexión a Internet",
    no_record_found: "No se ha encontrado ningún registro",
    max_range_error: "¡Puede elegir hasta {0} días!"
  },
  global: {
    comment: "Comentario",
    description: "Descripción",
    day: "Día",
    note: "Nota",
    employee: "Empleado",
    address: "Dirección",
    explanation: "Descripción",
    hour: "Hora",
    month: "Mes",
    phone: "Teléfono",
    status: "Estado",
    file: "Archivo",
    greeting: "Hola, {0}",
    search_for_employee: "Escribe algo para buscar empleados..",
    others: "otros",
    importance_enum: {
      LOW: "Bajo",
      MEDIUM: "Centro",
      HIGH: "Alto"
    },
    importance: "Calificación de importancia",
    date: "Historia",
    loading_message: "Cargando..",
    birth_date: "Cumpleaños",
    min_character_message: "Debe introducir al menos {0} caracteres",
    no_employee_message: "No hay empleados con los criterios que busca",
    employees: "Personal",
    beginDate: "Fecha de inicio",
    endDate: "Fecha de finalización",
    beginTime: "Hora de inicio",
    endTime: "Hora de finalización",
    year: "Año",
    message: "Mensaje",
    detail: "Detalle",
    country: "País",
    active_employees: "Trabajadores activos",
    passive_employees: "Bajas Laborales",
    maxEmployeeCount: "Número de Personal Norm",
    years_old: "Edad",
    print: "Imprimir",
    expense_management: "Gestión de Gastos",
    paymentType: "Tipo de Pago",
    employee_selected: "Seleccionar empleado...|1 empleado seleccionado|{contar} empleados seleccionados",
    shift_selected: "Seleccionar turno...|1 turno seleccionado|{contar} turno seleccionado",
    shifts_type: "{0} Turnos",
    total_hours: "Total Horas",
    name: "Nombre",
    reason: "Motivo",
    organization: "Organización",
    minute: "Minutos",
    from: "Inicio",
    to: "Fin",
    shift: "Turno",
    uploaded_files: "Archivos subidos",
    manager: "Gerente",
    selected: "{0} seleccionados"
  },
  assessment: {
    very_poor: "Muy Mal",
    poor: "Malo",
    normal: "Centro",
    good: "Bien",
    very_good: "Muy Bueno",
    na: "Sin Evaluación"
  },
  buttons: {
    home: "Página de inicio",
    ok: "OK.",
    delete: "Cancelar",
    back: "Atrás",
    save: "Guardar",
    update: "Actualizar",
    edit: "Editar",
    cancel: "Anular",
    close: "Salir",
    dont_save: "Salir sin guardar",
    dont_save_continue: "Continuar sin guardar",
    save_changes: "Guardar cambios",
    evaluation_finish: "Completar evaluación",
    assign: "Cita",
    add: "{0} Añadir",
    add_and_new: "Añadir y Nuevo",
    refresh: "Actualizar",
    filter: "Filtrar",
    export: "Exportar",
    create: "{0} Crear",
    create_and_new: "Crear y Nuevo",
    download: "Descargar",
    yes: "Sí",
    no: "No, No, No",
    clear: "Limpiar",
    filter_results: "Filtrar resultados",
    delete_document: "Eliminar documento",
    scan: "Escanear",
    send_message: "Enviar Mensaje",
    send: "Enviar",
    apply: "Implementar",
    info: "Información",
    prev: "Anterior {0}",
    next: "Siguiente {0}",
    list_table: "Modo Tabla",
    list_card: "Modo Tarjeta",
    continue: "Continuar",
    show_all: "Mostrar todo",
    cancel_leave: "Cancelar permiso",
    select_all: "Seleccionar todo",
    upload: "Cargar",
    file_upload: "Cargar archivo",
    file_download: "Operaciones de Archivo",
    import: "Transferir",
    upload_template: "Cargar Plantilla",
    download_template: "Descargar Plantilla",
    trigger: "Activar",
    action: "Procesar",
    send_registration_credentials: "Enviar Credenciales",
    on: "Abrir",
    off: "Cerrar",
    uploads: {
      employee: "Obtener el personal dentro",
      leave: "Importar permisos"
    },
    multiple_delete_shift: "Borrado de turnos por lotes",
    multiple_upload_shift: "Carga de turnos por lotes",
    multiple_actions: "Transacciones por Lotes",
    send_email_and_sms_notification: "Enviar notificaciones por E-mail y SMS",
    add_expense: "Solicitar Gastos",
    add_imprest: "Solicitar Anticipo",
    add_overtime: "Solicitar horas extras",
    add_leave_request: "Solicitar permiso",
    evaluatee: "Evaluar",
    add_skill: "Añadir pregunta",
    sign: "Firme aquí",
    skip: "Omitir",
    back_to_home: "Volver a la página de inicio",
    please_select: "Por favor, seleccione...",
    preview: "Avance"
  },
  response: {
    success: {
      ok: "Operación realizada con éxito",
      update: "Actualización exitosa",
      delete: "Borrado correcto",
      send_sms_password_code: "Código de renovación de contraseña enviado como sms"
    },
    error: {
      user_not_found: "El nombre de usuario o la contraseña son incorrectos",
      network_error: "Error de conexión a Internet"
    }
  },
  request_status: {
    pending: "En espera de aprobación",
    PENDING: "En espera de aprobación",
    approved: "Aprobado",
    APPROVED: "Aprobado",
    denied: "Rechazado",
    DENIED: "Rechazado",
    cancelled: "Anulado",
    CANCELLED: "Anulado",
    need_extra_detail: "Se solicita información adicional",
    NEED_EXTRA_DETAIL: "Detalles adicionales solicitados",
    undefined: "Desconocido",
    revision: "Revisión",
    REVISION: "Revisión"
  },
  shift_types: {
    FIX: "Fijo",
    FLEXIBLE: "Flexible",
    OFF_DAY: "Dias festivos de la semana",
    OFFDAY: "Dias festivos de la semana",
    HOLIDAY: "Día festivo",
    WORKDAY: "Día laborable",
    BREAK: "Día de descanso",
    OVERTIME: "Horas extraordinarias",
    OVERTIME_SHIFT: "Turno de horas extraordinarias",
    ON_LEAVE: "Permiso"
  },
  shift: {
    overtime: "Horas Extras",
    missing_time: "Turno Incompleto",
    shift_parameters: "Parámetro de Turno",
    break: "Pausa",
    sub_events: "Registros Interinos",
    included_calculation: "Se incluye en el cálculo",
    not_included_calculation: "No se incluye en el cálculo",
    included_overtime_calculation: "Las horas extraordinarias no se incluyen en el cálculo",
    not_included_overtime_calculation: "Las horas extraordinarias están incluidas en el cálculo",
    included_missing_time_calculation: "Las horas extraordinarias no se incluyen en el cálculo",
    not_included_missing_time_calculation: "Las horas extraordinarias se incluyen en el cálculo",
    work_day: "Día laborable",
    overtime_day: "Jornada Extra",
    full_time: "Tiempo Completo",
    absent: "Ausencia",
    unpaid_leave: "Permiso no retribuido",
    paid_leave: "Permiso retribuido",
    public_holiday: "Día festivo",
    on_leave: "Permiso",
    off_day: "Semana Festiva",
    break_day: "Día de descanso",
    incalculable: "Horas extraordinarias no contabilizadas"
  },
  sensors: {
    NFC: "Nfc",
    FACE: "Reconocimiento facial",
    RFID: "Rfid",
    FINGER: "Huella dactilar",
    BLUETOOTH: "Bluetooth",
    GATEWARE: "Gateware",
    BEACON: "Baliza",
    GPS: "Gps",
    WIFI: "Wifi",
    ID: "Id",
    QR: "Qr",
    QR_CODE: "Código QR",
    PASSWORD: "Contraseña",
    PIN: "Pin",
    CARD: "Tarjeta",
    FIXED: "Fijo",
    EXTERNAL: "Externo",
    JAN: "JAN",
    WEB: "Web"
  },
  identity_type: {
    FACE: "Cara",
    PASSWORD: "Contraseña",
    PIN: "Pin",
    FINGER: "Huella dactilar",
    QR_CODE: "Código QR",
    CARD: "Tarjeta",
    EXTERNAL: "Externo"
  },
  event_types: {
    BREAK_IN: "Entrada de rotura",
    BREAK_OUT: "Salida de descanso",
    LUNCH_IN: "Entrada de alimentos",
    LUNCH_OUT: "Salida de alimentos",
    DUTY_IN: "Entrada de Misión",
    DUTY_OUT: "Salida de Tarea",
    CLOCK_IN: "Entrada de Trabajo",
    CLOCK_OUT: "Salida del trabajo",
    UNKNOWN: "Desconocido",
    undefined: "Desconocido"
  },
  currency: {
    amount: "Importe",
    requested_amount: "Importe solicitado",
    approved_amount: "Importe aprobado",
    code: "Unidad",
    codes: {
      TRY: "TL",
      USD: "DÓLAR",
      EUR: "EURO"
    }
  },
  titles: {
    expense: {
      create: "Añadir gasto",
      detail: "Detalle de gastos"
    },
    imprest: {
      create: "Añadir anticipo",
      detail: "Detalle Anticipo"
    }
  },
  language: {
    tr: "Turco",
    en: "inglés",
    de: "alemán",
    TURKISH: "turco",
    ENGLISH: "inglés",
    GERMAN: "alemán"
  },
  status: {
    active: "Activo",
    passive: "Pasivo",
    enable: "Activo",
    disable: "Discapacitado"
  },
  gender: {
    male: "Hombre",
    female: "Mujer",
    other: "Otros",
    others: "No especificado",
    MALE: "Hombre",
    FEMALE: "Mujer",
    OTHER: "No especificado"
  },
  marital_status: {
    married: "Casado",
    single: "Soltero",
    divorced: "Divorciado"
  },
  education: {
    student: "Estudiante",
    primary_school: "Escuela primaria",
    secondary_school: "Escuela Secundaria",
    high_school: "Instituto",
    collage: "Grado Asociado",
    university: "Licenciatura",
    master: "Máster",
    phd: "Doctorado"
  },
  education_languages: {
    turkish: "Turco",
    english: "inglés",
    german: "alemán",
    french: "francés",
    dutch: "neerlandés / holandés",
    spanish: "español",
    italian: "italiano",
    arabic: "Árabe",
    russian: "ruso",
    persian: "persa",
    chinese: "chino",
    japanese: "japonés",
    hindi: "hindi",
    latin: "latín",
    portuguese: "portugués",
    korean: "coreano"
  },
  military_service: {
    done: "Completado",
    undone: "No realizada",
    exempt: "Exento",
    suspended: "Suspendido"
  },
  academic_title: {
    lecturer: "Formador",
    research_assistant: "Asistente de investigación",
    assistant_professor: "Profesor Asistente",
    associate_professor: "Profesor asociado",
    professor: "Catedrático"
  },
  employment_type: {
    full_time: "A tiempo completo",
    part_time: "A tiempo parcial",
    casual: "Todos los días",
    contracted: "Contratado",
    freelance: "Libre",
    intern: "En prácticas",
    mobile: "Móvil",
    retired: "Jubilado",
    trial: "Período de prueba",
    seasonal: "Estacional",
    disabled: "Discapacitado"
  },
  collar_type: {
    white: "Blanco",
    blue: "Azul",
    gray: "Gris"
  },
  quite_job_reasons: {
    resignation: "Renuncia",
    cancellation_of_labor_contract: "Anulación de contrato",
    military_service: "Servicio militar",
    other: "Otros"
  },
  blood_type: {
    o_rh_negative: "0-",
    o_rh_positive: "0+",
    a_rh_negative: "A-",
    a_rh_positive: "A+",
    b_rh_negative: "B-",
    b_rh_positive: "B+",
    ab_rh_negative: "AB-",
    ab_rh_positive: "AB+"
  },
  time_duration: {
    hour: "Hora",
    minute: "Minutos",
    second: "Segundos"
  },
  date: {
    year: "Año",
    month: "Ay",
    day: "Día",
    time: "Ver",
    week: "Semana",
    minute: "Minutos"
  },
  validations: {
    messages: {
      phone: "El campo {_field_} debe ser un número de teléfono válido",
      password_match: "Las contraseñas no coinciden",
      integer: "El campo {_field_} debe ser un número entero"
    }
  },
  nationalities: {
    Afghan: "Afgano",
    Albanian: "albanés",
    Algerian: "argelino",
    American: "Americano",
    Andorran: "andorrano",
    Angolan: "angoleño",
    Antiguans: "Antigua y Barbuda",
    Argentinean: "Argentino",
    Armenian: "Armenio",
    Australian: "australiana",
    Austrian: "austriaco",
    Azerbaijani: "azerbaiyano",
    Bahamian: "bahameño",
    Bahraini: "bahreiní",
    Bangladeshi: "bangladesí",
    Barbadian: "barbadense",
    Barbudans: "Barbuda",
    Botswana: "Botsuana",
    Belarusian: "Bielorruso",
    Belgian: "Belga",
    Belizean: "Beliceño",
    Beninese: "Benin",
    Bhutanese: "Butanlı",
    Bolivian: "boliviano",
    Bosnian: "bosnio",
    Brazilian: "brasileña",
    British: "británico",
    Bruneian: "Brunei Darussalam",
    Bulgarian: "búlgaro",
    Burkinabe: "Burkina Faso",
    Burmese: "Myanmar",
    Burundian: "Burundi",
    Cambodian: "camboyano",
    Cameroonian: "camerunés",
    Canadian: "Canadiense",
    "Cape Verdean": "Nariz Verde",
    "Central African": "Centroafricano",
    Chadian: "chadiano",
    Chilean: "Chileno",
    Chinese: "Chino",
    Colombian: "colombiano",
    Comoran: "Comoros",
    Congolese: "congoleño",
    "Costa Rican": "costarricense",
    Croatian: "croata",
    Cuban: "cubano",
    Cypriot: "chipriota",
    Czech: "checo",
    Danish: "danés",
    Djibouti: "yibutiano",
    Dominican: "Dominicana",
    Dutch: "neerlandés",
    "East Timorese": "timorense oriental",
    Ecuadorean: "ecuatoriano",
    Egyptian: "egipcio",
    Emirian: "Emirano",
    "Equatorial Guinean": "guineano ecuatorial",
    Eritrean: "eritreo",
    Estonian: "estonio",
    Ethiopian: "etíope",
    Fijian: "fiyiano",
    Filipino: "filipino",
    Finnish: "finés",
    French: "francés",
    Gabonese: "Gabonesa",
    Gambian: "gambiano",
    Georgian: "georgiano",
    German: "alemán",
    Ghanaian: "ghanés",
    Greek: "griego",
    Grenadian: "granadino",
    Guatemalan: "guatemalteco",
    "Guinea-Bissauan": "Guinea-Bissaulu",
    Guinean: "guineano",
    Guyanese: "Guyanés",
    Haitian: "haitiano",
    Herzegovinian: "Herzegovina",
    Honduran: "Hondureño",
    Hungarian: "húngaro",
    "I-Kiribati": "kiribatili",
    Icelander: "Islandés",
    Indian: "indio",
    Indonesian: "indonesio",
    Iranian: "iraní",
    Iraqi: "Iraquí",
    Irish: "irlandés",
    Israeli: "israelí",
    Italian: "italiano",
    Ivorian: "Marfil",
    Jamaican: "jamaicano",
    Japanese: "japonesa",
    Jordanian: "jordano",
    Kazakhstani: "jersey",
    Kenyan: "Kenia",
    "Kittian and Nevisian": "San Cristóbal y Nieves",
    Kuwaiti: "Kuwaití",
    Kyrgyz: "Kirguisa",
    Laotian: "laosiano",
    Latvian: "letón",
    Lebanese: "libanés",
    Liberian: "liberiano",
    Libyan: "Libio",
    Liechtensteiner: "Liechtenstein",
    Lithuanian: "lituano",
    Luxembourger: "luxemburgués",
    Macedonian: "macedonio",
    Malagasy: "malgache",
    Malawian: "malawiano",
    Malaysian: "malayo",
    Maldivan: "Maldivas",
    Malian: "maliense",
    Maltese: "maltés",
    Marshallese: "Mariscal",
    Mauritanian: "mauritano",
    Mauritian: "Mauricio",
    Mexican: "mexicano",
    Micronesian: "Micronesia",
    Moldovan: "Moldavo",
    Monacan: "Mónaco",
    Mongolian: "Mongolia",
    Moroccan: "marroquí",
    Mosotho: "Lesotolu",
    Motswana: "Botsuana",
    Mozambican: "Mozambique",
    Namibian: "Namibia",
    Nauruan: "Naurulu",
    Nepalese: "nepalí",
    "New Zealander": "neozelandés",
    Nicaraguan: "nicaragüense",
    Nigerian: "nigeriano",
    Nigerien: "nigeriano",
    "North Korean": "norcoreano",
    "Northern Irish": "irlandés",
    Norwegian: "noruego",
    Omani: "Omaní",
    Pakistani: "paquistaní",
    Palauan: "Palaulu",
    Panamanian: "panameño",
    "Papua New Guinean": "Papúa Nueva Guinea",
    Paraguayan: "paraguaya",
    Peruvian: "peruano",
    Polish: "polaco",
    Portuguese: "portugués",
    Qatari: "Qatar",
    Romanian: "Rumano",
    Russian: "ruso",
    Rwandan: "Ruandalo",
    "Saint Lucian": "Santa Lucía",
    Salvadoran: "salvadoreño",
    Samoan: "samoano",
    "San Marinese": "San Marino",
    "Sao Tomean": "Sao Tomeli",
    Saudi: "Saudí",
    Scottish: "Escocés",
    Senegalese: "senegalés",
    Serbian: "Serbia",
    Seychellois: "Seychelles",
    "Sierra Leonean": "Sierra Leona",
    Singaporean: "singapurense",
    Slovakian: "eslovaco",
    Slovenian: "esloveno",
    "Solomon Islander": "Isleño de Salomón",
    Somali: "Somalí",
    "South African": "Sudafricano",
    "South Korean": "surcoreano",
    Spanish: "español",
    "Sri Lankan": "esrilanqués",
    Sudanese: "sudanés",
    Surinamer: "Surinamés",
    Swazi: "Esvati",
    Swedish: "sueco",
    Swiss: "suizo",
    Syrian: "Siria",
    Taiwanese: "taiwanés",
    Tajik: "tayiko",
    Tanzanian: "tanzano",
    Thai: "tailandés",
    Togolese: "togolu",
    Tongan: "tongano",
    "Trinidadian or Tobagonian": "Trinidad y Tobago",
    Tunisian: "tunecino",
    Turkish: "Turco",
    Tuvaluan: "Lienzo",
    Ugandan: "ugandés",
    Ukrainian: "ucraniano",
    Uruguayan: "uruguayo",
    Uzbekistani: "uzbeko",
    Venezuelan: "venezolano",
    Vietnamese: "vietnamita",
    Welsh: "Gales",
    Yemenite: "yemení",
    Zambian: "zambiano",
    Zimbabwean: "Zimbabue"
  },
  disability_level: {
    first_level: "Nivel 1",
    second_level: "Nivel 2",
    third_level: "Nivel 3"
  },
  social_security_type: {
    SS_4A: "SSK",
    SS_4B: "Bağkur",
    SS_4C: "Emekli Sandığı"
  },
  clock_ip_error: "La dirección ip no se encuentra entre las direcciones ip permitidas",
  days: {
    monday: "Lunes",
    tuesday: "martes",
    wednesday: "miércoles",
    thursday: "jueves",
    friday: "viernes",
    saturday: "sábado",
    sunday: "Mercado"
  },
  api_error: {
    756: "Hay permiso entre las fechas que ha solicitado",
    766: "Su contraseña debe tener al menos 8 y como máximo 16 caracteres",
    767: "Su contraseña debe contener letras minúsculas, letras mayúsculas, números y caracteres especiales: .-/@#$%^&_+=()",
    768: "Su contraseña no puede incluir su nombre, apellido o número de personal",
    769: "Esta contraseña ya se ha utilizado antes, por favor, introduzca una contraseña diferente a la última {0} contraseñas"
  },
  http: {
    200: "Operación realizada con éxito",
    201: "Operación realizada con éxito",
    202: "Registro correcto",
    204: "Operación correcta",
    304: "Error de caché",
    401: "Sesión no autorizada",
    404: "No se ha encontrado dicha solicitud",
    409: "Disponible desde la misma grabación",
    415: "Formato de archivo compatible",
    500: "Error de servidor",
    503: "Servidor no disponible"
  },
  expense_items: "Partidas de gastos",
  expense_item_name: "Nombre del bolígrafo",
  update_expense_item: "Actualizar partida de gastos",
  add_expense_item: "Añadir partida de gastos",
  file_format_error: "Formato de archivo no válido",
  file_size_error: "Tamaño de archivo no válido",
  not_selected_expense_item_error_message: "No ha añadido una partida de gastos",
  expense_description: "Descripción de gastos",
  abolisher_employee: "Cancelar empleado",
  expense_manager: "Gestor de gastos",
  event_date: "Fecha de transacción",
  cancelled_date: "Fecha de cancelación",
  approval_history: "Historial de confirmación",
  request_processes: {
    super_admin: "Aprobado por el superadministrador",
    approved: "Aprobó la solicitud",
    pending: "Se espera que la autorice",
    stopped: "El proceso de aprobación se detuvo porque la solicitud fue rechazada",
    waiting: "Está esperando a que el gestor anterior la apruebe",
    denied: "Solicitud rechazada"
  },
  sso_forgot_password_warning: {
    title: "Advertencia",
    message: "Puede iniciar sesión en Idenfit con su dirección de correo electrónico de Accenture."
  },
  time_abbr: {
    hour: "horas",
    minute: "minutos",
    second: "segundos"
  },
  vedubox_user_not_found: "Usuario de Vedubox no encontrado",
  gdpr: {
    acceptance: {
      title: "Aprobación del Texto de Consentimiento Explícito de protección de datos personales",
      checkbox: "Apruebo el texto de Consentimiento Explícito de protección de datos personales",
      save: "Guardar y continuar",
      formError: "Para continuar, debe aprobar el texto de Consentimiento Explícitode protección de datos personales"
    }
  },
  web_terminal_loading: "El terminal web se está cargando",
  web_terminal_not_found: "Terminal web no encontrada",
  multiple_assigned_shift_warning:
    "Tiene más de un turno asignado. Por favor, seleccione uno de los siguientes turnos.",
  paper_approval: "Aprobación del papel",
  paperApprovalProcessWarningMessage: "Necesita descargar o previsualizar el archivo",
  minLength: "Al menos 8 carácteres",
  hasLowerCaseAndUpperCase: "Contiene dígitos, letras mayúsculas y minúsculas",
  hasSpecialCharacter: "Contiene caracteres especiales",
  passwordExpireNotificationMessage:
    "Quedan 0 días para que tu contraseña caduque | Quedan 1 día para que tu contraseña caduque | Quedan {count} días para que tu contraseña caduque",
  pendingRequests: "Solicitudes pendientes",
  allPendingRequests: "Todas las solicitudes pendientes",
  maxImprestAmountMessage: "Límite máximo que se puede solicitar: {0}",
  or: "o",
  continueWithMicrosoft: "Continuar con Microsoft"
};
