export default {
  auth: {
    email: "E-posta",
    password: "Şifre",
    logout: "Çıkış Yap",
    sign_in: "Giriş Yap",
    sign_in_with_sso: "SSO ile Giriş Yap",
    login_screen: "giriş ekranı",
    back_to_sign_in: "Giriş Ekranına Dön",
    reset_password: "Şifremi Sıfırla",
    reset_password_code: "Sıfırlama Kodu",
    forget_password: "Şifremi Unuttum",
    new_password: "Yeni Şifre ",
    re_new_password: "Yeni Şifre (Tekrar)",
    login_failed: "İdenfit kimliğinizi bulamadı",
    change_password: "Şifremi Değiştir",
    continue: "Devam Et",
    showcase: {
      welcome: "Hoşgeldiniz^3000",
      cloud: "Yeni nesil tanımlama ve iş gücü yönetimi bulut platformu^3000",
      identify: "İş gücünüzü kolay ve güvenli bir şekilde tanımlamanızı, izlemenizi ve yönetmenizi sağlar^4000",
      manage: "İstediğiniz zaman, istediğiniz yerde ve istediğiniz şekilde yönetebilirsiniz^4000",
      freedom: "Tüm sisteminizi görmek için özgürsünüz^3000"
    },
    email_not_found: "Bu email adresi bulunamadı",
    invalid_reset_token: "Geçersiz sıfırlama kodu",
    reset_password_expired: "Yeni bir şifre sıfırlamak için en az 3 dakika beklemelisiniz",
    reset_code_expired: "Şifre sıfırlama kodunun süresi doldu",
    send_sms_password_code: "Şifre yenileme kodu sms olarak gönderildi",
    password_change_successfully: "Şifreniz başarıyla değiştirildi",
    invalid_email_or_password: "E-posta veya şifre hatalı",
    invalid_password: "Şifre hatalı",
    current_password: "Mevcut Şifreniz",
    sso_error: "Bir hata oluştu"
  },
  dashboard: {
    access: {
      table: {
        last_event: "Son Etkinlikler",
        personal: "Çalışan",
        terminal_type: "Terminal Tipi",
        general_info: "Genel Bilgilendirme",
        terminal: "Terminal",
        event_type: "Etkinlik Tipi",
        identity_type: "Kimlik Tipi",
        time: "Zaman",
        event: "Etkinlik",
        location: "Lokasyon",
        place: "Lokasyon",
        place_beacon: "Lokasyon Beacon",
        minor: "Minor",
        major: "Major",
        parent: "Bağlı olduğu lokasyon",
        duration: "Kaldığı Süre",
        first_seen: "Giriş zamanı",
        last_seen: "Çıkış zamanı",
        max_person_count: "Maksimumu Kişi Sayısı",
        begin_date_time: "Başlangıç zamanı",
        end_date_time: "Bitiş zamanı",
        employee_dialog_title: "Çalışan Giriş - Çıkış Bilgileri",
        empty_zone_message: "Bölgede hiç kimse yok",
        loading_message: "Oluşturuluyor"
      },
      monthly_dashboard: "Aylık Dashboard",
      monthly_events: "Aylık Olaylar",
      monthly_timesheet_report: "Aylık Zaman Çizelgesi Raporum",
      absence: "Devamsızlıklarım",
      incalculable: "Hesaplanamayanlarım",
      lates: "Geç Gelmelerim",
      overtime: "Fazla Mesailerim",
      early_leaver: "Erken Çıkmalarım",
      missing_time: "Eksik Mesailerim",
      expected: "Beklenenlerim",
      total_tardiness_average: "Geç Kalma Ortalaması",
      total_leaver_average: "Erken Çıkma Ortalaması",
      total_overtime_average: "Fazla Mesai Ortalaması",
      tardiness_minute: "Geç kalma dakikası",
      early_minute: "Erken çıkma dakikası",
      overtime_minute: "Fazla mesai dakikası",
      short_minute: "dk",
      today: "Bugün"
    },
    currency: {
      buying: "Alış",
      selling: "Satış",
      currencies: "Döviz"
    },
    about: {
      about: "Hakkımızda",
      mission: "Misyon",
      vision: "Vizyon"
    },
    menu_of_day: "Günün Menüsü",
    messages: {
      no_data_reminders: "Hatırlatıcı bildirimi bulunmamaktadır",
      no_data_messages: "Mesaj bildirimi bulunmamaktadır",
      no_data_leaves: "İzin talebi bulunmamaktadır",
      no_data_overtimes: "Fazla mesai talebi bulunmamaktadır",
      no_data_expenses: "Harcama talebi bulunmamaktadır",
      no_data_imprests: "Avans talebi bulunmamaktadır",
      no_data_incalculables: "Hesaplanamayan çalışan bulunmuyor",
      no_data_absences: "Devamsız çalışan bulunmuyor"
    },
    request_type: {
      leave: "İzin Talepleri",
      overtime: "Fazla Mesai Talepleri",
      expense: "Harcama Talepleri",
      imprest: "Avans Talepleri"
    },
    weather_forecast: "Hava Durumu & Güncel Kurlar",
    reminder_tab_title: "Hatırlatıcılar",
    message_tab_title: "Mesajlar",
    data_type_enum: {
      LEAVE_REQUEST: "İzin Talepleri",
      ACTIVITY: "Etkinlikler",
      REMINDER: "Hatırlatıcılar"
    }
  },
  time: {
    crud: {
      create_overtime: "Fazla Mesai Talebi Oluştur"
    },
    event: {
      clock_in: "Giriş",
      clock_out: "Çıkış",
      duty_in: "Görev Giriş",
      duty_out: "Görev Çıkış",
      break_in: "Mola Giriş",
      break_out: "Mola Çıkış",
      lunch_in: "Yemek Giriş",
      lunch_out: "Yemek Çıkış"
    },
    menu: {
      shifts: "Vardiyalar",
      timesheets: "Zaman Çizelgesi",
      overtime_requests: "Fazla Mesailer",
      overtime_approval: "Onay Bekleyen Talepler",
      employee_calendar: "Çalışan Takvimi"
    },
    shift_types: {
      FIX: "Sabit",
      FLEXIBLE: "Esnek",
      OFF_DAY: "Hafta Tatili",
      OFFDAY: "Hafta Tatili",
      HOLIDAY: "Resmi Tatili",
      WORKDAY: "İş Günü",
      BREAK: "Dinlenme Günü"
    },
    overtime_approval: {
      employee: "Çalışan",
      workplan: "Vardiya",
      request_status: "Durum",
      reason: "Sebep",
      request_time: "Talep Tarihi"
    },
    overtime_request: {
      approve_title: "Fazla Mesai Onaylama",
      add_overtime: "Fazla Mesai Talebi Oluştur",
      date: "Vardiya Tarihi",
      workplan: "Vardiya",
      overtime_in_minutes: "Mesai Süresi",
      short_minute: "dk",
      request_status: "Durum",
      request_date: "Talep Tarihi",
      period: "Periyod",
      periods: {
        CLOCK_IN: "Mesai Giriş",
        CLOCK_OUT: "Mesai Çıkış",
        CLOCK_IN_OUT: "Mesai Giriş & Çıkış"
      },
      message: "{0} <b> {1} tarihinideki vardiyası için {2} dk </b> fazla mesai talebini onaylamak istiyor musunuz ?"
    },
    timesheet: {
      employee: "Çalışan",
      employees: "Çalışanlar",
      date: "Tarih",
      type: "Türü",
      work_plan: "Çalışma Planı",
      shift: "Vardiya",
      clock_in: "Giriş",
      clock_in_title: "Giriş Zamanı",
      late: "Geç",
      reason: "Sebep",
      late_title: "Geç Giriş",
      it: "KT",
      it_title: "Kimlik Tipi",
      terminal: "Terminal",
      clock_out: "Çıkış",
      clock_out_title: "Çıkış Zamanı",
      early: "Erken",
      early_title: "Erken Çıkış",
      ewt: "BÇS",
      ewt_title: "Beklenen Çalışma Süresi",
      awt: "GÇS",
      twt_title: "Toplam Çalışma Süresi",
      twt: "TÇS",
      awt_title: "Gerçekleşen Çalışma Süresi",
      ot: "FM",
      ot_title: "Fazla Mesai",
      mt: "EM",
      mt_title: "Eksik Mesai",
      name: "Çalışan",
      time: "Zaman",
      identity_type: "Kimlik Türü",
      event_type: "Olay Türü",
      event_time: "Olay Zamanı",
      all_events: "Bütün Olaylar",
      status: "Durum",
      shift_name: "Vardiya Adı",
      branch: "Şube",
      unit: "Birim",
      team: "Takım",
      jobInfo: "Görev",
      staffNumber: "Sicil No",
      information: {
        work_day: "İş Günü",
        work_day_desc: "Personelin o gün işe geleceği anlamına gelir.",
        work_off: "Hafta Tatili",
        work_off_desc: "Personelin o gün hafta tatilidir.",
        public_holiday: "Resmi Tatil",
        public_holiday_desc: "Personelin o gün resmi tatilidir.",
        did_not_come_to_work: "İşe Gelmedi",
        did_not_come_to_work_desc: "Çalışan o gün vardiyası olmasına rağmen işe gelmedi.",
        on_leave: "İzinli",
        on_leave_desc: "Çalışan o gün izinlidir.",
        calculated: "Hesaplandı",
        calculated_desc: "Yeşil renkli yazıların hesaplama işlemi yapıldığı anlamına gelir.",
        not_calculated: "Hesaplanamadı",
        not_calculated_desc: "Kırmızı renkli yazıların hesaplanamadığı anlamına gelir."
      },
      total: "Toplam",
      ot_mt: "Fark"
    },
    calendar: {
      holiday: "Resmi Tatil",
      off_day: "Hafta Tatili",
      breakday: "Dinlenme Günü",
      employee_overtime: "Çalışan bu tarihte işe gelirse fazla mesai olarak hesaplanır",
      employee_off_day: "Çalışanın haftalık tatilidir",
      employee_break_day: "Çalışanın dinlenme günüdür",
      shift_loading: "Idenfit vardiyaları oluşturuluyor",
      create_shift: "Vardiya Oluştur",
      shift_detail: "Vardiya Detayı",
      leave_type: "İzin Tipi",
      begin_time: "Başlangıç Saati",
      end_time: "Bitiş Saati",
      break_time: "Mola Süresi",
      shift_time: "Vardiya Süresi",
      clock_in_max_overtime: "Giriş Maximum Fazla Mesai",
      clock_out_max_overtime: "Çıkış Maximum Fazla Mesai",
      lately_tolerance: "Geç Kalma Toleransı",
      early_tolerance: "Erken Çıkma Toleransı",
      overtime: "Fazla Mesai",
      early_clock_out_tolerance: "Erken Çıkma Toleransı",
      lately_clock_in_tolerance: "Geç Kalma Toleransı",
      max_clock_in_overtime_in_minutes: "Giriş Maksimum Fazla Mesai",
      max_clock_out_overtime_in_minutes: "Çıkış Maksimum Fazla Mesai",
      min_clock_in_overtime_in_minutes: "Giriş Minimum Fazla Mesai",
      min_clock_out_overtime_in_minutes: "Çıkış Minimum Fazla Mesai",
      minimum_overtime: "Minimum fazla mesai",
      maximum_overtime: "Maksimum fazla mesai",
      midnight_tolerance: "Gün Dönümü Toleransı",
      shift_type: "Vardiya Tipi",
      count_as_overtime: "Fazla mesai sayılsın",
      total_work_time: "Toplam Çalışma Süresi",
      selectWeekends: "Hafta Tatili Günleri",
      selectBreaks: "Dinlenme Günleri",
      overtime_request: {
        approve_title: "Fazla Mesai Onaylama",
        add_overtime: "Fazla Mesai Talebi Oluştur",
        detail_overtime: "Fazla Mesai Talebi Detayı",
        date: "Vardiya Tarihi",
        workplan: "Vardiya",
        overtime_in_minutes: "Mesai Süresi",
        request_status: "Durum",
        request_date: "Talep Tarihi",
        approval_date: "Onaylama Tarihi",
        period: "Periyod",
        history: "Onaylama Geçmişi",
        periods: {
          CLOCK_IN: "Mesai Giriş",
          CLOCK_OUT: "Mesai Çıkış",
          CLOCK_IN_OUT: "Mesai Giriş & Çıkış"
        },
        approved:
          "{0} <b> {1} tarihinideki vardiyası için {2} dk </b> fazla mesai talebini onaylamak istiyor musunuz ?",
        rejected:
          "{0} <b> {1} tarihinideki vardiyası için {2} dk </b> fazla mesai talebini reddetmek istiyor musunuz ?",
        approval_to_manager: "Fazla Mesai Yöneticisine onay gitsin",
        not_approval_to_manager: "Fazla Mesai Yöneticisine onay gitmesin",
        pending_approval_manager: "Onayı Beklenen Yönetici",
        processes: {
          approved: "Talebi onayladı",
          pending: "Onay vermesi bekleniyor",
          stopped: "Talep reddedildiği için onaylama süreci durduruldu",
          waiting: "Bir önceki yöneticinin onaylamasını bekliyor",
          denied: "Talep reddedildi"
        }
      },
      multiple_shift_deleting: "Toplu Vardiya Silme",
      cancel_request: "İptal Et",
      cancel_request_title: "Vardiya Talebini İptal Et"
    }
  },
  leave: {
    crud: {
      create: "İzin Talebi Oluştur"
    },
    menu: {
      leave_accruals: "İzin Hakları",
      leave_requests: "İzin Talepleri",
      leave_approvals: "Bekleyen İzin Onayları"
    },
    hourly: "Saatlik İzin",
    daily: "Günlük İzin",
    type: "İzin Tipi",
    begin_time: "Başlangıç Saati",
    end_time: "Bitiş Saati",
    begin_date: "Başlangıç Tarihi",
    end_date: "Bitiş Tarihi",
    approved_time: "Onay Tarihi",
    request_time: "Talep Tarihi",
    approve_or_reject: "Onayla veya Reddet",
    status: "Durum",
    no_approver: "Onaylayan Yok",
    manager: "İzin Yöneticisi",
    managers: "İzin Yöneticileri",
    balance: "Bakiye",
    not_leave_accruals: "Bu çalışanın henüz bir izin haketmemiştir",
    permission: {
      employee_leave_approver_message: "Bu çalışan, diğer çalışanların izin taleplerini onaylayabilir",
      employee_not_leave_approver_message: "Bu çalışan, diğer çalışanların izin taleplerini onaylayamaz",
      employee_shift_approver_message: "Bu çalışan, diğer çalışanlara vardiya atayabilir",
      employee_not_shift_approver_message: "Bu çalışan, diğer çalışanlara vardiya atayamaz",
      employee_overtime_approver_message: "Bu çalışan, diğer çalışanların fazla mesai taleplerini onaylayabilir",
      employee_not_overtime_approver_message: "Bu çalışan, diğer çalışanların fazla mesai taleplerini onaylayamaz"
    },
    notification: {
      employee_receiver_leave_notification_message: "Bu çalışan, diğer çalışanların izin bildirimlerini alabilir",
      employee_not_receiver_leave_notification_message: "Bu çalışan, diğer çalışanların izin bildirimlerini alamaz",
      employee_receiver_shift_notification_message:
        "Bu çalışan, diğer çalışanların vardiya atama bildirimlerini alabilir",
      employee_not_receiver_shift_notification_message:
        "Bu çalışan, diğer çalışanların vardiya atama bildirimlerini alamaz",
      employee_receiver_overtime_notification_message:
        "Bu çalışan, diğer çalışanların fazla mesai talep bildirimlerini alabilir",
      employee_not_receiver_overtime_notification_message:
        "Bu çalışan, diğer çalışanların fazla mesai talep bildirimlerini alamaz"
    },
    respect: "Yukarıdaki izin yöneticileri öncelikli olacaktır",
    order_is_important: "Yöneticinin sırası önemlidir",
    already_selected_manager: "Bu yöneticiyi zaten seçtiniz",
    there_is_no_leave_manager: "Atanmış izin yöneticisi yok",
    add_leave_manager: "İzin Yöneticisi Ekle",
    used: "Kullanılan",
    remaining: "Kalan",
    on_leave: "İzinli",
    period: "Zaman Dilimi",
    duration: "Süre",
    periods: {
      first_half: "1.Yarım",
      second_half: "2.Yarım",
      first_quarter: "1.Çeyrek",
      second_quarter: "2.Çeyrek",
      third_quarter: "3.Çeyrek",
      fourth_quarter: "4.Çeyrek"
    },
    request: {
      this_day_does_not_count_as_leave: "Bugün {0}, izin günlerinden sayılmaz",
      leave_balance: "İzin Bakiyesi",
      max_exceed_leave_balance: "Avans Bakiyesi",
      leave_request_count: "Talep edilen",
      used: "Kullanılan",
      remaining: "Kalan",
      approved: "Onayla",
      denied: "Reddet",
      leave_decreased_count: "İzinden Düşülecek"
    },
    accruals: {
      name: "İzin Adı",
      count: "Toplam Hakediş",
      used: "Kullanılan",
      balance: "Kalan",
      previous_balance: "Önceki Bakiye"
    },
    approvals: {
      leave_approval: "İzin Onaylama",
      message:
        "<b>{1}</b> izin bakiyesi <b>{2}</b> gün olan <b>{0}</b> isimli çalışanın <b>{3}</b> - <b>{4}</b> tarihleri arasında yaptığı toplam <b>{5}</b> günlük izin talebini onaylamak istiyor musunuz?"
    },
    cancels: {
      leave_cancel: "İzin İptal",
      message: "İptal etme nedeniniz?",
      error_message: "İptal etme nedeniniz? alanı gereklidir"
    },
    error_messages: {
      notice_period_error: "Bu izin en az {0} gün önceden talep edilmelidir",
      max_consecutive_error: "Tek seferde maksimum {0} gün kadar izin talep edebilirsiniz",
      min_consecutive_error: "Tek seferde mininum {0} gün kadar izin talep edebilirsiniz",
      leave_request_exist_error: "Bu tarihlerde talep edilmiş bir izin bulunmaktadır",
      total_leave_balance_error1: "Bu izin için yeterli bakiyeniz bulunmamaktadır",
      total_leave_balance_error2:
        "Bu izin için maximum {0} gün avans bakiyeden kullanabilirsiniz, avans bakiyeniz yetmediği için izin talebinde bulunamazsınız"
    },
    e_signature: "Dijital İmza",
    e_signature_leave_form: "Dijital İmzalı İzin Formu",
    leave_form: "İzin Formu",
    leave_cancel: "İzin İptal",
    signed: "İmzalandı",
    not_signed: "İmzalanmadı",
    requests: {
      days_off_automatically: "Otomatik eklenen günler",
      requested_leave_type_documents: "İzin için getirilmesi gereken dokümanlar"
    },
    today_counts_as_a_day_off: "Bugün izin günlerinden sayılır",
    required_document_message: " | Yüklemeniz gereken {count} döküman var | Yüklemeniz gereken {count} dökümanlar var",
    isNexyDayMessage: "İzin başlangıcı ertesi gün"
  },
  leave_request_status: {
    pending: "Onay Bekleyen",
    PENDING: "Onay Bekleyen",
    approved: "Onaylandı",
    APPROVED: "Onaylandı",
    denied: "Reddedildi",
    DENIED: "Reddedildi",
    rejected: "Reddedildi",
    REJECTED: "Reddedildi",
    cancelled: "İptal Edildi",
    CANCELLED: "İptal Edildi",
    need_extra_detail: "Ekstra detay isteniyor",
    NEED_EXTRA_DETAIL: "Ekstra detay isteniyor",
    null: "Bilinmiyor",
    NULL: "Bilinmiyor",
    undefined: "Bilinmiyor",
    UNDEFINED: "Bilinmiyor",
    closed: "Kapalı",
    CLOSED: "Kapalı",
    close_pending: "Kapama Bekliyor",
    CLOSE_PENDING: "Kapama Bekliyor",
    NOT_COME: "Sırası Gelmedi",
    not_come: "Sırası Gelmedi",
    super_admin: "Yönetici Onayı",
    SUPER_ADMIN: "Yönetici Onayı"
  },
  time_duration: {
    hour: "Saat",
    minute: "Dakika",
    second: "Saniye"
  },
  leave_type: {
    paid: "Ücretli",
    unpaid: "Ücretsiz",
    on_duty: "Görevli",
    restricted_holiday: "Kısıtlı Tatil"
  },
  leave_status: {
    APPROVED: "Onaylandı",
    PENDING: "Beklemede",
    DENIED: "Reddedildi",
    CANCELLED: "İptal Edildi",
    NEED_EXTRA_DETAIL: "Detay gerekli",
    not_come: "Sırası gelmedi",
    NOT_COME: "Sırası gelmedi"
  },
  leave_duration: {
    FULL_DAY: "Tam gün {0}",
    HALF_DAY: "Yarım gün {0}",
    QUARTER_DAY: "Çeyrek gün {0}",
    HOURLY_DAY: "Çeyrek gün {0}",
    HOURLY: "Saatlik {0}"
  },
  hr: {
    menu: {
      profile: "Profil",
      performances: "Performans",
      hiring: "İşe Alım",
      trainings: "Eğitimler",
      expenses: "Harcamalar",
      imprests: "Avanslar",
      papers: "Evraklar",
      paper: "Evrak",
      documents: "Dokümanlar",
      health: "Sağlık",
      debit_tickets: "Zimmetler",
      payroll: "Bordrolar",
      events: "Etkinlikler",
      approvals: "Onay Bekleyen Talepler",
      expense_approval: "Harcama Talepleri",
      imprest_approval: "Avans Talepleri",
      messages: "Mesajlar",
      inbox: "Gelen Kutusu",
      outbox: "Giden Kutusu",
      suggestions: "Görüş & Öneriler",
      notes: "Notlar",
      travels: "Seyahatlar",
      travel_requests: "Seyahat Talepleri",
      vehicles: "Araçlar",
      menus: "Yemek Menüsü",
      contact: "İletişim",
      expense: "Harcama",
      expense_request_history: "Harcama Talep Geçmişi",
      imprest: "Avans",
      imprest_request_history: "Avans Talep Geçmişi",
      travel: "Seyahat",
      travel_request_history: "Seyahat Talep Geçmişi",
      debit_ticket_transfer_requests: "Zimmet Transfer Talepleri",
      training: "Eğitim",
      training_requests: "Eğitim Talepleri",
      training_request_history: "Eğitim Talep Geçmişi",
      survey: "Anket",
      pending_surveys: "Bekleyen Anketlerim",
      completed_surveys: "Katıldığım Anketler",
      expired_surveys: "Süresi Dolan Anketler",
      paper_request_history: "Evrak Talep Geçmişi",
      paper_requests: "Evrak Talepleri"
    },
    account: {
      overview: "Hızlı Bakış",
      email: "E-posta",
      profile: "Profil",
      language_role: "Dil & Rol",
      contact: "İletişim",
      personal: "Kişisel",
      family: "Aile",
      education: "Eğitim",
      employment: "Görevlendirme",
      financial: "Finansal",
      social_media: "Sosyal Medya",
      body_size: "Vücut Ölçüleri",
      mobile_device: "Mobile Cihazlar",
      special_fields: "Özel Alanlar",
      messages: "Mesajlar",
      notes: "Notlar",
      management: "Yönetim"
    },
    employee: {
      overview: {
        staff_number: "Sicil No",
        branch: "Şube",
        unit: "Birim",
        team: "Takım",
        email: "E-posta",
        job_info: "Görev Tanımı",
        title: "Ünvan",
        leave: {
          manager: "İzin Yöneticisi",
          managers: "İzin Yöneticileri",
          approver: "İzni Onaylayan"
        },
        hired_date: "İşe Alım Tarihi",
        status: "Durum",
        manager: "Genel Yöneticisi",
        shift_manager: "Vardiya Yöneticisi",
        shift_managers: "Vardiya Yöneticileri",
        role: "Rol"
      },
      information: {
        identity_number: "Kimlik No",
        first_name: "Adı",
        last_name: "Soyadı",
        work_email: "E-posta (İş)",
        phone: "Telefon",
        email_and_sms_send_question: "Bu çalışana e-mail ve sms bilgilendirmesi gönderilsin mi?"
      },
      profile: {
        identity_number: "Kimlik No",
        first_name: "Adı",
        last_name: "Soyadı",
        work_email: "E-posta (İş)",
        phone: "Telefon",
        email_and_sms_send_question: "Bu çalışana e-mail ve sms bilgilendirmesi gönderilsin mi?",
        service_line: "Servis Hattı"
      },
      language_role: {
        language: "Dil",
        role: "Rolü"
      },
      contact: {
        email: "E-posta",
        address: "Adres",
        secondary_phone: "İkinci Telefon",
        fixed_phone: "Sabit Telefon",
        work_phone: "İş Telefonu",
        spouse_phone: "Eş Telefonu",
        extension_number: "Dahili No",
        emergency_contact_name: "Acil Durum Adı Soyadı",
        emergency_contact_phone: "Acil Durum Telefonu"
      },
      personal: {
        pasaport_number: "Pasaport Numarası",
        birth_date: "Doğum Tarihi",
        place_of_birth: "Doğum Yeri",
        nationality: "Uyruk",
        blood_type: "Kan Grubu",
        gender: "Cinsiyet",
        disability_level: "Engel Derecesi",
        ex_convict: "Sabıkalı",
        driver: "Şoför",
        military_service: "Askerlik",
        suspended_until: "kadar askıya alındı",
        hes_code: "HES Kodu",
        hes_expired_date: "HES Kodu Geçerlilik Tarihi"
      },
      family: {
        marital_status: "Medeni Durumu",
        spouse_name: "Eşi'nin Adı",
        child_count: "Çocuk Sayısı",
        tenant: "Kiracı",
        martyrs_relatives: "Şehit akrabası",
        disability_child_count: "Engelli Çocuk Sayısı",
        date_of_marriage: "Evlenme Tarihi",
        father_name: "Baba Adı",
        mother_name: "Anne Adı",
        mothers_maiden_name: "Anne Kızlık Soyadı",
        sibling_count: "Kardeş Sayısı",
        child_info: "Çocuk Bilgisi",
        add_child_info: "Çocuk Bilgisi Ekle"
      },
      education: {
        graduation: "Mezuniyet",
        school: "Okul",
        faculty: "Fakülte",
        department: "Bölüm",
        graduation_grade: "Mezuniyet Derecesi",
        academic_title: "Akademik Ünvan",
        languages: "Diller",
        licence_file: "Lisans Dosyası"
      },
      employment: {
        staff_number: "Sicil No",
        branch: "Şube",
        unit: "Birim",
        team: "Takım",
        job_info: "Görev",
        manager: "Yönetici",
        agency: "Acenta",
        ssn: "SGK No",
        passive: "Pasif",
        hired_date: "İşe Alım Tarihi",
        started_date: "İşe Başlama Tarihi",
        employment_type: "Çalışma Şekli",
        collar_type: "Yaka Tipi",
        quite_job: "İşten Ayrılma",
        quit_job_reason: "İşten Ayrılma Nedeni",
        quit_job_reason_description: "Açıklama",
        quit_job_date: "İşten Çıkış Tarihi",
        have_an_interview_made: "Mülakat Yapıldı",
        have_an_interview_made_not: "Mülakat Yapılmadı",
        integration_value: "Entegrasyon Numarası",
        research_and_development_person: "Arge Çalışanı",
        social_security_type: "Sosyal Güvenlik Tipi",
        company: "Şirket",
        allowance_date: "Hakediş Başlama Tarihi"
      },
      financial: {
        iban: "IBAN",
        ticket_number: "Yemek Kartı No",
        bank_account_number: "Banka Hesap Numarası",
        bank_branch: "Hesap Şubesi",
        salary: "Maaşı",
        ticket_price: "Yemek Kartı Bakiyesi",
        previous_salary: "Önceki Maaşı",
        previous_ticket_price: "Önceki Yemek Kartı Bakiyesi",
        yearly_premium: "Yıllık Prim",
        monthly_stoppage: "Aylık Stopaj",
        health_insurance_person_count: "Ailedeki Sigortalı Kişi Sayısı",
        agi: "Asgari Geçim İndirimi (AGİ)",
        gross_salary: "Brüt Maaş",
        pay_to_net: "Net Maaş",
        pay_to_gross: "Brüt Maaş",
        salary_type: "Maaş Ödeme Türü"
      },
      social_media: {
        facebook: "Facebook",
        twitter: "Twitter",
        instagram: "İnstagram",
        linkedin: "Linkedin",
        skype: "Skype"
      },
      body_size: {
        weight: "Kilo",
        height: "Boy",
        shirt_size: "Gömlek Bedeni",
        pants_size: "Pantolon Bedeni",
        shoe_size: "Ayakkabı Numarası",
        waistcoat: "Yelek",
        coat: "Mont"
      },
      management: {
        manager: "Genel Yönetici",
        leave_managers: "İzin Yöneticileri",
        shift_managers: "Vardiya Yöneticileri",
        overtime_managers: "Fazla Mesai Yöneticileri",
        expense_managers: "Harcama Yöneticileri"
      },
      mobile_device: {
        serial_number: "Seri Numarası",
        multiple_device_allowed: "Çoklu Cihaz Girişi"
      }
    },
    performance: {
      results: {
        evaluatee: "Değerlendirilen",
        published_evaluators: "Değerlendirenler",
        unpublished_evaluators: "Değerlendirmeyenler",
        kpi_score: "Hedef Puanı",
        competence_score: "Yetkinlik Puanı",
        totalCount: "Toplam Katılımcı",
        publishedCount: "Aktif Katılımcı",
        evaluation_name: "Değerlendirme Adı",
        total_score: "Toplam Puan",
        status: "Durum",
        period: "Dönem",
        scoring: "Puanlama",
        beginDate: "Başlangıç Tarihi",
        endDate: "Bitiş Tarihi"
      },
      table: {
        name: "Değerlendirme Adı",
        type: "Performans Tipi",
        score_range: "Puan Aralığı",
        evaluatee: "Değerlendirilecek Çalışan",
        expiration_period: "Dönem",
        validation_period: "Süre",
        status: "Değerlendirme Durumu",
        categories: "Kategoriler"
      },
      depths: {
        depth_1: "1. Seviye",
        depth_2: "2. Seviye",
        depth_3: "3. Seviye",
        depth_4: "4. Seviye",
        depth_5: "5. Seviye"
      },
      add: {
        kpi: "Hedef Ekle",
        competence: "Yetkinlik Ekle",
        pool: "Havuzdan eklemek istiyorum",
        custom: "Yeni eklemek istiyorum",
        kpi_group: "Hedef Grubu Ekle",
        competence_group: "Yetkinlik Grubu Ekle"
      },
      detail: {
        kpi_group: "Hedef Grubu Detayı",
        competence_group: "Yetkinlik Grubu Detayı"
      },
      crud: {
        detail: {
          template: "Şablon Detayı",
          evaluation: "Değerlendirme Planı Detayı"
        }
      },
      type: {
        KPI: "Hedef",
        COMPETENCE: "Yetkinlik"
      },
      validation: {
        add_item: "{0} kategorisine soru eklemelisiniz",
        template_exist_category: "{0} kategorisine kategori eklenmiş",
        template_must_a_category: "Performans şablonunda en az bir tane kategori olmalı",
        question_total_weight_required: "Soruların ağırlıklarının toplamı {0}% olmak zorundadır",
        question_total_weight: "Soruların ağırlıklarının toplamı {0}%",
        category_not_exist: "Böyle bir kategori mevcut değil",
        category_total_weight_equals_in_questions:
          "{0} kategorisinin toplam ağırlığı {1}% olduğu için bu kategoriye ait soruların toplam ağırlığı {1}% olmak zorundadır",
        category_in_question_required: "{0} kategorisinde en az bir soru olmak zorundadır",
        question_weight_required: "{0} ağırlığını giriniz"
      },
      information:
        '<p class="body-1 mt-5">' +
        "KPI & COMPETENCE kategorilerini seçerek kpi ve competence listenizi oluşturabilirsiniz.\n" +
        "</p>" +
        '<p class="body-1">' +
        "Seçtiğiniz kategoriye tanımlanmış olan kpi ve competence sorularına ağırlıklarını girerek soru bazlı puanlama yapabilirsiniz.\n" +
        "</p>" +
        '<p class="body-1">' +
        "Eklediğiniz listedeki soruların toplamı KPI'a veya COMPETENCE'e belirlediğiniz ağırlığa eşit olmalı.\n" +
        "</p>",
      total_evaluatees_count:
        "Toplam değerlendirmeye katılacak kişi <span class='headline font-weight-bold'>{0}</span>",
      waiting_evaluates_finish: "{0} kişinin değerlendirmeyi tamamlaması bekleniyor",
      complete_evaluation: "Bu değerlendirme tamamlanmış",
      expiration_date: "Bitiş Tarihi",
      template_name: "Şablon Adı",
      kpi: "Hedef",
      kpi_category: "Hedef Kategorisi",
      kpis: "Hedefler",
      competence: "Yetkinlik",
      competence_category: "Yetkinlik Kategorisi",
      competences: "Yetkinlikler",
      result_view: "Sonuçları Göster",
      waiting: "Bekleniyor",
      hourly: "Saatlik",
      weekly: "Haftalık",
      monthly: "Aylık",
      yearly: "Yıllık",
      score: "Puan",
      star: "Yıldız",
      weight: "Ağırlık",
      point: "Puan",
      person: "Kişi",
      persons: "Kişiler",
      period_type: "Periyot",
      score_type: "Puanlama Tipi",
      score_min_count: "En düşük puan",
      score_max_count: "En yüksek puan",
      evaluation: "Değerlendirme",
      evaluatees: "Değerlendirilecekler",
      evaluators: "Değerlendirecekler",
      self: "Kendisi",
      superior: "Üstleri",
      subordinates: "Astları",
      same: "Eşitleri",
      flexible: "Esnek Seçim",
      period: "Değerlendirme Periyodu",
      expiration_dates: "Dönem Aralığı",
      expiration_begin_date: "Değerlendirilecek Dönem Başlangıcı",
      expiration_end_date: "Değerlendirilecek Dönem Bitiş",
      level: "Seviye",
      depth: "Derinlik",
      validation_date: "Değerlendirme Aralığı",
      validation_begin_date: "Değerlendirme Başlangıç Tarihi",
      validation_end_date: "Değerlendirme Bitiş Tarihi",
      published_date_time: "Yayınlanma Tarihi",
      degree_360: "360 °",
      m2m: "M2M",
      published: "Değerlendirmeyi Başlat",
      published_start: "Değerlendir",
      evaluation_done: "Değerlendirildi",
      are_you_sure: "Emin misiniz?",
      are_you_text: "Bu değerlendirmeyi başlatmak istiyor musunuz?",
      performance_item: {
        title: "Performans"
      },
      my_evaluatees: "Değerlendireceklerim",
      my_evaluated: "Değerlendirdiklerim",
      off_message_title: "Geliştirme Sürecinde",
      off_message_content: "Performans modülü iyileştirme süreçlerinden dolayı kullanım dışıdır.",
      info_dialog_title: "Bilgilendirme",
      info_dialog_message:
        "Değerlendirmeniz işleme alınmıştır, işlem tamamlandığında değerlendirmeniz sisteme yansıyacaktır."
    },
    training: {
      plan: "Eğitim Planı",
      name: "Eğitim Adı",
      type: "Eğitim Tipi",
      organizer: "Planlayan",
      lecturer: "Eğitmen",
      terminal: "Terminal",
      sessions: "Oturumlar",
      session: "Oturum",
      training_type: {
        ONLINE: "Online",
        CLASSROOM: "Sınıf",
        ON_SITE: "Saha"
      },
      training_detail: "Eğitim Detayı",
      location: "Lokasyon",
      description: "Açıklama",
      training: "Eğitim",
      request_training: "Eğitim Talep Et",
      training_requests: "Eğitim Talepleri",
      request_date: "Talep Tarihi",
      abolisher: "İptal Eden Çalışan",
      approval_history: "Onaylama Geçmişi",
      cancelled_date: "İptal Edildiği Tarih",
      hr_manager: "İnsan Kaynakları Yöneticisi",
      approval_date: "Onaylama Tarihi",
      cancel_request: "İptal Et",
      cancellation_note: "İptal etme nedeniniz?",
      request_cancellation_process: "Eğitim Talebi İptal İşlemi",
      request_approval_process: "Eğitim Talebi Onaylama İşlemi",
      denied: "Reddet",
      approved: "Onayla",
      training_planned: "Eğitim Planlandın Mı?"
    },
    expense: {
      name: "Harcama Adı",
      type: "Harcama Tipi",
      amount: "Harcama Tutarı",
      date: "Harcama Tarihi",
      status: "Durumu",
      attachment: "Dosya",
      create_expense: "Harcama Talep Et",
      travel: "Seyahat",
      approved_amount: "Onaylanan Tutar",
      expense_type: {
        expense_type: "Harcama Tipi"
      },
      payment_type: {
        CASH: "Nakit",
        CREDIT_CARD: "Kredi Kartı",
        PREPAID_CARD: "Şirket Kredi Kartı",
        OTHER: "Diğer",
        null: "Belirtilmemiş"
      }
    },
    imprest: {
      amount: "Tutar",
      period: "Tarih",
      reason: "Sebep",
      status: "Durum",
      create_imprest: "Avans Talep Et",
      travel: "Seyahat",
      approved_amount: "Onaylanan Tutar",
      date: "Tarih"
    },
    paper: {
      name: "Evrak Adı",
      type: "Evrak Tipi",
      issue_date: "Veriliş Tarihi",
      expire_date: "Bitiş Tarihi",
      signature_status: "İmza Durumu",
      signature_types: {
        NOT_REQUIRED: "Zorunlu değil",
        STANDARD_SIGNATURE: "Standart İmza",
        BIOMETRIC_SIGNATURE: "Biyometrik İmza",
        SIGNED: "İmzalı",
        null: "-",
        E_SIGNATURE: "Dijital İmza"
      },
      add_paper: "Evrak Ekle",
      papers: {
        issue_date: "Veriliş Tarihi",
        expire_date: "Bitiş Tarihi"
      }
    },
    document: {
      name: "Döküman Adı",
      type: "Döküman Tipi",
      category: "Döküman Kategorisi",
      sender: "Gönderen",
      date: "Gönderme Tarihi",
      add_document: "Döküman Ekle",
      mobile_accessible_disable: "Mobilde göremez",
      mobile_accessible_enable: "Mobilde görebilir"
    },

    health: {
      name: "Hastalık Adı",
      category: "Hastalık Kategorisi",
      from: "Başlangıç Tarihi",
      to: "Bitiş Tarihi",
      disease_detail: "Hastalık Detayı",
      importance: "Önem",
      importance_enum: {
        LOW: "Düşük",
        MEDIUM: "Orta",
        HIGH: "Yüksek"
      },
      medicines: "İlaçlar",
      attachment: "Ek Dosya",
      description: "Açıklama"
    },
    debit_tickets: {
      asset: "Varlık",
      borrow_date: "Ödünç Alma Tarihi",
      due_date: "Bitiş Tarihi",
      give_back_date: "İade Tarihi",
      quantity: "Adet",
      transfer: "Transfer Et",
      transfer_approval: "Transfer Onaylama",
      approver: "Transfer Edilecek Çalışan",
      debit_transfer: "Zimmet Transferi",
      download_form: "Formu İndir",
      DEBIT_TRANSFER_DUPLICATE_EXCEPTION: "Bu zimmet için daha önceden oluşturulmuş bir transfer talebi bulunmaktadır",
      DATA_INTEGRITY_VIOLATION_EXCEPTION: "Bu zimmet zaten size zimmetlenmiş"
    },
    payroll: {
      month: "Ay",
      created_date: "Oluşturulma Tarihi",
      file: "Dosya"
    },
    events: {
      name: "Etkinlik Adı",
      type: "Etkinlik Tipi",
      owner: "Oluşturan",
      online: "Online",
      begin_date: "Başlangıç Tarihi",
      end_date: "Bitiş Tarihi",
      activity: "Etkinlik"
    },
    expense_approval: {
      employee: "Çalışan",
      description: "Açıklama",
      amount: "Tutar",
      status: "Durum",
      request_date: "Talep Tarihi",
      document: "Dosya",
      expense_approval: "Harcama Onaylama",
      message:
        "{0}, <b>{1}</b> tarihinde <b>{2}</b> tipinde <b>{3}</b> harcama talebinde bulundu, onaylamak istiyor musunuz?"
    },
    imprest_approval: {
      employee: "Çalışan",
      amount: "Tutar",
      status: "Durum",
      request_date: "Talep Tarihi",
      imprest_approval: "Avans Onaylama",
      message: "{0}, <b>{1}</b> tarihinde <b>{2}</b> avans talebinde bulundu, onaylamak istiyor musunuz?",
      approved_amount: "Onaylanan Tutar",
      requested_amount: "Talep Edilen Tutar"
    },
    hiring: {
      job_posting: "İlan",
      applicant: "Başvuran",
      applicants: "Başvuranlar",
      interview: "Görüşme",
      assessment_date: "Görüşme Tarihi",
      working_date: "İşe Başlama Tarihi",
      last_job: "Son çalıştığı iş",
      last_position_level: "Son çalıştığı pozisyon",
      evaluator: "Değerlendiren",
      evaluators: "Değerlendirenler",
      weight: "Ağırlık",
      link: "Link",
      skill: "Yetenek",
      rate: "Oran",
      rating: "Puan Ver",
      table: {
        applicant: "Başvuru Yapan",
        job_posting: "İlan",
        last_job: "Son İşi",
        assessment: "Genel Değerlendirme",
        rating: "Değerlendirme Sonucu",
        assessment_date: "Değerlendirme Tarihi",
        status: "Tamamlanma Durumu",
        evaluators: "Değerlendirenler",
        evaluator: "Değerlendiren"
      },
      skill_type: {
        soft: "Soft",
        technical: "Teknik",
        question: "Soru",
        link: "Link"
      }
    },
    message: {
      message_type: "Mesaj Tipi",
      sender: "Gönderen",
      receivers: "Alıcılar",
      importance: "Önem Derecesi",
      sms: "Sms",
      email: "E-mail",
      in_app: "Bildirim",
      title: "Başlık",
      body: "Mesaj İçeriği",
      whom: "Kime",
      date: "Gönderim Tarihi",
      message_type_enum: {
        EMAIL: "E-posta",
        SMS: "Sms",
        IN_APP: "Bildirim"
      },
      message_importance_enum: {
        LOW: "Düşük",
        MEDIUM: "Orta",
        HIGH: "Yüksek"
      },
      send_message: "Mesaj Gönder",
      success_message: "{0} kişiye {1} gönderildi"
    },
    suggestion: {
      category: "Görüş & Öneri Kategorisi",
      title: "Görüş & Öneriler",
      add_suggestion: "Görüş & Öneri Ekle",
      body: "Mesaj",
      owner: "Oluşturan"
    },
    note: {
      notes: "Notlar",
      title: "Başlık",
      body: "İçerik",
      attachment: "Ek Dosya",
      description: "Açıklama",
      add_note: "Not Ekle",
      owner: "Çalışan",
      importance: "Önem Derecesi"
    },
    travel: {
      request_travel: "Seyahat Talep Et",
      leave_request: "İzin Talebi",
      travels: "Seyahatler",
      travel: "Seyahat",
      travel_detail: "Seyahat Detayı",
      start_work_date: "İş Başı Tarihi",
      is_domestic: "Yurt içi seyahat mı?",
      country: "Gidilecek Ülke",
      city: "Gidilecek Şehir",
      region: "Gidilecek Bölge",
      is_rent_car: "Kiralık araç var mı?",
      is_shuttle: "Havalimanı transferi var mı?",
      description: "Açıklama",
      reason: "Sebep",
      status: "Durum",
      expense_message:
        "<strong>{0}</strong>, <strong>{1}</strong> isminde <strong>{2}</strong> tutarında harcama talebinde bulundu",
      imprest_message:
        "<strong>{0}</strong>, <strong>{1}</strong> tarihinde <strong>{2}</strong> tutarında avans talebinde bulundu",
      subsistence_message:
        "<strong>{0}</strong> isimli çalışana <strong>{1}</strong> tarihinde <strong>{2}</strong> tutarında harcırah verildi",
      travel_dates: "Seyahat Tarihleri",
      pending_travel_approvals: "Bekleyen Seyahat Onayları",
      travel_process: "Seyahat Talebi İşlemi",
      travel_details: "Seyahat Detayları",
      no_detail_item: "Hiç detay eklenmemiş",
      add_new_detail: "Yeni Detay Ekle",
      detail_type: "Detay Tipi",
      detail_subtype: "Detay Alt Tipi",
      travel_detail_type_enum: {
        TRANSFER: "Transfer",
        LAYOVER: "Konaklama",
        BUSINESS: "İş",
        TRAVEL_INSURANCE: "Seyahat Sigortası"
      },
      travel_detail_subtype_enum: {
        BUS: "Otobüs",
        PLANE: "Uçak",
        TRAIN: "Tren",
        SHIP: "Gemi",
        CAR: "Otomobil",
        HOTEL: "Otel",
        HOSTEL: "Pansiyon",
        VILLA: "Villa",
        FACTORY: "Fabrika",
        OFFICE: "Ofis",
        TRAVEL_HEALTH_POLICY: "Seyahat Sağlık Poliçesi"
      },
      begin_date: "Başlangıç Tarihi",
      end_date: "Bitiş Tarihi",
      expenses: "Harcamalar",
      imprests: "Avanslar",
      debits: "Zimmetler",
      borrow_date: "Ödünç Tarihi",
      due_date: "Bitiş Tarihi",
      give_back_date: "Geri Verilme Tarihi",
      quantity: "Adet",
      name: "İsim",
      category: "Kategori",
      model: "Model",
      subsistences: "Harcırahlar",
      project: "Proje",
      attrition: "Yıpranma Payı",
      new_leave_request: "Yeni izin oluştur",
      adding_expense: "Harcama Ekleme",
      adding_imprest: "Avans Ekleme",
      adding_subsistence: "Harcırah Ekleme",
      adding_debit: "Zimmet Ekleme",
      assignment_form: "Görevlendirme Formu"
    },
    travel_request: {
      employee: "Çalışan",
      begin_date: "Başlangıç Tarihi",
      end_date: "Bitiş Tarihi",
      start_work_date: "İş Başı Tarihi",
      status: "Durum",
      request_date: "Talep Tarihi",
      travel_approval: "Seyahat Onaylama",
      message:
        "<b>{0}</b>, <b>{1}</b> - <b>{2}</b> tarihleri arasında seyahat talebinde bulundu, onaylamak istiyor musunuz?"
    },
    vehicle: {
      plate_number: "Plaka Numarası",
      brand: "Marka",
      model: "Model",
      year: "Yıl",
      branch: "Şube",
      employee: "Çalışan"
    },
    contact: {
      employee: "Çalışan",
      email: "E-posta",
      phone: "Telefon Numarası"
    },
    projects: {
      project: "Proje",
      employee_count_message: "Çalışan yok | 1 çalışan | {count} çalışan"
    },
    debit_ticket_transfer_request: {
      requester: "Talep Eden",
      asset: "Varlık",
      approval_message:
        "<strong>{0}</strong>, aşağıda bilgileri yer alan varlığı sizin zimmetinize transfer etme talebinde bulundu, onaylamak istiyor musunuz?"
    },
    survey: {
      name: "Anket Adı",
      published_date: "Yayınlanma Tarihi",
      end_date: "Bitiş Tarihi",
      start_survey: "Ankete Başla",
      survey_privacy: "Anket Gizliliği",
      status: "Durum",
      pending_title: "Tamamlamanız Gereken Anketler",
      completed_title: "Katıldığım Anketler",
      expired_title: "Süresi Dolan Anketler",
      complete_survey: "Anketi Tamamla",
      survey_information: "Anket Bilgileri",
      exit: "Çıkış",
      save_and_finish: "Kaydet ve Bitir",
      status_enum: {
        completed: "Tamamlandı",
        continuous: "Devam Ediyor",
        expired: "Süresi Doldu"
      },
      section_count: "Bölüm Sayısı",
      question_count: "Soru Sayısı",
      estimated_duration: "Tahmini Süre",
      description: "Açıklama",
      anonymous_on: "Gizli Oylama",
      anonymous_off: "Açık Oylama",
      enter_answer: "Cevap girebilirsiniz",
      your_answer: "Cevabınız",
      please_select: "Seçiniz"
    },
    employee_requesting: "Talep Eden",
    employee_creating: "Talebi Oluşturan"
  },
  feedback: {
    reasons: "Sebepler",
    reason: "Sebep",
    feeling: "Yüz ifadesi",
    feeling_enum: {
      BAD: "Çok Kötü",
      SAD: "Kötü",
      NORMAL: "Normal",
      HAPPY: "Mutlu",
      ECSTATIC: "Çok Mutlu"
    },
    title: "Geri Bildirim {0} / {1}",
    comment: "Açıklama"
  },
  menu: {
    dashboard: "Anasayfa",
    time: "Zaman",
    hr: "İnsan Kaynakları",
    leave: "İzin",
    clock_in_out: "Giriş / Çıkış"
  },
  message: {
    saved_successfully: "Kayıt Başarılı",
    registration_failed: "Kayıt Başarısız",
    updated_successfully: "Güncelleme Başarılı",
    deleted_successfully: "Silme Başarılı",
    download_email_report: "Raporunuz hazır, indirmek için indir butonuna tıklayınız",
    clock_ip_error: "İp adresi izin verilen ip adresleri arasında değil",
    evaluation_date_range_error_message: "Aktif değerlendirme döneminde değilsiniz",
    leave_calculate_error: "Hesaplama hatası",
    required_document_message: "Yüklemeniz gereken döküman var",
    notice_period_error: "Bu izin en az {0} gün önceden talep edilmelidir",
    max_consecutive_error: "Tek seferde maksimum {0} gün kadar izin talep edebilirsiniz",
    min_consecutive_error: "Tek seferde mininum {0} gün kadar izin talep edebilirsiniz",
    leave_request_exist_error: "Bu tarihlerde talep edilmiş bir izin bulunmaktadır",
    total_leave_balance_error1: "Bu izin için yeterli bakiyeniz bulunmamaktadır",
    total_leave_balance_error2:
      "Bu izin için maximum {0} gün avans bakiyeden kullanabilirsiniz, avans bakiyeniz yetmediği için izin talebinde bulunamazsınız",
    network_error: "İnternet bağlantı hatası",
    no_record_found: "Kayıt Bulunamadı",
    max_range_error: "En fazla {0} gün seçebilirsiniz!"
  },
  global: {
    comment: "Yorum",
    description: "Açıklama",
    day: "Gün",
    note: "Not",
    employee: "Çalışan",
    address: "Adres",
    explanation: "Açıklama",
    hour: "Saat",
    month: "Ay",
    phone: "Telefon",
    status: "Durum",
    file: "Dosya",
    greeting: "Merhaba, {0}",
    search_for_employee: "Çalışan aramak için birşeyler yazın...",
    others: "Diğerleri",
    importance_enum: {
      LOW: "Düşük",
      MEDIUM: "Orta",
      HIGH: "Yüksek"
    },
    importance: "Önem Derecesi",
    date: "Tarih",
    loading_message: "Yükleniyor...",
    birth_date: "Doğum Günü",
    min_character_message: "En az {0} karakter girmeniz gerekiyor",
    no_employee_message: "Aradığınız kriterde calisan bulunamadi",
    employees: "Çalışanlar",
    beginDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    beginTime: "Başlangıç Saati",
    endTime: "Bitiş Saati",
    year: "Yıl",
    message: "Mesaj",
    detail: "Detay",
    country: "Ülke",
    active_employees: "Aktif Çalışanlar",
    passive_employees: "İşten Ayrılanlar",
    maxEmployeeCount: "Norm Kadro Sayısı",
    years_old: "yaşında",
    print: "Yazdır",
    expense_management: "Harcama Yönetimi",
    paymentType: "Ödeme Tipi",
    employee_selected: "Çalışan seçiniz...|1 çalışan seçildi|{count} çalışan seçildi",
    shift_selected: "Vardiya seçiniz...|1 vardiya seçildi|{count} vardiya seçildi",
    shifts_type: "{0} Vardiyalar",
    total_hours: "Toplam Saat",
    name: "İsim",
    reason: "Sebep",
    organization: "Organizasyon",
    minute: "Dakika",
    from: "Başlangıç",
    to: "Bitiş",
    shift: "Vardiya",
    uploaded_files: "Yüklenen Dosyalar",
    manager: "Yönetici",
    selected: "{0} seçildi"
  },
  assessment: {
    very_poor: "Çok Kötü",
    poor: "Kötü",
    normal: "Orta",
    good: "İyi",
    very_good: "Çok İyi",
    na: "Değerlendirme Yapılmadı"
  },
  buttons: {
    home: "Anasayfa",
    ok: "Tamam",
    delete: "Sil",
    back: "Geri",
    save: "Kaydet",
    update: "Güncelle",
    edit: "Düzenle",
    cancel: "İptal",
    close: "Kapat",
    dont_save: "Kaydetmeden Çık",
    dont_save_continue: "Kaydetmeden Devam Et",
    save_changes: "Değişiklikleri Kaydet",
    evaluation_finish: "Değerlendirmeyi Tamamla",
    assign: "Atama",
    add: "{0} Ekle",
    add_and_new: "Ekle ve Yeni",
    refresh: "Yenile",
    filter: "Filtrele",
    export: "Dışarı Aktar",
    create: "{0} Oluştur",
    create_and_new: "Oluştur ve Yeni",
    download: "İndir",
    yes: "Evet",
    no: "Hayır",
    clear: "Temizle",
    filter_results: "Sonuçları Filtrele",
    delete_document: "Dokümanı Sil",
    scan: "Tara",
    send_message: "Mesaj Gönder",
    send: "Gönder",
    apply: "Uygula",
    info: "Bilgilendirme",
    prev: "Önceki {0}",
    next: "Sonraki {0}",
    list_table: "Tablo Modu",
    list_card: "Kart Modu",
    continue: "Devam",
    show_all: "Hepsini Göster",
    cancel_leave: "İzİnİ İptal Et",
    select_all: "Tümünü Seç",
    upload: "Yükle",
    file_upload: "Dosya Yükle",
    file_download: "Dosya İşlemleri",
    import: "İçeri Aktar",
    upload_template: "Şablonu Yükle",
    download_template: "Şablonu İndir",
    trigger: "Tetikle",
    action: "İşlem Yap",
    send_registration_credentials: "Kimlik Bilgilerini Gönder",
    on: "Açık",
    off: "Kapalı",
    uploads: {
      employee: "Çalışanları içeri aktar",
      leave: "İzinleri içeri aktar"
    },
    multiple_delete_shift: "Toplu Vardiya Silme",
    multiple_upload_shift: "Toplu Vardiya Yükleme",
    multiple_actions: "Toplu İşlemler",
    send_email_and_sms_notification: "E-mail & SMS bilgilendirmesi Gönder",
    add_expense: "Harcama Talep Et",
    add_imprest: "Avans Talep Et",
    add_overtime: "Fazla Mesai Talep Et",
    add_leave_request: "İzin Talep Et",
    evaluatee: "Değerlendir",
    add_skill: "Soru Ekle",
    sign: "İmzala",
    skip: "Atla",
    back_to_home: "Anasayfaya Dön",
    please_select: "Lütfen seçiniz...",
    preview: "Ön İzleme"
  },
  response: {
    success: {
      ok: "İşlem başarılı",
      update: "Güncelleme başarılı",
      delete: "Silme başarılı",
      send_sms_password_code: "Şifre yenileme kodu sms olarak gönderildi"
    },
    error: {
      user_not_found: "Kullanıcı adı veya şifre hatalı",
      network_error: "İnternet bağlantı hatası"
    }
  },
  request_status: {
    pending: "Onay Bekleyen",
    PENDING: "Onay Bekleyen",
    approved: "Onaylandı",
    APPROVED: "Onaylandı",
    denied: "Reddedildi",
    DENIED: "Reddedildi",
    cancelled: "İptal Edildi",
    CANCELLED: "İptal Edildi",
    need_extra_detail: "Ekstra detay isteniyor",
    NEED_EXTRA_DETAIL: "Ekstra detay isteniyor",
    undefined: "Bilinmiyor",
    revision: "Revizyon",
    REVISION: "Revizyon"
  },
  shift_types: {
    FIX: "Sabit",
    FLEXIBLE: "Esnek",
    OFF_DAY: "Hafta Tatili",
    OFFDAY: "Hafta Tatili",
    HOLIDAY: "Resmi Tatili",
    WORKDAY: "İş Günü",
    BREAK: "Dinlenme Günü",
    OVERTIME: "Fazla Mesai",
    OVERTIME_SHIFT: "Fazla Mesai Vardiyası",
    ON_LEAVE: "İzinli"
  },
  shift: {
    overtime: "Fazla Mesai",
    missing_time: "Eksik Mesai",
    shift_parameters: "Vardiya Parametresi",
    break: "Mola",
    sub_events: "Ara Kayıtlar",
    included_calculation: "Hesaplamaya dahil edilir",
    not_included_calculation: "Hesaplamaya dahil edilmez",
    included_overtime_calculation: "Fazla mesai hesaplamaya dahil edilmez",
    not_included_overtime_calculation: "Fazla mesai hesaplamaya dahil edilir",
    included_missing_time_calculation: "Eksik mesai hesaplamaya dahil edilmez",
    not_included_missing_time_calculation: "Eksik mesai hesaplamaya dahil edilir",
    work_day: "Çalışma Günü",
    overtime_day: "Fazla Mesai Günü",
    full_time: "Tam Mesai",
    absent: "Devamsız",
    unpaid_leave: "Ücretsiz İzin",
    paid_leave: "Ücretli İzin",
    public_holiday: "Resmi Tatil",
    on_leave: "İzinli",
    off_day: "Hafta Tatili",
    break_day: "Dinlenme Günü",
    incalculable: "Hesaplanamayan Mesai"
  },
  sensors: {
    NFC: "Nfc",
    FACE: "Yüz Tanıma",
    RFID: "Rfid",
    FINGER: "Parmak izi",
    BLUETOOTH: "Bluetooth",
    GATEWARE: "Gateware",
    BEACON: "Beacon",
    GPS: "Gps",
    WIFI: "Wifi",
    ID: "Id",
    QR: "Qr",
    QR_CODE: "Kare Kod",
    PASSWORD: "Şifre",
    PIN: "Pin",
    CARD: "Kart",
    FIXED: "Sabit",
    EXTERNAL: "Harici",
    JAN: "JAN",
    WEB: "Web"
  },
  identity_type: {
    FACE: "Yüz",
    PASSWORD: "Şifre",
    PIN: "Pin",
    FINGER: "Parmak İzi",
    QR_CODE: "Kare Kod",
    CARD: "Kart",
    EXTERNAL: "Harici"
  },
  event_types: {
    BREAK_IN: "Mola Giriş",
    BREAK_OUT: "Mola Çıkış",
    LUNCH_IN: "Yemek Giriş",
    LUNCH_OUT: "Yemek Çıkış",
    DUTY_IN: "Görev Giriş",
    DUTY_OUT: "Görev Çıkış",
    CLOCK_IN: "Mesai Giriş",
    CLOCK_OUT: "Mesai Çıkış",
    UNKNOWN: "Bilinmiyor",
    undefined: "Bilinmiyor"
  },
  currency: {
    amount: "Tutar",
    requested_amount: "Talep edilen tutar",
    approved_amount: "Onaylanan tutar",
    code: "Birim",
    codes: {
      TRY: "TL",
      USD: "DOLAR",
      EUR: "EURO"
    }
  },
  titles: {
    expense: {
      create: "Harcama Ekle",
      detail: "Harcama Detayı"
    },
    imprest: {
      create: "Avans Ekle",
      detail: "Avans Detayı"
    }
  },
  language: {
    tr: "Türkçe",
    en: "İngilizce",
    de: "Almanca",
    TURKISH: "Türkçe",
    ENGLISH: "İngilizce",
    GERMAN: "Almanca"
  },
  status: {
    active: "Aktif",
    passive: "Pasif",
    enable: "Etkin",
    disable: "Devre Dışı"
  },
  gender: {
    male: "Erkek",
    female: "Kadın",
    other: "Belirtilmemiş",
    others: "Belirtilmemiş",
    MALE: "Erkek",
    FEMALE: "Kadın",
    OTHER: "Belirtilmemiş"
  },
  marital_status: {
    married: "Evli",
    single: "Bekar",
    divorced: "Boşanmış"
  },
  education: {
    student: "Öğrenci",
    primary_school: "İlk Okul",
    secondary_school: "Orta Okul",
    high_school: "Lise",
    collage: "Ön Lisans",
    university: "Lisans",
    master: "Yüksek Lisans",
    phd: "Doktora"
  },
  education_languages: {
    turkish: "Türkçe",
    english: "İngilizce",
    german: "Almanca",
    french: "Fransızca",
    dutch: "Hollandaca / Flemenkçe",
    spanish: "İspanyolca",
    italian: "İtalyanca",
    arabic: "Arapça",
    russian: "Rusça",
    persian: "Farsça",
    chinese: "Çince",
    japanese: "Japonca",
    hindi: "Hintçe",
    latin: "Latince",
    portuguese: "Portekizce",
    korean: "Korece"
  },
  military_service: {
    done: "Tamamladı",
    undone: "Yapılmadı",
    exempt: "Muaf",
    suspended: "Askıya Alındı"
  },
  academic_title: {
    lecturer: "Eğitmen",
    research_assistant: "Araştırma Görevlisi",
    assistant_professor: "Asistan Profesör",
    associate_professor: "Doçent",
    professor: "Profesör"
  },
  employment_type: {
    full_time: "Tam Zamanlı",
    part_time: "Yarı Zamanlı",
    casual: "Gündelik",
    contracted: "Sözleşmeli",
    freelance: "Serbest",
    intern: "Stajyer",
    mobile: "Mobil",
    retired: "Emekli",
    trial: "Deneme Süresi",
    seasonal: "Sezonluk",
    disabled: "Engelli"
  },
  collar_type: {
    white: "Beyaz",
    blue: "Mavi",
    gray: "Gri"
  },
  quite_job_reasons: {
    resignation: "İstifa",
    cancellation_of_labor_contract: "Sözleşme İptali",
    military_service: "Askerlik",
    other: "Diğer"
  },
  blood_type: {
    o_rh_negative: "0-",
    o_rh_positive: "0+",
    a_rh_negative: "A-",
    a_rh_positive: "A+",
    b_rh_negative: "B-",
    b_rh_positive: "B+",
    ab_rh_negative: "AB-",
    ab_rh_positive: "AB+"
  },
  time_duration: {
    hour: "Saat",
    minute: "Dakika",
    second: "Saniye"
  },
  date: {
    year: "Yıl",
    month: "Ay",
    day: "Gün",
    time: "Saat",
    week: "Hafta",
    minute: "Dakika"
  },
  validations: {
    messages: {
      phone: "{_field_} alanının geçerli bir telefon numarası olması gerekir",
      password_match: "Şifreler uyuşmuyor",
      integer: "{_field_} alanı bir tamsayı olmalıdır"
    }
  },
  nationalities: {
    Afghan: "Afgan",
    Albanian: "Arnavut",
    Algerian: "Cezayirli",
    American: "Amerikalı",
    Andorran: "Andorralı",
    Angolan: "Angolalı",
    Antiguans: "Antigua ve Berbudalı",
    Argentinean: "Arjantinli",
    Armenian: "Ermeni",
    Australian: "Avustralyalı",
    Austrian: "Avusturyalı",
    Azerbaijani: "Azerbaycanlı",
    Bahamian: "Bahamalı",
    Bahraini: "Bahreynli",
    Bangladeshi: "Bangladeşli",
    Barbadian: "Barbadoslu",
    Barbudans: "Barbudalı",
    Botswana: "Botsvanalı",
    Belarusian: "Belaruslu",
    Belgian: "Belçikalı",
    Belizean: "Belizeli",
    Beninese: "Beninli",
    Bhutanese: "Butanlı",
    Bolivian: "Bolivyalı",
    Bosnian: "Bosnalı",
    Brazilian: "Brezilyalı",
    British: "İngiliz",
    Bruneian: "Brunei Darüsselamlı",
    Bulgarian: "Bulgar",
    Burkinabe: "Burkina Fasolu",
    Burmese: "Myanmarlı",
    Burundian: "Burundili",
    Cambodian: "Kamboçyalı",
    Cameroonian: "Kamerunlu",
    Canadian: "Kanadalı",
    "Cape Verdean": "Yeşil Burunlu",
    "Central African": "Orta Afrikalı",
    Chadian: "Çadlı",
    Chilean: "Şilili",
    Chinese: "Çinli",
    Colombian: "Kolombiyalı",
    Comoran: "Komorlu",
    Congolese: "Kongolu",
    "Costa Rican": "Kosta Rikalı",
    Croatian: "Hırvat",
    Cuban: "Kübalı",
    Cypriot: "Kıbrıslı",
    Czech: "Çek",
    Danish: "Danimarkalı",
    Djibouti: "Cibutili",
    Dominican: "Dominikli",
    Dutch: "Hollandalı",
    "East Timorese": "Doğu Timorlu",
    Ecuadorean: "Ekvadorlu",
    Egyptian: "Mısırlı",
    Emirian: "Emiranlı",
    "Equatorial Guinean": "Ekvator Gineli",
    Eritrean: "Eritreli",
    Estonian: "Eston",
    Ethiopian: "Etiyopyalı",
    Fijian: "Fijili",
    Filipino: "Filipinli",
    Finnish: "Fin",
    French: "Fransız",
    Gabonese: "Gabonlu",
    Gambian: "Gambiyalı",
    Georgian: "Gürcü",
    German: "Alman",
    Ghanaian: "Ganalı",
    Greek: "Yunan",
    Grenadian: "Grenadalı",
    Guatemalan: "Guatemalalı",
    "Guinea-Bissauan": "Gine-Bissaulu",
    Guinean: "Gineli",
    Guyanese: "Guyanalı",
    Haitian: "Haitili",
    Herzegovinian: "Hersekli",
    Honduran: "Honduraslı",
    Hungarian: "Macar",
    "I-Kiribati": "Kiribatili",
    Icelander: "İzlandalı",
    Indian: "Hintli",
    Indonesian: "Endonezyalı",
    Iranian: "İranlı",
    Iraqi: "Iraklı",
    Irish: "İrlandalı",
    Israeli: "İsrailli",
    Italian: "İtalyan",
    Ivorian: "Fildişili",
    Jamaican: "Jamaikalı",
    Japanese: "Japon",
    Jordanian: "Ürdünlü",
    Kazakhstani: "Kazak",
    Kenyan: "Kenyalı",
    "Kittian and Nevisian": "Saint Kitts ve Nevisli",
    Kuwaiti: "Kuveytli",
    Kyrgyz: "Kırgız",
    Laotian: "Laoslu",
    Latvian: "Leton",
    Lebanese: "Lübnanlı",
    Liberian: "Liberyalı",
    Libyan: "Libyalı",
    Liechtensteiner: "Lihtenştaynlı",
    Lithuanian: "Litvanyalı",
    Luxembourger: "Lüksemburglu",
    Macedonian: "Makedonyalı",
    Malagasy: "Madagaskarlı",
    Malawian: "Malavili",
    Malaysian: "Malezyalı",
    Maldivan: "Maldivlerli",
    Malian: "Malili",
    Maltese: "Maltalı",
    Marshallese: "Marşallı",
    Mauritanian: "Moritanyalı",
    Mauritian: "Mauritiuslu",
    Mexican: "Meksikalı",
    Micronesian: "Mikronezyalı",
    Moldovan: "Moldovalı",
    Monacan: "Monakolu",
    Mongolian: "Moğol",
    Moroccan: "Faslı",
    Mosotho: "Lesotolu",
    Motswana: "Botsvanalı",
    Mozambican: "Mozambikli",
    Namibian: "Namibyalı",
    Nauruan: "Naurulu",
    Nepalese: "Nepalli",
    "New Zealander": "Yeni Zelandalı",
    Nicaraguan: "Nikaragualı",
    Nigerian: "Nijeryalı",
    Nigerien: "Nijer",
    "North Korean": "Kuzey Koreli",
    "Northern Irish": "İrlandalı",
    Norwegian: "Norveçli",
    Omani: "Ummanlı",
    Pakistani: "Pakistanlı",
    Palauan: "Palaulu",
    Panamanian: "Panamalı",
    "Papua New Guinean": "Papua Yeni Gineli",
    Paraguayan: "Paraguaylı",
    Peruvian: "Perulu",
    Polish: "Polonyalı",
    Portuguese: "Portekizli",
    Qatari: "Katarlı",
    Romanian: "Romen",
    Russian: "Rus",
    Rwandan: "Ruandalo",
    "Saint Lucian": "Saint Lucialı",
    Salvadoran: "El Salvadorlu",
    Samoan: "Samoalı",
    "San Marinese": "San Marinolu",
    "Sao Tomean": "Sao Tomeli",
    Saudi: "Suudi",
    Scottish: "İskoç",
    Senegalese: "Senegalli",
    Serbian: "Sırp",
    Seychellois: "Seyşellerli",
    "Sierra Leonean": "Sierra Leoneli",
    Singaporean: "Singapurlu",
    Slovakian: "Slovak",
    Slovenian: "Slovenyalı",
    "Solomon Islander": "Solomon Adalı",
    Somali: "Somalili",
    "South African": "Güney Afrikalı",
    "South Korean": "Güney Koreli",
    Spanish: "İspanyol",
    "Sri Lankan": "Sri Lankalı",
    Sudanese: "Sudanlı",
    Surinamer: "Surinamlı",
    Swazi: "Esvati̇ni̇li",
    Swedish: "İsveçli",
    Swiss: "İsviçreli",
    Syrian: "Suriyeli",
    Taiwanese: "Tayvanlı",
    Tajik: "Tacik",
    Tanzanian: "Tanzanyalı",
    Thai: "Taylandlı",
    Togolese: "Togolu",
    Tongan: "Tongalı",
    "Trinidadian or Tobagonian": "Trinidad ve Tobagolu",
    Tunisian: "Tunuslu",
    Turkish: "Türk",
    Tuvaluan: "Tuvalulu",
    Ugandan: "Ugandalı",
    Ukrainian: "Ukraynalı",
    Uruguayan: "Uruguaylı",
    Uzbekistani: "Özbek",
    Venezuelan: "Venezuelalı",
    Vietnamese: "Vietnamlı",
    Welsh: "Galler",
    Yemenite: "Yemenli",
    Zambian: "Zambiyalı",
    Zimbabwean: "Zimbabveli"
  },
  disability_level: {
    first_level: "1. Seviye",
    second_level: "2. Seviye",
    third_level: "3. Seviye"
  },
  social_security_type: {
    SS_4A: "SSK",
    SS_4B: "Bağkur",
    SS_4C: "Emekli Sandığı"
  },
  clock_ip_error: "İp adresi izin verilen ip adresleri arasında değil",
  days: {
    monday: "Pazartesi",
    tuesday: "Salı",
    wednesday: "Çarşamba",
    thursday: "Perşembe",
    friday: "Cuma",
    saturday: "Cumartesi",
    sunday: "Pazar"
  },
  api_error: {
    756: "Talep ettiğiniz tarihler arasında izin bulunmaktadır",
    766: "Şifreniz en az 8, en çok 16 karakter uzunluğunda olmalıdır",
    767: "Şifrenizde küçük harf, büyük harf, rakam ve özel karakter bulunmalıdır: .-/@#$%^&_+=()",
    768: "Şifrenizde ad, soyad veya sicil numarası yer alamaz",
    769: "Bu şifre daha önce kullanılmıştır, lütfen son {0} şifrenizden farklı bir şifre giriniz"
  },
  http: {
    200: "İşlem Başarılı",
    201: "İşlem Başarılı",
    202: "Kayıt Başarılı",
    401: "Yetkisiz Oturum",
    404: "Böyle bir istek bulunamadı",
    409: "Aynı kayıttan mevcut",
    415: "Desteklenmeye dosya formatı",
    204: "İşlem Başarılı",
    304: "Önbellek Hatası",
    500: "Sunucu Hatası",
    503: "Sunucu kullanılamıyor"
  },
  expense_items: "Harcama Kalemleri",
  expense_item_name: "Kalem Adı",
  update_expense_item: "Harcama Kalemi Güncelle",
  add_expense_item: "Harcama Kalemi Ekle",
  file_format_error: "Geçersiz Dosya Formatı",
  file_size_error: "Geçersiz Dosya Boyutu",
  not_selected_expense_item_error_message: "Harcama kalemi eklemediniz",
  expense_description: "Harcama Açıklaması",
  abolisher_employee: "İptal Eden Çalışan",
  expense_manager: "Harcama Yöneticisi",
  event_date: "İşlem Tarihi",
  cancelled_date: "İptal Edildiği Tarih",
  approval_history: "Onaylama Geçmişi",
  request_processes: {
    super_admin: "Süper Yönetici tarafından onaylandı",
    approved: "Talebi onayladı",
    pending: "Onay vermesi bekleniyor",
    stopped: "Talep reddedildiği için onaylama süreci durduruldu",
    waiting: "Bir önceki yöneticinin onaylamasını bekliyor",
    denied: "Talep reddedildi"
  },
  sso_forgot_password_warning: {
    title: "Uyarı",
    message: "Idenfit'e Accenture elektronik posta adresiniz ile giriş yapabilirsiniz."
  },
  time_abbr: {
    hour: "sa",
    minute: "dk",
    second: "sn"
  },
  vedubox_user_not_found: "Vedubox Kullanıcısı Bulunamadı",
  gdpr: {
    acceptance: {
      title: "KVKK Açık Rıza Metni Onayı",
      checkbox: "KVKK Açık Rıza metnini onaylıyorum.",
      save: "Kaydet ve Devam Et",
      formError: "Devam edebilmek için KVKK Açık Rıza metnini onaylamanız gerekmektedir."
    }
  },
  web_terminal_loading: "Web terminali yükleniyor",
  web_terminal_not_found: "Web terminali bulunamadı",
  multiple_assigned_shift_warning:
    "Birden fazla atanmış vardiyanız bulunmaktadır. Lütfen aşağıdaki vardiyalardan birini seçiniz.",
  paper_approval: "Evrak Onaylama",
  paperApprovalProcessWarningMessage: "Dosyayı indirmeniz ya da önizleme yapmanız gereklidir",
  minLength: "En az 8 karakter",
  hasLowerCaseAndUpperCase: "Rakam, büyük harf ve küçük harf içerir",
  hasSpecialCharacter: "Özel karakter içerir",
  passwordExpireNotificationMessage:
    "Şifrenizin kullanım süresinin dolmasına {count} gün kaldı | Şifrenizin kullanım süresinin dolmasına {count} gün kaldı | Şifrenizin kullanım süresinin dolmasına {count} gün kaldı",
  pendingRequests: "Onay Bekleyenler",
  allPendingRequests: "Tüm Onay Bekleyenler",
  maxImprestAmountMessage: "Talep edilebilecek üst limit: {0}",
  or: "veya",
  continueWithMicrosoft: "Microsoft ile devam edin"
};
